<template lang="pug">
  JSCharting(
    :options="chartOptions"
    class="full-rose-chart"
    v-if="dataLoaded")
</template>

<script>
import JSCharting, { JSC } from 'jscharting-vue';

export default {
  components: {
    JSCharting,
  },
  props: {
    roseData: {
      type: [Object, Array],
      required: true,
    }
  },
  data() {
    return {
      dataLoaded: false,
      chartOptions: {
        type: 'radar column',
        animation_duration: 1000,
        legend: {
          title_label_text: 'Velocidad del viento (m/s)',
          position: 'bottom',
          reversed: true
        },
        defaultSeries_shape_padding: 0.02,
        yAxis: {
          min: 0,
          defaultTick_label_text: '%value%',
          scale: { type: 'stacked' },
          alternateGridFill: 'none'
        },
        xAxis: {
          min: 0,
          scale: { range: [0, 360], interval: 45 },
          customTicks: [
            { value: 360, label_text: 'N' },
            { value: 45, label_text: 'NE' },
            { value: 90, label_text: 'E' },
            { value: 135, label_text: 'SE' },
            { value: 180, label_text: 'S' },
            { value: 225, label_text: 'SW' },
            { value: 270, label_text: 'W' },
            { value: 315, label_text: 'NW' }
          ]
        },
        palette: [
          '#c62828',
          '#ff7043',
          '#fff176',
          '#aed581',
          '#80cbc4',
          '#bbdefb'
        ],
        defaultPoint: {
          tooltip:
        '<b>%seriesName</b> %xValue° %yValue%'
        },
      },
    };
  },
  mounted() {
    console.log('entro mounted RoseGraph');
    console.log(this.roseData);
    this.chartOptions = JSC.merge(this.chartOptions, {
      series: this.roseData.reverse()
    });
    this.dataLoaded = true;
  }
};
</script>

<style scoped>
.full-rose-chart {
  max-width: 500px;
  height: 700px;
  margin: 0px auto;
}
</style>
