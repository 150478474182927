export default {
  data() {
    return {
      validatedCaptcha: false,
      captchaError: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  }
};
