import { apiClient } from './config.js';
import { getCookie } from '../utils.js';

const csrfToken = 'csrftoken';

export default {
  // iniciar sesión de usuario
  login(userName, password) {
    // agregar cookie a la petición
    apiClient.interceptors.request.use((config) => {
      config.headers.post['X-CSRFToken'] = getCookie(csrfToken);
      return config;
    });
    return apiClient.post(
      'login',
      {
        username: userName,
        password
      }
    );
  },
  // conseguir la cookie para hacer las peticiones POST
  getCSRFCookie() {
    return apiClient.get('login-set-cookie');
  },
  // Borrar las credenciales del usuario
  logout() {
    // agregar cookie a la petición
    apiClient.interceptors.request.use((config) => {
      config.headers.post['X-CSRFToken'] = getCookie(csrfToken);
      return config;
    });
    return apiClient.post('logout');
  },
  // iniciar sesión de usuario
  register(
    firstName,
    lastName,
    phone,
    email,
    userName,
    password,
    address,
    identificationType,
    identification,
    rut,
    companyName,
    selectedCity,
  ) {
    // agregar cookie a la petición
    apiClient.interceptors.request.use((config) => {
      config.headers.post['X-CSRFToken'] = getCookie(csrfToken);
      return config;
    });
    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('username', userName);
    formData.append('address', address);
    formData.append('password', password);
    formData.append('phone', phone);
    formData.append('identification_type', identificationType);
    formData.append('identification', identification);
    formData.append('company_name', companyName);
    formData.append('city', selectedCity);
    if (rut) {
      formData.append('rut', rut);
    }
    return apiClient.post(
      'register',
      formData
    );
  },
  // refrescar el token de acceso
  getNewToken(refreshToken) {
    return apiClient.post(
      'token/refresh',
      {
        refresh: refreshToken,
      }
    );
  }
};
