<template lang="pug">
v-card(
  max-width="600"
  class="mt-4 mx-auto")
  v-list(
    v-if="isMounted"
    subheader
    two-line)
    v-list-item-group(v-model="selectedItem" :color="'blue'")
      v-subheader(inset v-show="apps.length > 0") Aplicaciones
      v-list-item(
        v-for="app in rates"
        :key="app.app.id")
        v-list-item-avatar
          v-icon(
            :title="app.app.category.name"
            color="white"
            :style="{backgroundColor: app.app.category.color}"
            dark) mdi-map
        v-list-item-content(class="d-flex flex-row")
          v-list-item-title(v-text="app.app.name")
          v-list-item-subtitle(v-text="convertCurrency(app.price)")
        div(class="d-flex flex-row")
          v-text-field(
            v-model="app.count" class="textbox" outlined dense
            type="number"
            min="0"
            @keypress="isNumber($event)"
          )
        v-list-item-action {{convertCurrency(app.count * app.price)}}
  v-divider
  v-subheader Resumen de la compra
  v-list(
    two-line
    subheader)
    v-list-item
      v-list-item-content
        v-list-item-title Total créditos
      v-list-item-action(class="text-h6") {{Number(totalCredits)}}

    v-list-item
      v-list-item-content
        v-list-item-title Valor a pagar
      v-list-item-action(class="text-h6 d-flex flex-row")
        v-img(
        class="mb-2 mr-1"
        max-height="26"
        max-width="33"
        src="@/assets/flags/Colombia.png")
        | COP {{convertCurrency(totalPayment)}}
    v-list-item
      v-btn(
        v-if="isLoggedIn"
        :disabled="this.totalPayment === 0"
        color="success"
        @click="goPayU"
        class="mx-auto white--text")
        v-icon(
          left
          dark) mdi-currency-usd
        | Ir a formulario de pago
      v-row(v-else class="justify-space-between text-center")
        v-col
          v-btn(
            color="success"
            @click.native.stop="goLogin"
            class="mx-auto white--text")
            v-icon(
              left
              dark) mdi-account
            | Ingresar
        // v-col
          v-btn(
            color="success"
            @click="goRegister"
            class="mx-auto white--text")
            v-icon(
              left
              dark) mdi-account-plus
              Registrarse
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';
import { currencyFormat } from '../../utils.js';

export default {
  data: () => ({
    selectedItem: null,
    isMounted: false,
  }),
  computed: {
    ...mapGetters('subscription', ['apps', 'webGis', 'totalCredits', 'totalPayment']),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('subscription', ['paidVersion']),
    ...mapState('subscription', ['rates']),
  },
  watch: {
    '$store.state.ui.showDialogForm': {
      deep: true,
      handler(newVal) {
        console.log(' changed', newVal);
        if (newVal === true) {
          // generar el codigo de referencia
          this.CREATE_REF_CODE();
        }
      }
    }
  },
  async mounted() {
    this.CREATE_REF_CODE();
    await this.getRates();
    // marcar la aplicacion sugerida si existe version paga
    if (this.paidVersion !== 0) {
      console.log(this.rates.map((x) => x.app.id));
      const appIndex = this.rates.map((x) => x.app.id).indexOf(this.paidVersion);
      console.log('appIndex', appIndex);
      this.selectedItem = appIndex;
    }
    this.isMounted = true;
  },
  methods: {
    ...mapActions('subscription', ['getRates', 'savePayment']),
    ...mapMutations('subscription', ['APPEND', 'SUBTRACT', 'CREATE_REF_CODE']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    isNumber(evt) {
      // evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log('charCode:', charCode);
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      }
      return true;
    },
    convertCurrency(value) {
      return currencyFormat(value);
    },
    goPayU() {
      this.savePayment()
        .then((res) => {
          if (res === 'success') {
            this.$bus.$emit('select-modal-form', {
              component: 'subscription-form',
              title: 'Ingrese los datos para continuar con el pago.'
            });
          } else {
            this.SHOW_ALERT({
              level: 'error',
              message: res
            });
          }
        })
        .catch((err) => {
          console.log(err, 'en catch componente');
        });
    },
    goLogin() {
      this.$bus.$emit('select-modal-form', {
        component: 'login-form',
        title: 'Ingrese sus credenciales de usuario para poder acceder a todo el contenido.'
      });
    },
    goRegister() {
      this.$bus.$emit('select-modal-form', {
        component: 'register-form',
        title: 'Cree una cuenta para una persona o empresa.'
      });
    }
  },
};
</script>

<style scoped>
  .textbox {
    max-width: 60px;
    max-height: 25px;
    text-align: center;
  }
</style>
