<template lang="pug">
  div(class="mt-8 text-center")
    div(class="text-h5") Solicite su Servicio
    div(class="text-caption mb-5 mx-auto" width="60")
      | Diligencie el formulario y a continuación lo contactará
      | uno de nuestros expertos en calidad del aire para entregarle
      | la cotización y atender sus inquietudes
    v-card(
      class="mx-auto my-auto meteo-form"
      max-width="500"
      min-width="300"
      outlined)
      v-form(ref="form" v-model="valid" lazy-validation :id="formId")
        v-text-field(
          v-model="firstName"
          :rules="[rules.required]"
          label="*Nombre(s)"
          required)
        v-text-field(
          v-model="lastName"
          :rules="[rules.required]"
          label="*Apellido(s)"
          required)
        v-text-field(
          v-model="company"
          :rules="[rules.required]"
          label="*Nombre Entidad"
          required)
        v-text-field(
          v-model="phone"
          :rules="[rules.required]"
          label="*Teléfono"
          required)
        v-text-field(
          v-model="direction"
          :rules="[]"
          label="Dirección"
          required)
        v-text-field(
          v-model="email"
          :rules="[rules.required, rules.emailvalid]"
          label="*Correo Electrónico"
          required)
        v-text-field(
          v-model="city"
          :rules="[]"
          label="Ciudad"
          required)
        location-picker(
          label="Ubicación Proyecto"
          help-text="Las coordenadas deben estar lat+espacio+lon. \
          Si no conoce la ubicación, presione el icono de mapa para ubicarse"
        )
        v-row
          v-col(cols="6")
            v-menu(
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field(
                  v-model="startDate"
                  label="Fecha inicial"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on")
              v-date-picker(
                v-model="startDate"
                :active-picker.sync="activePicker1"
                :max="todayFormat"
                min="2000-01-01"
                @change="save(startDate, 1)")
          v-col(cols="6")
            v-menu(
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field(
                  v-model="finalDate"
                  label="Fecha final"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on")
              v-date-picker(
                v-model="finalDate"
                :active-picker.sync="activePicker2"
                :max="todayFormat"
                min="2000-01-01"
                @change="save(finalDate, 2)")
        motion-captcha(
          :form-id="formId"
          v-model="validatedCaptcha"
          :show-error="captchaError")
        v-btn(
          :disabled="!valid"
          color="success"
          class="mr-4 mt-4"
          @click="") Enviar

</template>

<script>
import LocationPicker from '@/components/LocationPicker';
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';

export default {
  name: 'AirModelForm',
  components: {
    LocationPicker,
    MotionCaptcha
  },
  mixins: [formMixin],
  data() {
    return {
      formId: 'model-form',
      menu1: false,
      menu2: false,
      activePicker1: null,
      activePicker2: null,
      startDate: null,
      finalDate: null,
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      direction: '',
      email: '',
      city: '',
      valid: true,
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
        emailvalid: (v) => /.+@.+\..+/.test(v) || 'Debe ingresar un correo valido',
      },
    };
  },
  computed: {
    todayFormat() {
      return (new Date(
        Date.now() - (new Date()).getTimezoneOffset() * 60000
      )).toISOString().substr(0, 10);
    },
  },
  methods: {
    save(date, num) {
      this.$refs[`menu${num}`].save(date);
    },
    submit() {
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      if (this.validatedCaptcha === false) {
        this.captchaError = true;
        return;
      }
      this.captchaError = false;
    }
  },
};
</script>
