import { apiClient } from './config.js';
import { getToken } from '../utils.js';

export default {
  async getPosts() {
    const token = await getToken();
    if (token !== '') {
      apiClient.interceptors.request.use((config) => {
        config.headers = { ...{ Authorization: `Bearer ${token}` }, ...config.headers };
        return config;
      });
    }
    return apiClient.get('posts');
  }
};
