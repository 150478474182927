<template lang="pug">
div
  highcharts(class="hc" :options="getChartData()" ref="chart")
</template>
<script>
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import windbarbInit from 'highcharts/modules/windbarb';

exportingInit(Highcharts);
windbarbInit(Highcharts);

export default {
  name: 'ForeCastBarbChart',
  props: {
    southernWind: {
      type: Array,
      required: true
    },
    zonalWind: {
      type: Array,
      required: true
    },
    speedWind: {
      type: Array,
      required: true
    },
    initialDate: {
      type: String,
      required: true
    },
    finishDate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  methods: {
    formatData() {
      const data = [];
      for (let index = 0; index < this.southernWind.length; index++) {
        const southernElement = this.southernWind[index];
        const zonalElement = this.zonalWind[index];
        const speedElement = parseFloat(this.speedWind[index].toFixed(2));
        // hayar la arcotangente de las componentes
        let calc = Math.atan(zonalElement / southernElement);
        // Convertir de radianes a grados
        calc *= (180 / Math.PI);
        let plusValue = 0;
        if (southernElement > 0) {
          plusValue = 180;
        }
        if (zonalElement > 0 && southernElement < 0) {
          plusValue = 180;
        }
        plusValue += calc;
        data.push([speedElement, parseFloat(plusValue.toFixed(1))]);
      }
      return data;
    },
    getChartData() {
      const data = this.formatData();
      return {
        title: {
          // text: ` Viento observado entre ${this.initialDate} y ${this.finishDate}`,
          text: '',
          align: 'center'
        },
        credits: {
          enabled: false
        },
        // subtitle: {
        //   text: 'Source: '
        //       + '<a href="https://www.yr.no/nb/historikk/graf/1-137598/Norge/Vestland/Vik/Vik%C3%B8yri?q=2022-07-30"'
        //       + 'target="_blank">YR</a>',
        //   align: 'left'
        // },
        xAxis: {
          type: 'datetime',
          offset: 40
        },
        yAxis: {
          title: {
            text: 'Velocidad del Viento (m/s)'
          }
        },
        plotOptions: {
          series: {
            pointStart: Date.UTC(
              this.initialDate.slice(6, 10),
              this.initialDate.slice(3, 5),
              this.initialDate.slice(0, 2),
              this.initialDate.slice(11, 13),
            ),
            pointInterval: 36e5 // una hora
          }
        },
        series: [
          {
            type: 'windbarb',
            data,
            name: 'Wind',
            color: Highcharts.getOptions().colors[1],
            showInLegend: false,
            tooltip: {
              valueSuffix: ' m/s'
            }
          },
          {
            type: 'area',
            keys: ['y'], // wind direction is not used here
            data,
            color: Highcharts.getOptions().colors[0],
            fillColor: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0.25).get()
                ]
              ]
            },
            name: 'Velocidad del viento',
            tooltip: {
              valueSuffix: ' m/s'
            },
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        ]
      };
    }
  }
};
</script>
