<template lang="pug">
  v-container(class="text-center")
    div(class="text-h4 font-weight-light pb-2") {{item.title}}
    div(class="title-divider blue darken-4 mx-auto mb-5")
    div(class="item-image mb-5")
      v-img(height="350px" :src="item.image")
    div(class="text-caption item-description font-weight-light") {{item.description}}
    contact-us-form
</template>

<script>
import ContactUsForm from '@/components/forms/ContactUsForm';

export default {
  components: {
    ContactUsForm,
  },
  data() {
    return {
      item: null,
    };
  },
  beforeMount() {
    const itemId = parseInt(this.$route.params.item_id, 10);
    let source = '';
    if (this.$route.path.includes('/view-study')) {
      source = 'studies';
    } else {
      source = 'products';
    }
    this.item = this.$store.getters['meteocolombia/getItem'](itemId, source);
  }
};
</script>

<style scoped>
  .title-divider {
    width: 10%;
    height: 3px;
  }
</style>
