<template lang="pug">
div(class="mt-8 text-center")
  v-card(
    class="mx-auto my-auto meteo-form"
    max-width="500"
    outlined)
    v-card-subtitle(class="text-caption"
    ) Ingrese su correo electrónico.
    | Recibirá un correo de recuperación de contraseña.
    v-alert(
      v-if="errorMessage"
      border="top"
      color="red lighten-2"
      dark) {{errorMessage}}
    v-form.my-5(
      ref="form" v-model="valid" lazy-validation :id="formId" @submit.prevent="submit"
    )
      v-text-field(
        v-model="email"
        :rules="emailRules"
        label="Email"
        required)
      motion-captcha.mt-3(
        :form-id="formId"
        v-model="validatedCaptcha"
        :show-error="captchaError"
        class="cuadro_capcha")
      v-btn.mt-3(
        type="submit"
        color="primary"
      ) Recuperar contraseña
      v-alert.mt-5(
          v-if="showAlert"
          :type="alertType"
          border="top"
          dark
        ) {{ alertMessage }}
</template>

<script>
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';
import { serverApi } from '@/config';

export default {
  name: 'ForgotPasswordForm',
  components: {
    MotionCaptcha,
  },
  mixins: [formMixin],
  data() {
    return {
      formSent: false,
      formId: 'forgot-password-form',
      email: '',
      alertType: '',
      alertMessage: '',
      showAlert: false,
      emailRules: [
        (v) => !!v || 'El email es requerido',
        (v) => /.+@.+\..+/.test(v) || 'El email debe ser válido',
      ],
    };
  },
  methods: {
    submit() {
      if (this.validatedCaptcha) {
        const url = `${serverApi}recovery-password`;
        const data = { email: this.email };
        // usar fetch para enviar el formulario
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) =>
            response.json().then((data) => ({
              status: response.status,
              data,
            })))
          .then(({ status, data }) => {
            if (status !== 200) {
              this.alertType = 'error';
            } else {
              this.alertType = 'success';
            }
            this.showAlert = true;
            this.alertMessage = data.message;
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            this.alertMessage = error.message || error;
            this.alertType = 'error';
            this.showAlert = true;
          });
      } else {
        this.alertType = 'error';
        this.alertMessage = 'Error al enviar el formulario';
        this.showAlert = true;
      }
    },
  },
};
</script>
//
