<template lang="pug">
v-app
    v-alert(v-if="showAlert" class="mb-0" :type="alertType") {{alertMessage}}
    v-main.bgBody
      router-view
    //- v-footer(class="meteo-footer" padless)
      v-layout(justify-center wrap)
        v-flex(
          class="meteoFooter"
          py-4
          text-center
          white--text
          xs12) {{ new Date().getFullYear() }} — <strong>Meteocolombia</strong>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import MeteoBrand from '@/components/ui/MeteoBrand.vue';
import MeteoBlog from '@/components/ui/MeteoBlog.vue';
import LfStaticMapFrame from '@/components/Leaflet/LfStaticMapFrame.vue';
import lfControlMixin from '@/mixins/lfControlMixin.js';
// import { serverApi } from './config.js';

export default {
  name: 'App',
  components: {
    MeteoBlog,
    MeteoBrand,
    LfStaticMapFrame,
  },
  mixins: [lfControlMixin],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('ui', ['showAlert', 'alertMessage', 'alertType']),
  },
  beforeMount() {
    // Descomentar para cargar lista de capas en la barra lateral
    /* const response = await fetch(`${serverApi}layers`);
    const jsonResp = await response.json();
    const subLinks = [];
    for (let index = 0; index < jsonResp.length; index++) {
      const element = jsonResp[index];
      subLinks.push({
        text: element.name,
        to: `/leaflet/${element.identifier}`,
        public: true,
      });
    }
    const sites = {
      title: 'Sitios especiales',
      url: '/leaflet',
      icon: 'mdi-map-marker-multiple',
      subLinks
    };
    this.items.push(sites); */

    // obtener la cookie para poder hacer las peticiones post
    this.setCookie();

    // obtener los paises en los que opera la plataforma
    this.getCountries();

    // cargar las categorias de aplicaiones disponibles
    this.getAppCategories();

    // cargar los post del blog
    this.getPosts();
  },
  methods: {
    ...mapActions('ui', ['getAppCategories']),
    ...mapActions('auth', ['setCookie']),
    ...mapActions('blog', ['getPosts']),
    ...mapActions('meteocolombia', ['getCountries']),
    ...mapMutations('ui', ['SHOW_FAQS', 'CLOSE_SIDEBAR']),
    toggleTheme() {
      this.$vuetify.theme.themes.dark.anchor = '#41b883';
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
