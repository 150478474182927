<template lang="pug">
div(class="form-group")
  label(v-if="label") {{label}}
  div(class="text-center")
    v-menu(offset-y)
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          class="white--text"
          :color="selectedCity ? 'green darken-1' : 'amber darken-3'"
          block
          v-bind="attrs"
          v-on="on")
          span(v-if="selectedCity")
            | {{selectedCity.name}} - {{selectedCity.state}} - {{selectedCity.country}}
          span(v-else)
            | {{placeholder}} {{resultString}}
      v-list(class="scrollable" max-height="200px" )
        v-list-item
          v-text-field(v-model="queryString" :label="infoMessage" @click.stop="" outlined)
        v-list-item(
          v-for="(item, index) in filteredCities"
          color="red"
          :key="index"
          @click="selectCity(item)")
          v-list-item-title
            | {{item.name}} - {{item.state}} - {{item.country}}
            span(v-if="selectedCity")
              v-icon(
                v-if="selectedCity.code === item.code"
                class="ml-2"
                color="green darken-1") mdi-check-circle
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'CityPicker',
  model: {
    prop: 'selectedCity',
    event: 'change'
  },
  props: {
    minCharacters: {
      type: Number,
      default: 3
    },
    notFound: {
      type: String,
      default: 'Resultados no encontrados'
    },
    queryMessage: {
      type: String,
      default: 'Por favor ingrese xx o más caracteres'
    },
    placeholder: {
      type: String,
      default: 'Seleccione el municipio'
    },
    label: {
      type: String,
      default: ''
    },
    // rules: {
    //   type: Array,
    //   default: []
    // },
  },
  data() {
    return {
      queryString: '',
      selectedCity: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('subscription', ['filteredCities']),
    resultString() {
      let result = '';
      if (this.queryString.length > this.minCharacters) {
        result = `${this.filteredCities.length} resultado(s)`;
      }
      return result;
    },
    infoMessage() {
      let message = '';
      if (this.queryString.length < this.minCharacters) {
        const missingChars = this.minCharacters - this.queryString.length;
        message = this.queryMessage.replace('xx', missingChars);
      }
      if (this.queryString.length >= this.minCharacters && this.filteredCities.length === 0) {
        message = this.notFound;
      }
      return message;
    }
  },
  watch: {
    queryString(newVal) {
      if (newVal.length >= this.minCharacters) {
        this.fetchCities();
      }
    }
  },
  methods: {
    ...mapMutations('subscription', ['CLEAR_CITIES']),
    ...mapActions('subscription', ['getCities']),
    selectCity(city) {
      this.selectedCity = city;
      this.$emit('change', this.selectedCity.code);
      this.queryString = '';
      this.CLEAR_CITIES();
    },
    fetchCities() {
      this.getCities(this.queryString);
    },
    // async validate() {
    //   let isValid = true;
    //   if (this.rules.length > 0) {
    //     for (let index = 0; index < this.rules.length; index++) {
    //       const rule = this.rules[index];
    //       const ruleValue = await rule(this.selectedCity);
    //       if (ruleValue !== true) {
    //         this.isValid = false;
    //         this.errorMessage = ruleValue;
    //         isValid = false;
    //         return isValid;
    //       }
    //     }
    //   }
    //   if (isValid === true)
    //     this.isValid = true;
    //     this.errorMessage = '';
    //   return isValid;
    // }
  }
};
</script>
