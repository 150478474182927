import moment from 'moment';

export default {
  methods: {
    formatTick(value) {
      const formatDate = moment(value, 'DD-MM-YYYY hh:mm').format('dddDD.MMM');
      const hour = parseInt(value.slice(11, 13), 10);
      let tick = ['', ''];
      if (hour % 3 === 0 && hour !== 0) {
        tick = [`${hour}h`, ''];
      }
      if (hour === 0) {
        tick = ['0h', formatDate];
      }
      return tick;
    },
  }
};
