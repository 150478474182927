import axios from 'axios';

import {
  serverApi,
  meteoCountry,
  paymentCurrency
} from '../config.js';

export const apiClient = axios.create({
  baseURL: `${serverApi}/`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': 'true',
    // Authorization: `Token ${localStorage.getItem('token')}`,
    'X-Meteo-Country': meteoCountry,
    'X-Meteo-Currency': paymentCurrency
  },
});
