<template lang="pug">
v-container(class="d-flex flex-column" fill-height fluid)
  div(class="mb-4")
    h2(class="font-weight-light") Referencia de pago <b>{{referenceCode}}</b>
  v-card(class="mx-auto my-auto meteo-form text-center" max-width="380" min-width="380" outlined)
    v-alert(
    v-if="status === 'errorMessage'"
    border="top"
    color="red lighten-2"
    dark) {{errorMessage}}
    payment-form(v-show="false" ref="payUForm")
    v-form(ref="form" v-model="valid" lazy-validation :id="formId")
      v-text-field(
        v-model="buyerName"
        :rules="nameRules"
        label="*Su nombre"
        required)
      v-text-field(
        v-model="buyerEmail"
        :rules="[rules.required, rules.emailvalid]"
        label="*Correo Electrónico"
        required)
      //- v-text-field(
        disabled
        type="number"
        v-model="money"
        label="*Monto a pagar"
        :rules="[rules.required]"
        required)
      div(class="text-left mb-3")
        span(class="text-h5")
          | <b>Monto a pagar:</b> {{convertCurrency(money) || 0}}
      motion-captcha(
        :form-id="formId"
        v-model="validatedCaptcha"
        :show-error="captchaError")
      v-img(
        width="175"
        class="clickable mx-auto"
        @click="pay"
        :disabled="!valid"
        src="https://ecommerce.payulatam.com/img-secure-2015/boton_pagar_grande.png")
    ul(class="form-conditions text-caption mt-5 text-left")
      li * Los campos marcados con asterísco son obligatorios
      li ** El monto debe incluir el valor correspondiente de retención en la fuente
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import CurrencyInput from '@/components/ui/CurrencyInput';
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';
import { currencyFormat } from '../../utils';
import PaymentForm from '@/components/subscription/PaymentForm.vue';

export default {
  name: 'SubscriptionForm',
  components: {
    CurrencyInput,
    MotionCaptcha,
    PaymentForm,
  },
  mixins: [formMixin],
  data() {
    return {
      formId: 'subscription-form',
      valid: true,
      show: false,
      checkbox: false,
      money: 0,
      errorMessage: '',
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
        emailvalid: (v) => /.+@.+\..+/.test(v) || 'Debe ingresar un correo valido',
      },
      nameRules: [
        (v) => !!v || 'El nombre de usuario es obligatorio',
        (v) => (v && v.length >= 10) || 'El nombre debe tener por lo menos 10 carateres',
      ],
    };
  },
  watch: {
    '$store.state.ui.showDialogForm': {
      deep: true,
      handler(newVal) {
        console.log(' changed', newVal);
        if (newVal === true) {
          // generar el codigo de referencia si no esta generado
          if (this.referenceCode === '') {
            this.CREATE_REF_CODE();
          }
        }
      }
    }
  },
  computed: {
    ...mapState('auth', ['status', 'email']),
    ...mapState('subscription', ['referenceCode', 'serviceAmount']),
    ...mapGetters('subscription', ['totalPayment']),
    buyerEmail: {
      get() {
        return this.$store.state.subscription.buyerEmail;
      },
      set(value) {
        return this.$store.commit('subscription/SET_VALUE', {
          name: 'buyerEmail',
          value
        });
      }
    },
    buyerName: {
      get() {
        return this.$store.state.subscription.buyerName;
      },
      set(value) {
        return this.$store.commit('subscription/SET_VALUE', {
          name: 'buyerName',
          value
        });
      }
    }
  },
  mounted() {
    if (this.$route.name === 'PaymentService') {
      this.money = this.serviceAmount || 0;
    } else {
      // generar el codigo de referencia
      if (this.referenceCode === '') {
        this.CREATE_REF_CODE();
      }
      // scroll al inicio cuando el componente cargue
      const el = document.getElementsByClassName('v-dialog')[0];
      el.scrollTo(0, 0);
      // llenar el input del formulario
      this.money = this.totalPayment || 0;
      const userEmail = this.email || '';
      this.$store.commit('subscription/SET_VALUE', {
        name: 'buyerEmail',
        value: userEmail
      });
    }
  },
  methods: {
    ...mapMutations('subscription', ['CALCULATE_SIGNATURE', 'CREATE_REF_CODE']),
    async pay() {
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      if (this.validatedCaptcha === false) {
        this.captchaError = true;
        return;
      }
      if (this.money < 0) {
        this.errorMessage = 'El pago debe ser mayor a cero.';
        return;
      }
      this.captchaError = false;
      // calcular la firma MD5 para payU
      await this.CALCULATE_SIGNATURE(this.money);
      // enviar el formulario de payU
      await this.$refs.payUForm.sendPay();
    },
    convertCurrency(value) {
      return currencyFormat(value);
    },
  },
};
</script>
