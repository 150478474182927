import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        bgBody: colors.white,
        bgNotFound: colors.amber.lighten4,
        meteoNavBar: colors.lightBlue.darken1,
        meteoFooter: colors.lightBlue.lighten3,
        bannerWrapper: colors.grey.lighten3,
        bgLeafletControl: colors.white,
        subtitleLeafletControl: colors.grey.darken1,
        successLefletControl: colors.green.darken1,
        errorLefletControl: colors.red.darken4,
        appInnerIcon: colors.white,
        warningLefletControl: colors.yellow.darken1,
      },
      dark: {
        bgBody: colors.grey.lighten1,
        bgNotFound: colors.brown.lighten1,
        meteoNavBar: colors.blueGrey.darken1,
        meteoFooter: colors.blueGrey.lighten2,
        bannerWrapper: colors.grey.darken2,
        bgLeafletControl: colors.grey.darken4,
        subtitleLeafletControl: colors.grey.lighten4,
        successLefletControl: colors.green.lighten3,
        errorLefletControl: colors.red.lighten3,
        appInnerIcon: colors.grey.darken2,
        warningLefletControl: colors.yellow.lighten3,
      }
    }
  }
});
