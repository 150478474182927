<template lang="pug">
  v-container(fill-height fluid)
    v-card(class="mx-auto my-auto meteo-form text-center" max-width="344" min-width="300" outlined)
      v-alert(
      v-if="status === 'error'"
      border="top"
      color="red lighten-2"
      dark) {{errorMessage}}
      v-form(ref="form" v-model="valid" lazy-validation)
        v-text-field(
          v-model="userName"
          :rules="nameRules"
          label="Usuario"
          required)
        v-text-field(
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show ? 'text' : 'password'"
          name="input-10-2"
          label="Contraseña"
          hint="At least 8 characters"
          v-model="password"
          class="input-group--focused"
          @click:append="show = !show")
        v-checkbox(
            v-model="checkbox"
            :rules="[v => !!v || '¡Debe aceptar los términos para continuar!']"
            label="¿Está de acuerdo con los términos?"
            required)
        v-btn(
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="login") Ingresar
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      userName: '',
      valid: true,
      show: false,
      checkbox: false,
      password: '',
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
        emailMatch: () => ('The email and password you entered don\'t match'),
      },
      nameRules: [
        (v) => !!v || 'El nombre de usuario es obligatorio',
        (v) => (v && v.length >= 10) || 'El nombre debe tener por lo menos 10 caracteres',
      ],
    };
  },
  computed: {
    ...mapState('auth', ['status', 'errorMessage']),
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    login() {
      console.log('antes validate', this.valid);
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      console.log(isValid, this.valid);
      const { userName, password } = this;
      this.$store.dispatch('auth/login', { userName, password })
        .then((resp) => {
          // this.$router.push('/leaflet');
          console.log('entro login then', resp);
        })
        .catch((err) => {
          console.log('entro login catch', err);
        });
    }
  },
};
</script>
