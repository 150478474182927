<template lang="pug">
v-row(v-if="isMounted")
  v-col(class="pl-7 pr-0 mr-3 text-left" cols="9")
    //v-menu(bottom
      origin="center center"
      transition="scale-transition")
      template(v-slot:activator="{ on, attrs }")
    v-btn( id="brand"
          elevation="0"
          class="px-0 text-h1"
          color="black"
          ) <span style="color:#000000;">Meteo</span>
      <span style="color:#F9A825;"><b>{{countryName}}</b></span>
      //v-list(class="px-232")
          v-list-item(
            v-for="(item, index) in avalaibleCountries"
            //:key="index")
            v-list-item-title
              div(
                class="clickable"
                @click="changeSite(item.url_site)"
                //:title="'Ir a meteo' + item.name")
                img(class="country-flag mr-1" :src="serverUrl + item.flag")
                span Meteo<b>{{item.name}}</b>
  //v-col(class="pl-0" cols="2")
    img(class="country-flag mt-2 ml-2" :src="countryFlag")

</template>

<script>
import { mapState } from 'vuex';
import { serverUrl } from '../../config.js';

export default {
  data() {
    return {
      serverUrl,
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapState('meteocolombia', ['currentCountry', 'meteoCountries']),
    countryFlag() {
      return `${serverUrl}${this.currentCountry.flag}`;
    },
    avalaibleCountries() {
      return this.meteoCountries.filter((x) => x.url_site);
    },
    countryName() {
      if (this.currentCountry) {
        return this.currentCountry.name;
      }
      return '';
    },
  },
  methods: {
    changeSite(url) {
      window.location.replace(url);
    }
  }
};
</script>

<style scoped>
  .brand-text {
    color: white;
    font-size: 16px;
  }
  .country-flag {
    width: 40px;
    border-radius: 5px;
  }
  #brand {
    background: transparent!important;
    font-size: 24px !important;
    font-weight: bold;
  }
  @media (max-width: 540px) {
    #brand {
      font-size: 16px !important;
    }
  }
</style>
