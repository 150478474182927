<template lang="pug">
  div(:style="{width: `${widthContainer}px`, height: `${heightContainer}px`}")
    div(v-show="noData" class="no-data-container")
      v-icon(style="font-size:250%") mdi-power-plug-off
      span(class="font-weight-light") Oops! {{errorMsg}}.
    div
      div(v-show="!noData" class="control-container")
        v-switch(
          class="mr-1"
          label="Adiabáticos secos"
          v-model="showDryAdiabats"
          @click="toggleLine")
        v-switch(
          class="mr-1"
          label="Adiabáticos humedos"
          v-model="showIsoHumes"
          @click="toggleLine")
        v-switch(
          class="mr-1"
          label="Pto. rocio"
          v-model="showDew"
          @click="toggleLine")
        v-menu(offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              class="mt-3"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on") Indices
          v-list(class="px-2")
            div(
              class="text-caption"
              v-for="(item, index) in stuveIndexs"
              :key="index")
              span(class="font-weight-bold") {{ index.toUpperCase() }}:
              span.float-right {{ item.toFixed(2) }}
      div(v-show="!noData")
        span(class="diagram-title" :style="{width: `${widthContainer}px`}")
          | {{graphTitle}}
        div(id="stuve" :style="{width: `${widthContainer}px`}")
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import {
  VaporPressure, MixRatio, getVP, getTemp
} from '../../utils.js';

export default {
  name: 'StuveDiagram',
  props: {
    widthContainer: {
      type: Number,
      required: false,
      default: 360
    },
    heightContainer: {
      type: Number,
      required: false,
      default: 400
    }
  },
  data(instance) {
    const stuveData = instance.$attrs.stuveData || [];
    let lines = null;
    let dryAdiabat = null;
    let isoHumes = null;
    let indexs = null;
    let ctDew = null;
    try {
      lines = stuveData.data;
      dryAdiabat = stuveData.dry_adiabats;
      isoHumes = stuveData.isohumes;
      indexs = stuveData.indices;
      ctDew = stuveData.const_dew;
    } catch (error) {
      lines = [];
      dryAdiabat = [];
      isoHumes = [];
      indexs = [];
      ctDew = [];
    }

    return {
      noData: false,
      errorMsg: '',
      graphTitle: '',
      dryAdiabat,
      isoHumes,
      ctDew,
      tMin: -50,
      tMax: 40,
      tStep: 10,
      pLines: [1000, 850, 700, 500, 300, 200, 100],
      pTicks: [950, 900, 800, 750, 650, 600, 550, 450, 400, 350, 250, 150],
      topPressure: 100,
      basePressure: 1050,
      lines,
      stuveIndexs: indexs,
      showIndexs: false,
      showIsobars: true,
      showIsothrms: true,
      showDryAdiabats: true,
      showIsoHumes: true,
      showDew: true,
      mixRatios: [0.1, 0.2, 0.3, 0.5, 0.7, 1, 2, 3, 5, 7, 10, 15, 20, 30],
      stuveGroup: null,
      x: null,
      y: null,
      tan: Math.tan(55 * (Math.PI / 180)),
      unit: 'kt', // or kmh
      w: null,
      h: null,
      container: null,
      stuveData,
    };
  },
  created() {
    this.$bus.$on('stuve-no-load', (error) => {
      this.errorMsg = error.error;
      this.noData = true;
    });
    this.$bus.$on('stuve-data-loaded', (data) => {
      console.log('stuve-data-loaded', data);
      this.noData = false;
      const stuveData = data.data;
      this.lines = stuveData.data;
      this.dryAdiabat = stuveData.dry_adiabats;
      this.isoHumes = stuveData.isohumes;
      this.stuveIndexs = stuveData.indices;
      this.ctDew = stuveData.const_dew;
      this.graphTitle = `Diagrama Stüve para el periodo ${data.date} en el punto (${data.lat.toFixed(4)}, ${data.lon.toFixed(4)})`;

      // clear previous diagram data
      const el = document.getElementById('stuve');
      el.innerHTML = '';

      this.initStuve('#stuve');
      this.plot(this.lines);
    });
  },
  mounted() {
    // this.initStuve('#stuve');
    console.log('entro mounted stuve', this.stuveData);
    if (this.stuveData) {
      this.lines = this.stuveData.data;
      this.dryAdiabat = this.stuveData.dry_adiabats;
      this.isoHumes = this.stuveData.isohumes;
      this.stuveIndexs = this.stuveData.indices;
      this.ctDew = this.stuveData.const_dew;

      // clear previous diagram data
      const el = document.getElementById('stuve');
      el.innerHTML = '';

      this.initStuve('#stuve');
      this.plot(this.lines);
    }
  },
  methods: {
    toggleLine() {
      this.plot(this.lines);
    },
    generatemixRatios() {
      console.log('entro generate mix ratios', this.lines);
      const pressures = this.pLines.concat(this.pTicks);
      for (let index = 0; index < this.lines.length; index++) {
        const pressure = this.lines[index];
        const vp = VaporPressure(pressure.temp);
        const mr = MixRatio(vp, pressure.press);
        console.log(pressure.press, vp, mr);
        const vp2 = getVP(mr, pressure.press);
        const t = getTemp(vp2);
        console.log(vp2, t);
      }
    },
    plot(s) {
      const me = this;
      const { x, y } = this;
      const plotData = s;
      me.stuveGroup.selectAll('path').remove(); // clear previous paths from skew

      if (plotData.length === 0) return;

      // skew-t stuff
      const skewtline = plotData.filter((d) => (d.temp > -1000 && d.dwpt > -1000));
      const skewtlines = [];
      skewtlines.push(skewtline);

      const templine = d3.line()
        .curve(d3.curveBasis)
        .x((d, i) => x(d.temp) + (y(me.basePressure)
          - y(d.press)) / me.tan).y((d, i) => y(d.press));
      const tempLines = me.stuveGroup.selectAll('templines')
        .data(skewtlines).enter().append('path')
        .attr('class', (d, i) => ((i < 10) ? 'temp skline' : 'temp mean'))
        .attr('clip-path', 'url(#clipper)')
        .attr('d', templine);

      const tempdewline = d3.line()
        .curve(d3.curveBasis)
        .x((d, i) => x(d.dwpt) + (y(me.basePressure)
          - y(d.press)) / me.tan).y((d, i) => y(d.press));
      const tempDewlines = me.stuveGroup.selectAll('tempdewlines')
        .data(skewtlines).enter().append('path')
        .attr('class', (d, i) => ((i < 10) ? 'dwpt skline' : 'dwpt mean'))
        .attr('clip-path', 'url(#clipper)')
        .attr('d', tempdewline);

      const pressArray = plotData.map((x) => x.press);

      if (me.showDew) {
        Object.keys(this.ctDew).forEach((property) => {
          const ctDew = this.ctDew[property];
          // crear diccionario para dibujar la linea de adiabatico seco
          const dewData = [];
          pressArray.forEach((element, index) => {
            dewData.push({
              temp: ctDew[index],
              press: element
            });
          });

          const dewLine = d3.line()
            .curve(d3.curveLinear)
            .x((d, i) => x(d.temp)).y((d, i) => y(d.press));
          const dewLines = me.stuveGroup.selectAll('dewLines')
            .data([dewData]).enter().append('path')
            .attr('class', (d, i) => ((i < 10) ? 'dew-lines' : 'dew-lines'))
            .attr('clip-path', 'url(#clipper)')
            .attr('d', dewLine);
        });
      }

      if (me.showDryAdiabats) {
        Object.keys(this.dryAdiabat).forEach((property) => {
          const dryData = this.dryAdiabat[property];
          // crear diccionario para dibujar la linea de adiabatico seco
          const dryAdiabatData = [];
          pressArray.forEach((element, index) => {
            dryAdiabatData.push({
              temp: dryData[index],
              press: element
            });
          });

          const dryAdiabatLine = d3.line()
            .curve(d3.curveLinear)
            .x((d, i) => x(d.temp)).y((d, i) => y(d.press));
          const dryAdiabatLines = me.stuveGroup.selectAll('dryAdiabatLines')
            .data([dryAdiabatData]).enter().append('path')
            .attr('class', (d, i) => ((i < 10) ? 'dry-adbt' : 'dry-adbt'))
            .attr('clip-path', 'url(#clipper)')
            .attr('d', dryAdiabatLine);
        });
      }

      if (me.showIsoHumes) {
        Object.keys(me.isoHumes).forEach((property) => {
          const dryData = me.isoHumes[property];
          // crear diccionario para dibujar la linea de adiabatico seco
          const isoHumeData = [];
          pressArray.forEach((element, index) => {
            isoHumeData.push({
              temp: dryData[index],
              press: element
            });
          });

          const isoHumeLine = d3.line()
            .curve(d3.curveBasis)
            .x((d, i) => x(d.temp)).y((d, i) => y(d.press));
          const isoHumeLines = me.stuveGroup.selectAll('isoHumeLines')
            .data([isoHumeData]).enter().append('path')
            .attr('class', (d, i) => ((i < 10) ? 'iso-hume' : 'iso-hume'))
            .attr('clip-path', 'url(#clipper)')
            .attr('d', isoHumeLine);
        });
      }
      // mouse over
      this.drawToolTips(skewtlines[0]);
    },
    drawToolTips(skewtlines) {
      const { x, y } = this;
      const me = this;
      const lines = skewtlines.reverse();
      // Draw tooltips
      const tmpcfocus = this.stuveGroup
        .append('g').attr('class', 'focus tmpc').style('display', 'none');
      tmpcfocus.append('circle').attr('r', 4);
      tmpcfocus.append('text').attr('x', 9).attr('dy', '.35em');

      const dwpcfocus = this.stuveGroup
        .append('g').attr('class', 'focus dwpc').style('display', 'none');
      dwpcfocus.append('circle').attr('r', 4);
      dwpcfocus.append('text').attr('x', -9).attr('text-anchor', 'end').attr('dy', '.35em');

      const hghtfocus = this.stuveGroup.append('g').attr('class', 'focus').style('display', 'none');
      hghtfocus.append('text').attr('x', 0).attr('text-anchor', 'start').attr('dy', '.35em');

      const wspdfocus = this.stuveGroup
        .append('g').attr('class', 'focus windspeed').style('display', 'none');
      wspdfocus.append('text').attr('x', 0).attr('text-anchor', 'start').attr('dy', '.35em');

      const bisectTemp = d3.bisector((d) => d.press).left; // bisector function for tooltips
      this.container.append('rect')
        .attr('class', 'overlay')
        .attr('width', me.w)
        .attr('height', me.h)
        .on('mouseover', () => {
          tmpcfocus.style('display', null);
          dwpcfocus.style('display', null);
          hghtfocus.style('display', null);
          // wspdfocus.style('display', null);
        })
        .on('mouseout', () => {
          tmpcfocus.style('display', 'none');
          dwpcfocus.style('display', 'none');
          hghtfocus.style('display', 'none');
          // wspdfocus.style('display', 'none');
        })
        .on('mousemove', (e) => {
          const y0 = y.invert(d3.pointer(e)[1]); // get y value of mouse pointer in pressure space
          const i = bisectTemp(lines, y0, 1, lines.length - 1);
          const d0 = lines[i - 1];
          const d1 = lines[i];
          const d = y0 - d0.press > d1.press - y0 ? d1 : d0;
          tmpcfocus.attr('transform', `translate(${x(d.temp) + (y(me.basePressure) - y(d.press)) / me.tan},${y(d.press)})`);
          dwpcfocus.attr('transform', `translate(${x(d.dwpt) + (y(me.basePressure) - y(d.press)) / me.tan},${y(d.press)})`);
          hghtfocus.attr('transform', `translate(0,${y(d.press)})`);
          tmpcfocus.select('text').text(`${Math.round(d.temp)}°C`);
          dwpcfocus.select('text').text(`${Math.round(d.dwpt)}°C`);
          hghtfocus.select('text').text(`-- ${Math.round(d.hght)} m`); // hgt or hghtagl ???
          wspdfocus.attr('transform', `translate(${me.w - 65},${y(d.press)})`);
          wspdfocus.select('text').text(`${Math.round(me.convert(d.wspd, me.unit) * 10) / 10} ${me.unit}`);
        });
    },
    convert(msvalue, unit) {
      switch (unit) {
        case 'kt':
          return msvalue * 1.943844492;
        case 'kmh':
          return msvalue * 3.6;
        default:
          return msvalue;
      }
    },
    initStuve(div) {
      const me = this;
      const wrapper = d3.select(div);

      // wrapper.selectAll('g').remove();
      // wrapper.selectAll('text').remove();

      let width = parseInt(wrapper.style('width'), 10);
      let height = width; // tofix
      const margin = {
        top: 30, right: 40, bottom: 20, left: 35
      }; // container margins
      // functions for Scales and axes. Note the inverted domain for the y-scale: bigger is up!
      const r = d3.scaleLinear().range([0, 300]).domain([0, 150]);
      const y2 = d3.scaleLinear();
      let w; let h; let x; let y; let xAxis; let yAxis; let
        yAxis2;
      const data = [];
      // containers
      const svg = wrapper.append('svg').attr('id', 'svg'); // main svg
      this.container = svg.append('g').attr('id', 'container'); // container
      const skewtbg = this.container.append('g').attr('id', 'skewtbg').attr('class', 'skewtbg');// background
      me.stuveGroup = this.container.append('g').attr('class', 'skewt'); // put skewt lines in this group
      const barbgroup = this.container.append('g').attr('class', 'windbarb'); // put barbs in this group

      // local functions
      function setVariables() {
        width = parseInt(wrapper.style('width'), 10) - 10; // tofix: using -10 to prevent x overflow
        height = width; // to fix
        w = width - margin.left - margin.right;
        h = width - margin.top - margin.bottom;
        me.w = w;
        me.h = h;
        x = d3.scaleLinear().range([0, w]).domain([me.tMin, me.tMax]);
        y = d3.scaleLog().range([0, h]).domain([me.topPressure, me.basePressure]);
        me.x = x;
        me.y = y;
        xAxis = d3.axisBottom().scale(x).tickSize(0, 0).ticks(10);
        yAxis = d3.axisLeft().scale(y).tickSize(0, 0).tickValues(me.pLines)
          .tickFormat(d3.format('.0d'));
        yAxis2 = d3.axisRight().scale(y).tickSize(5, 0).tickValues(me.pTicks);
      }

      const drawBackground = () => {
        // Add clipping path
        skewtbg.append('clipPath')
          .attr('id', 'clipper')
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', w)
          .attr('height', h);

        // Skewed temperature lines
        skewtbg.selectAll('templine')
          .data(d3.range(this.tMin, this.tMax, this.tStep))
          .enter().append('line')
          .attr('x1', (d) => x(d))
          .attr('x2', (d) => x(d))
          .attr('y1', 0)
          .attr('y2', h)
          .attr('class', (d) => { if (d === 0) { return 'tempzero'; } return 'gridline'; })
          .attr('clip-path', 'url(#clipper)');

        // Logarithmic pressure lines
        skewtbg.selectAll('pressureline')
          .data(this.pLines.concat(this.pTicks))
          .enter().append('line')
          .attr('x1', 0)
          .attr('x2', w)
          .attr('y1', (d) => y(d))
          .attr('y2', (d) => y(d))
          .attr('class', 'gridline');

        // Line along right edge of plot
        skewtbg.append('line')
          .attr('x1', w - 0.5)
          .attr('x2', w - 0.5)
          .attr('y1', 0)
          .attr('y2', h)
          .attr('class', 'gridline');

        // Add axes
        skewtbg.append('g').attr('class', 'x axis').attr('transform', `translate(0,${h - 0.5})`).call(xAxis);
        skewtbg.append('g').attr('class', 'y axis').attr('transform', 'translate(-0.5,0)').call(yAxis);
        skewtbg.append('g').attr('class', 'y axis ticks').attr('transform', 'translate(-0.5,0)').call(yAxis2);
      };

      function resize() {
        skewtbg.selectAll('*').remove();
        setVariables();
        svg.attr('width', w + margin.right + margin.left).attr('height', h + margin.top + margin.bottom);
        me.container.attr('transform', `translate(${margin.left},${margin.top})`);
        drawBackground();
        me.plot(me.lines);
      }

      // assigns d3 events
      d3.select(window).on('resize', resize);

      const clear = (s) => {
        me.stuveGroup.selectAll('path').remove(); // clear previous paths from skew
        barbgroup.selectAll('use').remove(); // clear previous paths  from barbs
        // must clear tooltips!
        me.container.append('rect')
          .attr('class', 'overlay')
          .attr('width', w)
          .attr('height', h)
          .on('mouseover', () => false)
          .on('mouseout', () => false)
          .on('mousemove', () => false);
      };

      setVariables();
      resize();
      this.plot(this.lines);
      // this.generatemixRatios();
    }
  }
};
</script>

<style scoped>
.control-container {
  display: flex;
  zoom: 0.8;
  height: 20px;
  margin-left: 15px;
  padding-bottom: 65px;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 45%;
}

.diagram-title {
  font-size: 60%;
  font-weight: 500;
  text-align: center;
  display: table-header-group;
}
</style>
