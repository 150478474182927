import { apiClient } from './config.js';

export default {
  getProducts() {
    return apiClient.get('products');
  },
  getPaymentService(code) {
    return apiClient.get(`service_payments/${code}`);
  }
};
