/* eslint-disable consistent-return */
import VueJwtDecode from 'vue-jwt-decode';
// Vuex
import store from './store';
import AuthService from '@/services/AuthService.js';

export function randomColor() {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return { r, g, b };
}

export function VaporPressure(Ctemp) {
  const E = 6.11 * (10 ** (7.5 * (Ctemp / (237.7 + Ctemp))));
  return E;
}

export function MixRatio(VaporPress, MBpressure) {
  const MixRatio = 621.97 * (VaporPress / (MBpressure - VaporPress));
  return MixRatio;
}

export function getVP(MixRatio, MBpressure) {
  const VaporPress = MixRatio * (MBpressure / (621.97 - MixRatio));
  return VaporPress;
}

export function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x);
}

export function getTemp(VaporPress) {
  const lVal = getBaseLog(10, (VaporPress / 6.11));
  const T = 237.7 * (lVal / (7.5 - lVal));
  return T;
}

export function makeId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getCookie(name) {
  // obtener las cookies del documento
  const cookiesList = document.cookie.split(';').map((x) => x.trim());
  const cookiesObj = {};
  for (let index = 0; index < cookiesList.length; index++) {
    const element = cookiesList[index].split('=');
    // eslint-disable-next-line prefer-destructuring
    cookiesObj[element[0]] = element[1];
  }

  let cookieValue = '';
  if (name in cookiesObj) {
    cookieValue = cookiesObj[name];
  }
  return cookieValue;
}

export function currencyFormat(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
}

export async function getToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  let accessToken = localStorage.getItem('accessToken');
  if (accessToken === null) {
    return '';
  }
  const accessData = VueJwtDecode.decode(accessToken);
  const refreshData = VueJwtDecode.decode(refreshToken);
  // validar que el token de actialización este vigente
  if (refreshData.exp < Date.now() / 1000) {
    // desloguear al usuario
    store.commit('auth/LOGOUT');
    store.commit('ui/SHOW_ALERT', {
      level: 'error',
      message: '¡Sus credenciales han expirado!'
    },
    { root: true });
    return;
  }
  // validar que el token de acceso este vigente
  if (accessData.exp < Date.now() / 1000) {
    // refrescar el token de acceso
    const resp = await AuthService.getNewToken(refreshToken);
    accessToken = resp.data.access;
    localStorage.setItem('accessToken', accessToken);
  }
  return accessToken;
}

const graphColors = [
  '#D32F2F',
  '#00796B',
  '#C2185B',
  '#7B1FA2',
  '#EF6C00',
  '#512DA8',
  '#303F9F',
  '#0097A7',
  '#1976D2',
  '#0288D1',
  '#388E3C',
  '#689F38',
  '#827717',
  '#D84315',
  '#5D4037',
  '#455A64',
];

export function choosePaletteColor(index) {
  let color = '#757575';
  if ((index + 1) > graphColors.length) {
    color = graphColors[(index % graphColors.length) - 1];
  } else {
    color = graphColors[index];
  }
  return color;
}
