<template lang="pug">
v-container
  view-banner(
    :title="banner.title"
    :summary="banner.summary"
  )
  v-row(style="height: auto")
    v-col(v-for="item in items" cols="12" sm="4" md="3")
      v-card.mx-3.my-3(width="")
        v-img(height="200px", :src="item.image")

        v-card-text(class="text-center")
          h3(class="mb-2 blue--text text--darken-3 font-weight-light") {{ item.title }}
          div(class="font-weight-light text--primary") {{ trunkText(item.description) }}
          v-btn(
            class="mt-2 success font-weight-light"
            elevation="2"
            @click="viewItem(item)") Saber más

  contact-us-form
</template>

<script>
import { mapState } from 'vuex';
import ViewBanner from '@/components/ViewBanner';
import ContactUsForm from '@/components/forms/ContactUsForm';

export default {
  name: 'Studies',
  components: {
    ViewBanner,
    ContactUsForm,
  },
  data() {
    return {
      banner: null,
      items: [],
    };
  },
  computed: {
    ...mapState('meteocolombia', ['studies', 'products']),
  },
  watch: {
    $route(to) {
      const viewPath = to.path;
      this.selectItems(viewPath);
    }
  },
  created() {
    const viewPath = this.$route.path;
    this.selectItems(viewPath);
  },
  methods: {
    trunkText(text, length = 100) {
      return text <= length ? text : `${text.slice(0, length)}...`;
    },
    selectItems(route) {
      switch (route) {
        case '/studies':
          this.banner = this.studies.banner;
          this.items = this.studies.items;
          break;
        case '/products':
          this.banner = this.products.banner;
          this.items = this.products.items;
          break;
        default:
          break;
      }
    },
    viewItem(item) {
      let destinationRoute = '';
      if (this.$route.path === '/studies') {
        destinationRoute = '/view-study';
      } else {
        destinationRoute = '/view-product';
      }
      this.$router.push({ path: `${destinationRoute}/${item.id}`, params: { item } });
    }
  },
};
</script>
