<template lang="pug">
form(method="post" :action="payUWebcheckout" ref="payForm")
  input(name="merchantId" type="hidden" :value="merchantId")
  input(name="accountId" type="hidden" :value="accountId")
  input(name="description" type="hidden" :value="description")
  input(name="referenceCode" type="hidden" :value="referenceCode")
  input(name="amount" type="hidden" :value="amount")
  input(name="tax" type="hidden" :value="tax")
  input(name="taxReturnBase" type="hidden" :value="taxReturnBase")
  input(name="currency" type="hidden" :value="currency")
  input(name="signature" type="hidden" :value="signature" )
  input(v-for="app in purchasedApps" :name="app.app.url" type="hidden" :value="app.count")
  input(name="buyerEmail" type="hidden" :value="buyerEmail")
  input(name="responseUrl" type="hidden" :value="responseUrl")
  input(name="confirmationUrl" type="hidden" :value="confirmationUrl")
  input(name="Submit" type="submit" value="Enviar")
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PaymentForm',
  data: () => ({
  }),
  computed: {
    ...mapState('subscription', [
      'payUWebcheckout',
      'merchantId',
      'accountId',
      'referenceCode',
      'currency',
      'buyerEmail',
      'responseUrl',
      'confirmationUrl',
      'signature',
      'description',
      'amount',
      'tax',
      'taxReturnBase'
    ]),
    ...mapGetters('subscription', ['totalPayment', 'purchasedApps']),
  },
  mounted() {
  },
  methods: {
    sendPay() {
      this.$refs.payForm.submit();
    }
  },
};
</script>
