<template lang="pug">
div(style="height: 100%")
  l-map(
      v-if="showMap"
      :zoom="initialMapZoom"
      :center="{lat: mapLat, lng: mapLon}"
      :options="mapOptions"
      style="height: 100%; z-index:10"
      ref="myMap"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @ready="lefletMapReady()"
    )
      l-control-zoom(position="topright")
      lf-static-map-frame
      l-control-layers(ref="lyControl" :collapsed="collapsed")
      l-wms-tile-layer(
        v-for="layer in baseLayers"
        :key="layer.name"
        :base-url="layer.url"
        :layers="layer.layers"
        :visible="layer.visible"
        :name="layer.name"
        :layer-type="layer.type"
        :transparent="layer.transparent",
        :format="layer.format"
      )
      l-control(position="bottomleft")
        v-alert(dense :type="alertStatus" dismissible v-if="showAlert" v-model="showAlert")
          | {{alertMessage}}
      //- v-marker-cluster
      l-geo-json(
        v-if="geojson"
        :geojson="geojson"
        ref="geoLayer"
        @ready="geoLayerReady()"
        :options="{onEachFeature: jsonEachFeature, pointToLayer: jsonPntTolayer}")

</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import L, { latLng } from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LControlLayers,
  LWMSTileLayer,
  LControl,
  LGeoJson,
  LControlZoom,
} from 'vue2-leaflet';
import { mapMutations, mapState } from 'vuex';
import LfStaticMapFrame from '@/components/Leaflet/LfStaticMapFrame.vue';
import {
  serverApi, frontServerUrl, serverUrl, locationIcon
} from '../config.js';
import { getToken } from '../utils.js';

export default {
  name: 'Leaflet',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LControlLayers,
    LControl,
    LGeoJson,
    'l-wms-tile-layer': LWMSTileLayer,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    LfStaticMapFrame,
    LControlZoom,
  },
  data() {
    return {
      collapsed: false,
      map: null,
      geoLayer: null,
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      geojson: null,
      showMap: true,
      placesData: [],
      layerName: '',
      locationIcon,
      showTip: true,
      currentAppRate: {}
    };
  },
  computed: {
    ...mapState('ui/leaflet', ['alertMessage', 'showAlert', 'alertStatus', 'loadingData']),
    ...mapState('gis', ['initialMapZoom', 'baseLayers', 'mapLat', 'mapLon'])
  },
  watch: {
    $route(to) {
      const { location } = to.params;
      this.fillLayer(location);
    }
  },
  async mounted() {
    this.map = await this.$refs.myMap.mapObject;
    this.fillLayer(this.$route.params.location);
  },
  methods: {
    ...mapMutations('gis', ['CHANGE_MAP_LOCATION', 'UPDATE_BASE_LAYERS', 'RESTORE_BASE_LAYERS']),
    ...mapMutations('ui/leaflet', ['TOGGLE_LOADING', 'SHOW_ALERT']),
    ...mapMutations('subscription', ['SET_PAID_VERSION']),
    jsonEachFeature(ft, ly) {
      ly.bindPopup(this.getPopupString(ft), { closeButton: false, offset: L.point(0, 0) });
      ly.on('mouseover', () => { ly.openPopup(); });
      // ly.on('mouseout', () => { ly.closePopup(); });
    },
    toggleTip() {
      this.showTip = !this.showTip;
    },
    jsonPntTolayer(feature, latlng) {
      const markerIcon = L.icon({
        iconUrl: require(`@/assets/marker-icons/${this.locationIcon}`),
        iconSize: [15, 15], // size of the icon
        iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
        popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
      });
      return L.marker(latlng, { icon: markerIcon });
    },
    async fillLayer(location) {
      // this.TOGGLE_LOADING();
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      fetch(`${serverApi}layers/${location}`, {
        headers
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          const layerName = responseJson.name;
          if (responseJson.points) {
            // this.TOGGLE_LOADING();
            this.layerName = responseJson.name;
            this.currentAppRate = responseJson.rate;
            this.SHOW_ALERT({
              status: 'success',
              message: `Se cargaron ${responseJson.points.features.length} puntos correctamente
              para ${layerName}`,
            });
            this.geojson = responseJson.points;

            // cambiar  la ubicación del mapa si tiene ubicación especifica
            if (responseJson.central_point && responseJson.zoom) {
              const coords = responseJson.central_point.coordinates;
              this.CHANGE_MAP_LOCATION({
                center: [coords[1], coords[0]],
                zoom: responseJson.zoom
              });
            }

            // actualizar la version paga si existe
            if (responseJson.paid_version.length > 0) {
              this.SET_PAID_VERSION(responseJson.paid_version[0]);
            } else {
              this.SET_PAID_VERSION(-1);
            }

            // actualizar las capas base del mapa leaflet
            if ('base_layers' in responseJson) {
              this.UPDATE_BASE_LAYERS(responseJson.base_layers);
              if (responseJson.base_layers.length === 0) {
                this.RESTORE_BASE_LAYERS();
              }
            } else {
              this.RESTORE_BASE_LAYERS();
            }
            this.updateLocation(layerName);
          } else if ('error' in responseJson) {
            this.SHOW_ALERT({
              status: 'error',
              message: responseJson.error,
            });
          } else {
            this.SHOW_ALERT({
              status: 'error',
              message: `Se presentó un error al cargar los puntos para ${layerName}`,
            });
          }
        });
    },
    updateLocation(location) {
      const overlayLayers = this.layerControl._layers.filter((l) => l.overlay === true);
      console.log(this.layerControl._layers);
      // Eliminar las capas superpuestas si existen, del mapa y del control
      if (overlayLayers) {
        for (let index = 0; index < overlayLayers.length; index++) {
          const element = overlayLayers[index];
          this.map.removeLayer(element.layer);
          this.layerControl.removeLayer(element.layer);
        }
      }
      // actualizar el control de la capa solo si el objeto de la capa
      // geojson ya esta lista (this.geoLayerReady)
      if (this.geoLayer) {
        this.geoLayer.addTo(this.map);
        this.layerControl.addOverlay(this.geoLayer, location);
      }
    },
    getPopupString(feat) {
      const coords = feat.geometry.coordinates;
      const props = feat.properties;
      const data = props.icon;
      const targetUrl = `${frontServerUrl}forecast/${props.pk}?lat=${coords[1]}&lng=${coords[0]}&name=${props.name}`;
      const morningIcon = 'weather' in data.morning ? `${serverUrl}/static/icons/${data.morning.weather}` : '';
      const afternoonIcon = 'weather' in data.afternoon ? `${serverUrl}/static/icons/${data.afternoon.weather}` : '';
      return `<div class="meteo-popup-container">
        <div class="meteo-popup-header">
          <div class="meteo-popup-title">${props.name}</div>
        </div>
        <div class="meteo-popup-content">
          <div class="meteo-info-period">
            <span class="meteo-info-period-title">Mañana</span>
            <div class="meteo-info-container">
              <div>
                <img class="meteo-popup-image" src="${morningIcon}">
              </div>
              <div>
                <span class="meteo-popup-item-left">T. max.:</span>
                <span class="meteo-popup-item-right">${data.morning.tMax.toFixed(1)}°</span>
              </div>
              <div>
                <span class="meteo-popup-item-left">T. min.:</span>
                <span class="meteo-popup-item-right">${data.morning.tMin.toFixed(1)}°</span>
              </div>
            </div>
          </div>
          <div class="meteo-info-period">
            <span class="meteo-info-period-title">Tarde</span>
            <div class="meteo-info-container">
              <div>
                <img class="meteo-popup-image" src="${afternoonIcon}">
              </div>
              <div>
                <span class="meteo-popup-item-left">T. max.:</span>
                <span class="meteo-popup-item-right">${data.afternoon.tMax.toFixed(1)}°</span>
              </div>
              <div>
                <span class="meteo-popup-item-left">T. min.:</span>
                <span class="meteo-popup-item-right">${data.afternoon.tMin.toFixed(1)}°</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="meteo-popup-divider"></div>
      <a class="meteo-popup-button" href="${targetUrl}" target="_blank">REPORTE COMPLETO</a>`;
    },
    zoomUpdate(zoom) {
      console.log('zoom updtaded: ', zoom);
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      console.log('center updated', center);
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert('Click!');
    },
    goPayment() {
      this.$bus.$emit('select-modal-form', {
        component: 'credit-manager',
        title: 'Adquiera créditos para las aplicaciones de Meteocolombia'
      });
    },
    geoLayerReady() {
      this.geoLayer = this.$refs.geoLayer.mapObject;
      this.layerControl.addOverlay(this.geoLayer, this.layerName);
    },
    async lefletMapReady() {
      this.map = this.$refs.myMap.mapObject;

      this.layerControl = this.$refs.lyControl.mapObject;
    },
  },
};
</script>

<style scoped>
  .meteo-marker-icon {
    text-align: center; /* Horizontally center the text (icon) */
    line-height: 20px; /* Vertically center the text (icon) */
  }
</style>
