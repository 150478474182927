// Vuex (state pattern)
import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import ui from '@/store/modules/ui';
import meteocolombia from '@/store/modules/meteocolombia';
import gis from '@/store/modules/gis';
import blog from '@/store/modules/blog';
import subscription from '@/store/modules/subscription';

Vue.use(Vuex);

// Crear una nueva instancia de store.
const store = new Vuex.Store();

// Registrar modulos en el store
store.registerModule('auth', auth);
store.registerModule('ui', ui);
store.registerModule('meteocolombia', meteocolombia);
store.registerModule('gis', gis);
store.registerModule('blog', blog);
store.registerModule('subscription', subscription);

export default store;
