<template lang="pug">
div(class="mt-8 text-center")
  v-card(
    max-width="400"
    class="mt-4 mx-auto")
    v-list
      v-list-item(class="justify-center")
          v-icon(color="#1976D2") mdi-account-circle mdi-48px
      v-list-item(link class="justify-center")
        v-list-item-content
          v-list-item-title(class="text-h6") {{userInfo.username}}
          v-list-item-subtitle {{userInfo.email}}
    v-divider
    v-list( nav dense)
      v-list-item-group(color="primary")
        v-list-item
          v-list-item-content
            v-list-item-title(class="d-flex flex-row")
              | <span class="mt-2">Nombres:</span>
              | <span class="text-h6 font-weight-light ml-2 blue--text">
              | {{userInfo.first_name}}</span>
        v-list-item
          v-list-item-content
            v-list-item-title(class="d-flex flex-row")
              | <span class="mt-2">Apellidos:</span>
              | <span class="text-h6 font-weight-light ml-2 blue--text">
              | {{userInfo.last_name}}</span>
        v-list-item
          v-list-item-content
            v-list-item-title(class="d-flex flex-row")
              | <span class="mt-2">Identificación:</span>
              span(class="text-h6 font-weight-light ml-2 blue--text")
                | {{userInfo.identification_type}} - {{userInfo.identification}}
        v-list-item
          v-list-item-content
            v-list-item-title(class="d-flex flex-row")
              | <span class="mt-2">Teléfono:</span>
              | <span class="text-h6 font-weight-light ml-2 blue--text">
              | {{userInfo.telephone}}</span>
        v-list-item
          v-list-item-content
            v-list-item-title(class="d-flex flex-row")
              | <span class="mt-2">Dirección:</span>
              | <span class="text-h6 font-weight-light ml-2 blue--text">{{userInfo.address}}</span>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AccountInfo',
  data: () => ({
  }),
  computed: {
    ...mapState('subscription', ['userInfo']),
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions('subscription', ['getUserInfo'])
  },
};
</script>
