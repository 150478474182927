<template lang="pug">
div
  //- BEGIN MAP BOTTOM BAR
  div.mt-10(class="static-bottom-bar p-fixed")
    .bottom-control-icon.bgLeafletControl.pt-1(
      class="boton-bottom"
      v-for="item in staticBottomItems"
      @click="selectItem(item)",
      :title="item.title"
    )
      i(:class="['fas', item.icon, 'f-meteocolombia']")
    meteo-blog(v-if="showBlog" @mouseleave.native="showBlog = false")
    .bottom-control-icon.bgLeafletControl.pt-1(
      class="boton-bottom"
      :title="blogItem.title"
      v-else
      @mouseover="showBlog = true"
    )
      i(:class="['fas', blogItem.icon, 'f-meteocolombia']")
  v-dialog(
    v-model="showDialogForm"
    transition="dialog-bottom-transition"
    max-width="550")
    v-card
      v-toolbar.text-caption(color="primary" dark) {{currentDescription}}
      v-card-text
        component(
          v-bind:is="currentComponent"
          @response="changeForm"
        )
      v-card-actions(class="justify-end")
        v-btn(
          text
          @click="showDialogForm = false") Cerrar
  //- END MAP BOTTOM BAR
  //- BEGIN MAP TOP BAR
  div(class="top-static-bar")
    v-row(class="text-center pt-2")
      v-col(class="d-flex flex-row" cols="12" sm="3" md="3" lg="3" xl="3")
        v-btn(
          elevation="2"
          fab
          small
          title="Volver a la página principal"
          v-on="on"
          @click="goHome"
        )
          v-icon mdi-arrow-left
        meteo-brand
      v-spacer
      v-col(cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex flex-row justify-end")
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
      v-col(class="mode-dark d-flex flex-row justify-center" cols="12" sm="3" md="3" lg="3" xl="3")
        v-switch(
          class="mt-0"
          @click="toggleTheme"
          color="orange darken-3"
          title="Activar tema nocturno")
        v-icon(v-if="!$vuetify.theme.dark" class="mb-4" color="yellow") mdi-white-balance-sunny
        v-icon(v-else class="mb-4" color="white") mdi-weather-night
  //- END MAP TOP BAR
    //- BEGIN BOX APPPLICATION
  //v-row
    v-col
      v-tooltip
        template(v-slot:activator="{ on, attrs }")
          v-btn(
              class="static-apps-box p-fixed boton-back bgLeafletControl"
              elevation="2"
              fab
              small
              title="Volver a la página principal"
              v-on="on"
              @click="goHome"
          )
              v-icon mdi-arrow-left
  //v-expansion-panels(
    class="static-apps-box p-fixed"
    hover
    v-model="panel"
    multiple)
    v-expansion-panel(
      //:style="{background: item.color, color: 'white'}"
      v-for="(item,i) in appsBox"
      @mouseleave.native="actionPanel('leave', i)"
      @mouseover.native="actionPanel('over', i)"
      //:key="i")
      v-expansion-panel-header {{ item.title }}
      v-expansion-panel-content(class="list-content")
        app-item(v-for="(subitem, j) in item.apps" :item-data="subitem")
  //- END BOX APPPLICATION

</template>

<script>
import {
  mapState, mapMutations, mapGetters, mapActions
} from 'vuex';
import { LControl } from 'vue2-leaflet';
import FaqForm from '@/components/forms/FaqForm.vue';
import RegisterForm from '@/components/forms/RegisterForm.vue';
import LoginForm from '@/components/forms/LoginForm.vue';
import SubscriptionForm from '@/components/forms/SubscriptionForm.vue';
import CreditManager from '@/components/subscription/CreditManager.vue';
import MeteoBrand from '@/components/ui/MeteoBrand.vue';
import MeteoBlog from '@/components/ui/MeteoBlog.vue';
import MeteoNews from '@/components/ui/MeteoNews.vue';
import AppItem from '@/components/ui/AppItem.vue';

export default {
  name: 'StaticContent',
  components: {
    LControl,
    FaqForm,
    RegisterForm,
    MeteoBrand,
    LoginForm,
    SubscriptionForm,
    MeteoBlog,
    MeteoNews,
    CreditManager,
    AppItem,
  },
  computed: {
    ...mapState('ui', ['appsBox', 'showFaqs']),
    ...mapState('meteocolombia', ['staticTopItems', 'staticBottomItems',
      'loginItem', 'logoutItem', 'registerItem', 'blogItem']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedIn', 'showAuthItem']),
    showDialogForm: {
      get() {
        return this.$store.state.ui.showDialogForm;
      },
      set(value) {
        return this.$store.commit('ui/TOGGLE_FORM_DIALOG', value);
      }
    }
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_SIDEBAR']),
    ...mapActions('auth', ['userLogout']),
    goHome() {
      this.$router.push('/');
    },
    toggleTheme() {
      this.$vuetify.theme.themes.dark.anchor = '#41b883';
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    selectItem(currentItem) {
      if (currentItem.url) {
        this.$router.push(currentItem.url);
      }
      if (currentItem.component) {
        this.currentDescription = currentItem.description;
        this.currentComponent = currentItem.component;
        this.$store.commit('ui/TOGGLE_FORM_DIALOG', true);
      } else {
        window.location.href = currentItem.url;
      }
    },
    actionPanel(action, panel) {
      if (action === 'over') {
        this.panel.push(panel);
      } else {
        this.panel = [];
      }
    },
    changeForm(formName) {
      if (formName === 'registerItem') this.selectItem(this.registerItem);
      else if (formName === 'loginItem') this.selectItem(this.loginItem);
    },
  },
  watch: {
    showFaqs(newVal) {
      if (newVal) {
        const item = this.staticBottomItems.find((i) => i.key === 'faqs');
        this.selectItem(item);
      }
    },
  },
  created() {
    // evento para cambiar el formulario que se debe mostrar
    // en el modal
    this.$bus.$on('select-modal-form', (data) => {
      // mostrar el formulario si no esta desplegado
      if (this.showDialogForm === false) {
        this.$store.commit('ui/TOGGLE_FORM_DIALOG');
      }
      // cambiar el componente del formulario
      this.currentComponent = data.component;
      // Poner el titulo al formulario
      this.currentDescription = data.title;
    });
  },
  data() {
    return {
      showBlog: false,
      currentDescription: '',
      currentComponent: '',
      panel: [],
    };
  }
};
</script>
<style scoped>
.mode-dark{
  flex: 1 0 18.333333% !important;
  max-width: 130px !important;
}
.boton-back{
  margin-left: -500px !important;
  margin-top: 62px !important;
  background-color: #E1F5FE !important;
}
.boton-bottom{
  background-color: #E1F5FE !important;
}
.boton-bottom:hover{
  background-color: lightsteelblue !important;
  color: #ffffff !important;
}
.div, .v-dialog{
  overflow-y: hidden !important;
}
.v-dialog .v-card .v-card__text {
  overflow-y: scroll;
  max-height: 550px;
}
</style>
