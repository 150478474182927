/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import '../../public/resources/libs/motionCaptcha.js';
import '../../public/resources/libs/motionCaptcha.css';

const jqCaptchaPlugin = {};

jqCaptchaPlugin.install = function (Vue) {
  Vue.prototype.$jqCaptcha = $;
};

export default jqCaptchaPlugin;
