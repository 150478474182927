<template lang="pug">
v-card(
  max-width="400"
  class="mx-auto mt-5")
  v-app-bar(
    dark
    color="lime darken-1")

    v-toolbar-title Confirmación pago PayU

  v-container
    v-row(dense)
      v-col(class="d-flex flex-column text-center" cols="12")
        v-icon(
          size=125
          :color="$route.query.lapTransactionState === 'APPROVED' ? 'green darken-1' : 'yellow'")
          | {{getIcon($route.query.lapTransactionState)}}
        span(class="text-h5") {{getMessage($route.query.lapTransactionState)}}

      v-col(cols="12")
        v-list(class="transparent")
          v-subheader Nosotros
          v-list-item
            v-list-item-title Nombre:
            v-list-item-subtitle(class="text-right") {{$route.query.merchant_name}}
          v-divider
          v-list-item
            v-list-item-title Direccion:
            v-list-item-subtitle(class="text-right") {{$route.query.merchant_address}}
          v-divider
          v-list-item
            v-list-item-title Teléfono:
            v-list-item-subtitle(class="text-right") {{$route.query.telephone}}
          v-divider
          v-list-item
            v-list-item-title Sitio Web:
            v-list-item-subtitle(class="text-right") {{$route.query.merchant_url}}

          v-subheader Tu compra
          v-list-item
            v-list-item-title Código de Referencia:
            v-list-item-subtitle(class="text-right text-h6") {{$route.query.referenceCode}}
          v-divider
          v-list-item
            v-list-item-title Orden de pago:
            v-list-item-subtitle(class="text-right text-h6") {{$route.query.reference_pol}}
          v-divider
          v-list-item
            v-list-item-title Valor compra:
            v-list-item-subtitle(class="text-right")
              |{{$route.query.currency}} {{$route.query.TX_VALUE}}
          v-divider
          v-list-item
            v-list-item-title Cuenta de correo:
            v-list-item-subtitle(class="text-right") {{$route.query.buyerEmail}}

      v-col(class="d-flex flex-column text-center" cols="12")
        span(
          v-if="$route.query.lapTransactionState === 'APPROVED'"
          class="text-body-2 font-weight-light")
          | Gracias por adquirir nuestros servicios, recuerde
          | que con Meteocolombia el clima siempre esta a su favor.
        v-btn(
          color="blue"
          class="ma-2 mt-4 white--text"
          @click="$router.push({path: '/'})")
          v-icon(
            left
            dark) mdi-arrow-left
          | Ir a la página principal

</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ResponsePage',
  components: {
  },
  data: () => ({
  }),
  computed: {
    ...mapState('subscription', ['paymentMessage', 'paymentStatus'])
  },
  methods: {
    getMessage(status) {
      return status === 'APPROVED' ? 'Pago redimido exitosamente' : 'No se pudo validar el pago';
    },
    getIcon(status) {
      return status === 'APPROVED' ? 'mdi-check-decagram' : 'mdi-alert-decagram';
    },
  }
};
</script>

<style scope>
.v-main {
  background-color: #E0E0E0!important;
}
</style>
