import { apiClient } from './config.js';

export default {
  // Conseguir los puntos de una capa
  getPoints(location) {
    return apiClient.get(location);
  },

  // conseguir el archivo grid ascii para una variable NETCDF
  getNetCDFASCII(variable, date, level) {
    let url = `api/ascii_data?variable=${variable}&date=${date}`;
    if (level !== 'no') {
      url += `&level=${level}`;
    }
    return apiClient.get(url);
  },

  // actualizar los parametros del frontend
  getFrontParams(front) {
    const url = `fronts/${front}/caption`;
    return apiClient.get(url);
  }
};
