import GisService from '@/services/GisService.js';

const defaultBaseLayer = {
  name: 'Open Street',
  visible: true,
  attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  type: 'base',
  transparent: false,
  format: 'image/png',
  id: 'base_open_street'
};

const gis = {
  namespaced: true,
  state: {
    jsonData: {},
    initialMapZoom: 7,
    mapLat: 5.550381,
    mapLon: -73.564453,
    appModules: {},
    baseLayers: [],
    staticLayers: [],
  },
  mutations: {
    CHANGE_MAP_LOCATION(state, { center, zoom }) {
      state.initialMapZoom = zoom;
      [state.mapLat, state.mapLon] = center;
    },
    UPDATE_BASE_LAYERS(state, layers) {
      state.baseLayers = layers;
    },
    RESTORE_BASE_LAYERS(state) {
      state.baseLayers = [defaultBaseLayer];
    },
  },
  actions: {
    getPoints({ commit }, location) {
      commit('ui/leaflet/TOGGLE_LOADING', null, { root: true });
      return GisService.getPoints(location)
        .then((resp) => {
          commit('ui/leaflet/TOGGLE_LOADING', null, { root: true });
          commit('ui/leaflet/SHOW_ALERT', {
            level: 'success',
            message: 'Puntos cargados correctamente',
          }, { root: true });
          return resp.data;
        })
        .catch((err) => {
          commit('ui/leaflet/TOGGLE_LOADING', null, { root: true });
          commit('ui/leaflet/SHOW_ALERT', {
            status: 'error',
            message: 'Se presentó un error al cargar los puntos',
          }, { root: true });
          return err;
        });
    },
    getNetCDFASCII({ commit }, { variable, date, level }) {
      return GisService.getNetCDFASCII(variable, date, level)
        .then((resp) => {
          commit('ui/leaflet/SHOW_ALERT', {
            status: 'success',
            message: `Datos para ${variable} cargados correctamente`,
          }, { root: true });
          return resp.data;
        })
        .catch((err) => {
          commit('ui/leaflet/SHOW_ALERT', {
            status: 'error',
            message: `Error al cargar datos para ${variable}`,
          }, { root: true });
          return err;
        });
    },
    getFrontParams({ state }, front) {
      return GisService.getFrontParams(front)
        .then((resp) => {
          if ('baseLayers' in resp) {
            state.baseLayers = resp.baseLayers;
          }
          if ('zoom' in resp) {
            state.initialMapZoom = resp.zoom;
          }
          if ('central_point' in resp) {
            [state.mapLon, state.mapLat] = resp.central_point.coordinates;
          }
          return resp;
        })
        .catch((err) => err);
    }
  }
};

export default gis;
