<template lang="pug">
  div(
    class="banner-wrapper bannerWrapper my-3"
    style="border-bottom-color: #546E7A!important; border-bottom-style: solid;")
    v-row
      span(class="grey--text text--darken-4 banner-title text-h4 font-weight-light") {{title}}
    v-row
      span(class="banner-summary font-weight-light text-body-2") {{summary}}
</template>

<script>
export default {
  name: 'ViewBanner',
  props: {
    title: {
      type: String,
      required: true
    },
    summary: {
      type: String,
      required: true
    }
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>
  .banner-wrapper {
    min-height: 200px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    position: relative;
    padding-bottom: 15px;
  }

  .banner-title {
    top: 0px;
    max-width: 55%;
    padding-left: 20px;
    padding-top: 20px;
  }

  .banner-summary {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
</style>
