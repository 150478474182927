import BlogService from '@/services/BlogService.js';

const blog = {
  namespaced: true,
  state: {
    posts: [],
  },
  actions: {
    getPosts({ state }) {
      return BlogService.getPosts()
        .then((resp) => {
          state.posts = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
};

export default blog;
