<template lang="pug">
div
  l-marker(
    v-if="rosePnt"
    :lat-lng="rosePnt"
    :icon="icon"
    @ready="markerMixinReady"
    ref="mixinMarker")
  //- BOX EN MODO RESPONSIVE PARA DESKTOP
  l-control.control-container.bgLeafletControl.hidden-md-and-down(
    :style="{width: showTip === true ? 'map-control-opened' : 'map-control-closed'}"
    position="topright")
    .control-icon.py-1.px-1(
      v-if="!showTip",
      @click="toggleTip",
      style="cursor: pointer"
      title="Dibuja rosa de viento"
    )
      v-icon(large) mdi-compass-rose
    .control-tip.hidden-md-and-down(v-else)
      .text-h5.mb-1
        | Rosa de Viento
        v-btn(
          small
          icon="mdi-chevron-right"
          variant="text"
          elevation="0"
          density="comfortable"
          class="text-center float-right pa-2 my-1"
          @click="cancel")
          v-icon(large) mdi-chevron-double-right
      v-divider
      .my-2.control-internal-content
        | Seleccione un punto en el mapa para desplegar la rosa de viento
      v-divider(v-if="rosePnt")
      .my-2(v-if="rosePnt")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Punto seleccionado
        div {{rosePnt.lat}} | {{rosePnt.lng}}
      forecast-variables(ref="variablesControl")
      v-btn(
        v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
        rounded
        class="text-none text-subtitle-1 premium-button-lg mb-2"
        variant="elevated"
        color="white"
        :title="premiumTitle"
        @click="goPayment"
        outlined)
        v-icon mdi-medal
        <b>Plan Premium</b>
      v-divider
      span.errorLefletControl--text(v-if="errorMsg" class="mt-2 control-error") {{errorMsg}}
      .mt-2.d-flex.control-internal-content(style="zoom:0.9")
        v-btn(
          :loading="disabledButton"
          :disabled="disabledButton"
          small
          color="#00B0FF"
          class="text-subtitle-2 boton_control"
          @click="createRose") Crear rosa
          v-icon(right dark small) mdi-chart-donut-variant
      //- BOX EN MODO RESPONSIVE PARA MOVILES
  l-control.control-container.bgLeafletControl.hidden-lg-and-up(
    :style="{width: showTip === true ? 'map-control-opened' : 'map-control-closed'}"
    position="bottomright")
    .control-icon.py-1.px-1(
      v-if="!showTip",
      @click="toggleTip",
      style="cursor: pointer"
      title="Dibuja rosa de viento"
    )
      v-icon(large) mdi-compass-rose
    .control-tip.scrollable.hidden-lg-and-up(v-else class="py-4 px-4 mx-1" id="box-small")
      .text-h6.mb-1
        | Rosa de Viento
        v-btn(
          small
          icon="mdi-chevron-right"
          variant="text"
          elevation="0"
          density="comfortable"
          class="text-center float-right pa-2 my-1"
          @click="cancel")
          v-icon(large) mdi-chevron-double-down mdi-24px
      v-divider
      .my-2.control-internal-content
        | Seleccione un punto en el mapa para desplegar la rosa de viento
      v-divider(v-if="rosePnt")
      .my-2(v-if="rosePnt")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Punto seleccionado
        div {{rosePnt.lat}} | {{rosePnt.lng}}
      forecast-variables(ref="variablesControl")
      v-btn(
        v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
        rounded
        class="text-none text-subtitle-1 premium-button-lg mb-2"
        variant="elevated"
        color="white"
        :title="helpText"
        @click="goPayment"
        outlined)
        v-icon mdi-medal
        <b>Plan Premium</b>
      v-divider
      span.errorLefletControl--text(v-if="errorMsg" class="mt-2 control-error") {{errorMsg}}
      .mt-2.d-flex.control-internal-content(style="zoom:0.9")
        v-btn(
          :loading="disabledButton"
          :disabled="disabledButton"
          small
          color="#00B0FF"
          class="text-subtitle-2 boton_control"
          @click="createRose") Crear rosa
          v-icon(right dark small) mdi-chart-donut-variant
</template>

<script>
import { LControl } from 'vue2-leaflet';
import { icon } from 'leaflet';
import markerMixin from '@/mixins/markerMixin.js';
import lfControlMixin from '@/mixins/lfControlMixin.js';
import { serverApi, frontendRoute, premiumTitleText } from '@/config.js';
import ForecastVariables from '@/components/Forecast/ForecastVariables.vue';
import { getToken } from '@/utils.js';

export default {
  name: 'WindRoseControl',
  components: {
    LControl,
    ForecastVariables,
  },
  mixins: [markerMixin, lfControlMixin],
  data() {
    return {
      helpText: `
      Acceda a la información completa: Pronósticos a 48 horas en intervalos de 1 hora,
      herramientas de anaĺisis especializadas. Elija las aplicaciones que usted requiere.
      `,
      dialog: false,
      icon: icon({
        iconUrl: require('@/assets/marker-icon.png'),
        shadowUrl: require('@/assets/marker-shadow.png'),
        iconSize: [25, 37],
        iconAnchor: [16, 37]
      }),
      errorMsg: '',
      rosePnt: null,
      disabledButton: false,
      chartData: null,
    };
  },
  computed: {
    bigChartOptions() {
      const newData = { ...this.chartOptions };
      return Object.assign(newData, { width: 700, height: 700 });
    },
    premiumTitle() {
      return premiumTitleText;
    }
  },
  methods: {
    cancel() {
      this.showTip = !this.showTip;
      this.rosePnt = null;
      this.errorMsg = '';
    },
    async createRose() {
      // limpiar el mensaje de error
      this.errorMsg = '';
      if (!this.rosePnt) {
        this.errorMsg = 'Debe seleccionar un punto en el mapa';
        return;
      }

      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores';
        return;
      }

      // deshabilitar boton mientras los datos cargan
      this.disabledButton = true;

      // obtener coordenadas del marker y enviar petición
      const coords = this.mixinMarker.toGeoJSON().geometry.coordinates;
      // traer los valores de las variables extra si existen
      const extraParams = this.$refs.variablesControl.urlQueryParams();
      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.rosa}?lat=${coords[1]}&lon=${coords[0]}${extraParams}`, {
        headers
      });

      const jsonResp = await response.json();
      if (response.status === 200) {
        this.chartData = jsonResp;
        this.showForecast(
          this.chartData,
          'roseGraph',
          `Rosa de viento (${this.rosePnt.lat} | ${this.rosePnt.lng})`
        );
      } else {
        this.errorMsg = `${response.status} - ${jsonResp || response.statusText}`;
      }

      // devolver a estados iniciales
      this.disabledButton = false;
    },
    mapClick(e) {
      if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.rosePnt = {
          lat,
          lng,
        };
      }
    },
  }
};
</script>

<style scoped>
.columnChart {
  height: 400px;
}
.control-tip {
  padding: 22px !important;
}
.control-internal-content, .v-input__slot {
  padding-right: 42px !important;
}
</style>
