<template lang="pug">
div(class="text-center")
  div(class="text-h6 font-weight-regular") {{graphTitle}}
  component(
    v-bind:is="componentName"
    ref="dynamicGraph"
    v-bind="componentProperties"
    style="margin-left:auto; margin-right:auto")
</template>

<script>
import RoseGraph from '@/components/Forecast/RoseGraph.vue';
import StuveDiagram from '@/components/Forecast/StuveDiagram.vue';
import SkewTDiagram from '@/components/Forecast/SkewTDiagram.vue';
import ContourCut from '@/components/Forecast/ContourCut.vue';
import ForecastLineChart from '@/components/Forecast/ForecastLineChart.vue';

export default {
  name: 'ForecastDetail',
  components: {
    RoseGraph,
    StuveDiagram,
    SkewTDiagram,
    ContourCut,
    ForecastLineChart,
  },
  data() {
    return {
      graphData: null,
      graphTitle: '',
      componentName: 'RoseGraph',
      componentProperties: {}
    };
  },
  created() {
    let graphData = localStorage.getItem(this.$route.params.elementId);
    graphData = JSON.parse(graphData);
    this.graphData = graphData.data;
    this.graphTitle = graphData.title;
    this.getComponentName(this.$route.params.ghaphType);
  },
  methods: {
    getComponentName(graphType) {
      switch (graphType) {
        case 'roseGraph':
          this.componentName = 'RoseGraph';
          this.componentProperties = {
            roseData: this.graphData
          };
          break;
        case 'cutGraph':
          this.componentName = 'ContourCut';
          this.componentProperties = {
            contourData: this.graphData
          };
          break;
        case 'skwetGraph':
          this.componentName = 'SkewTDiagram';
          this.componentProperties = {
            skewtData: this.graphData
          };
          break;
        case 'stuveGraph':
          this.componentName = 'StuveDiagram';
          this.componentProperties = {
            stuveData: this.graphData
          };
          break;
        case 'powerGraph':
          this.componentName = 'ForecastLineChart';
          const speeds = this.graphData.speeds.map((x) => x.toFixed(2));
          const colorDict = {
            30: 'red',
            50: 'blue',
            80: 'green',
          };
          const windPowerData = [];
          Object.keys(this.graphData.probabilities).forEach((property) => {
            const probability = this.graphData.probabilities[property];
            const power = this.graphData.power[property];
            windPowerData.push({
              labels: speeds,
              data: probability.map((x) => x.toFixed(3)),
              fill: false,
              title: `Altura ${property} m (${power.toFixed(2)} Mwh)`,
              color: colorDict[property],
              deleteDatasets: false,
            });
          });
          this.componentProperties = {
            // roseData: this.graphData,
            windPowerData,
            graphLabels: speeds,
            variables: [],
            pointRadius: 0,
            xLabel: 'Velocidad del viento [m/s]',
            yLabel: 'Probabilidad',
            style: 'height:400px',
          };
          break;
        default:
          this.componentName = '';
          break;
      }
    }
  }
};
</script>
