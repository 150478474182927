<template lang="pug">
div
  l-geo-json(
    v-if="airportGeojson"
    :geojson="airportGeojson"
    ref="geoLayerAirport"
    @ready="geoAirportReady()"
    :options="{onEachFeature: jsonEFAirport, pointToLayer: jsonPntToAirport}")
  l-geo-json(
    v-if="navaidGeojson"
    :geojson="navaidGeojson"
    ref="geoLayerNavaid"
    @ready="geoNavaidReady()"
    :options="{onEachFeature: jsonEFNavaid, pointToLayer: jsonPntToNavaid}")
  transition(name="slide")
    l-control.control-container(
      class="series-dialog-wrapper bgLeafletControl"
      v-show="dialog"
      position="bottomleft")
      div(
        class="series-dialog-header")
        | Resultado corte
        v-btn(class="float-right pb-2" icon dark @click="closeDialog")
          v-icon mdi-close
      //- bar-loader(v-if="loadingGraph")
      error-view(
        v-show="loadError"
        heightVal=300
        :error-msg="errorMsg")
      div(class="box-chart-perfil")
          contour-cut(v-show="!loadError" class="chart-container-perfil")
  l-polyline(
    :lat-lngs="latlngs"
    :color="polyline.color"
    ref="cutPolyline"
    @ready="polylineReady")
    //- BOX EN MODO RESPONSIVE PARA DESKTOP
  div(v-if="selectorVisible")
    l-control.control-container.bgLeafletControl.hidden-md-and-down(
      :style="{width: showTip === true ? 'map-control-opened' : 'map-control-closed'}"
      position="topright"
    )
      .control-icon.py-1.px-1(
        v-if="!showTip",
        @click="toggleTip",
        style="cursor: pointer"
        title="Trazar la ruta"
      )
        v-icon(large) mdi-draw
      .control-tip.hidden-md-and-down(v-else class="py-4 px-4 mx-1" id="box-large")
        .text-h5.mb-1
          | Perfil Vertical
          v-btn(
            small
            icon
            variant="text"
            elevation="0"
            density="comfortable"
            class="text-center float-right pa-2 my-1"
            @click="cancelCut")
            v-icon(large) mdi-chevron-double-right
        v-divider
        .mt-2.mb-3 Seleccione los puntos de su ruta en el mapa
        v-divider(v-if="false")
        //.my-2(v-if="lastPnt")
          div(class="text-subtitle-1 text-subtitleLeafletControl font-weight-light") Último punto
          div {{lastPnt.lat}} | {{lastPnt.lng}}
        //v-divider(v-if="cutPoints >= 2")
        .my-2(id="perfil_vertical" v-if="cutPoints >= 2")
          //- div {{cutPoints}} punto(s) | {{cutDistance}}
          forecast-variables(ref="variablesControl")
        //v-btn(
          v-if="cutPoints >= 2"
          small
          color="red"
          class="ma-2 white--text"
          @click="deletePath") Borrar ruta
            v-icon(right dark) mdi-trash-can
        v-btn(
          v-if="dataLoaded"
          small
          color="blue"
          class="ma-2 white--text"
          @click="showForecast(chartData, 'cutGraph', 'Corte vertical')") Ventana emergente
            v-icon(right dark) mdi-dock-window
        div
          v-row
            v-col(cols="12" md="6")
              div(style="background-color: #7cb342;border-radius:100%;width:18px;height:18px;")
                span(class="subtitle-2 font-weight-bold mb-2 pa-5") Aeropuertos
            v-col(cols="12" md="6")
              div(style="background-color: #f44336;border-radius:100%;width:18px;height:18px;")
                span(class="subtitle-2 font-weight-bold mb-2 pa-5") Radioayudas
            v-col(cols="12" md="6" class="ma-0 pl-4 pt-2 pb-6")
              v-btn(
                v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
                rounded
                class="text-none text-subtitle-1 premium-button-lg"
                variant="elevated"
                color="white"
                :title="premiumTitle"
                @click="goPayment"
                outlined)
                v-icon mdi-medal
                <b>Plan Premium</b>
        v-divider
        span.errorLefletControl--text(v-if="errorMsg" class="mt-2 control-error") {{errorMsg}}
        .mt-2.d-flex.control-internal-content-vertical
          v-row
            v-col(cols="12" md="6")
              //v-btn(
                x-small
                color="errorLefletControl"
                class="ma-2 white--text"
                @click="cancelCut") Cerrar
                  v-icon(right dark small) mdi-close-circle
              v-btn(
                v-if="cutPoints >= 2"
                :loading="disabledButton"
                :disabled="disabledButton"
                block
                small
                color="#00B0FF"
                class="text-subtitle-2"
                @click="finishCut") Graficar
                v-icon(right dark small) mdi-chart-areaspline
            v-col(cols="12" md="6")
              v-btn(
                v-if="cutPoints >= 2"
                block
                small
                color="#FF9100"
                class="text-subtitle-2"
                @click="deletePath") Borrar ruta
                v-icon(right dark) mdi-trash-can
    //- BOX EN MODO RESPONSIVE PARA MOVILES
  div(v-if="selectorVisible")
    l-control.control-container.bgLeafletControl.hidden-lg-and-up(
      :style="{width: showTip === true ? 'map-control-opened' : 'map-control-closed'}"
      position="bottomright")
      .control-icon.py-1.px-1(
        v-if="!showTip",
        @click="toggleTip",
        style="cursor: pointer"
        title="Trazar la ruta"
      )
        v-icon(large) mdi-draw
      .control-tip.scrollable.hidden-lg-and-up(v-else class="py-4 px-4 mx-1" id="box-small")
        .text-h6.mb-1
          | Perfil Vertical
          v-btn(
            icon
            small
            variant="text"
            elevation="0"
            density="comfortable"
            class="text-center float-right"
            @click="cancelCut")
            v-icon(large) mdi-chevron-double-down mdi-24px
        v-divider
        .mt-2.mb-3 Seleccione los puntos de su ruta en el mapa
        v-divider(v-if="false")
        //.my-2(v-if="lastPnt")
          div(class="text-subtitle-1 text-subtitleLeafletControl font-weight-light") Último punto
          div {{lastPnt.lat}} | {{lastPnt.lng}}
        //v-divider(v-if="cutPoints >= 2")
        .my-2(v-if="cutPoints >= 2")
          //- div {{cutPoints}} punto(s) | {{cutDistance}}
          forecast-variables(ref="variablesControl")
        //v-btn(
          v-if="cutPoints >= 2"
          small
          color="red"
          class="ma-2 white--text"
          @click="deletePath") Borrar ruta
            v-icon(right dark) mdi-trash-can
        v-btn(
          v-if="dataLoaded"
          small
          color="blue"
          class="mb-2 mt-0 white--text boton_emergente"
          @click="showForecast(chartData, 'cutGraph', 'Corte vertical')") Ventana emergente
          //v-icon(right dark) mdi-dock-window
        div
          v-row
            v-col(cols="12" sm="12")
              div(style="background-color: #7cb342;border-radius:100%;width:18px;height:18px;")
                span(class="caption font-weight-bold mb-2 px-5") Aeropuertos
            v-col.pt-0(cols="12" sm="12")
              div(style="background-color: #f44336;border-radius:100%;width:18px;height:18px;")
                span(class="caption font-weight-bold mb-2 px-5 pt-0") Radioayudas
            v-col(cols="12" sm="6" class="ma-0 pl-4 pt-2 pb-6")
              v-btn(
                v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
                rounded
                class="text-none text-subtitle-1 premium-button-sm"
                variant="elevated"
                color="white"
                :title="premiumTitle"
                @click="goPayment"
                outlined)
                v-icon mdi-medal
                <b>Plan Premium</b>
        v-divider
        span.errorLefletControl--text(v-if="errorMsg" class="mt-2 control-error") {{errorMsg}}
        .mt-2.d-flex.control-internal-content(style="zoom:0.9")
          v-row
            v-col(cols="12" md="12" sm="12")
              v-btn(
                v-if="cutPoints >= 2"
                :loading="disabledButton"
                :disabled="disabledButton"
                block
                small
                color="#00B0FF"
                class="text-body font-weight-bold boton_control"
                @click="finishCut") Graficar
                v-icon(right dark small) mdi-chart-areaspline
            v-col.pt-0(cols="12" md="12" sm="12")
              v-btn(
                v-if="cutPoints >= 2"
                block
                small
                color="#FF9100"
                class="text-body font-weight-bold boton_control"
                @click="deletePath") Borrar
                v-icon(right dark) mdi-trash-can
</template>

<script>
/* eslint-disable global-require */
import { mapState } from 'vuex';
import L from 'leaflet';
import {
  LPolyline,
  LGeoJson,
  LControl,
} from 'vue2-leaflet';
import ContourCut from '@/components/Forecast/ContourCut';
import lfControlMixin from '@/mixins/lfControlMixin.js';
import ErrorView from '@/components/ui/ErrorView.vue';
import ForecastVariables from '@/components/Forecast/ForecastVariables.vue';
import { serverApi, premiumTitleText } from '@/config.js';
import { getToken } from '@/utils.js';

export default {
  name: 'CutsControl',
  components: {
    LControl,
    LPolyline,
    LGeoJson,
    ContourCut,
    ErrorView,
    ForecastVariables,
  },
  mixins: [lfControlMixin],
  data() {
    return {
      dialog: false,
      errorMsg: '',
      lastPnt: null,
      latlngs: [],
      polyline: {
        latlngs: [],
        color: '#64B5F6',
      },
      cutPolyline: null,
      coords: [],
      data: [],
      loadError: false,
      requestCoordinates: [],
      airportGeojson: null,
      navaidGeojson: null,
      geoLayerAirport: null,
      disabledButton: false,
      chartData: null,
      dataLoaded: false,
      showTip: true,
      selectorVisible: true,
    };
  },
  computed: {
    ...mapState('gis', ['initialMapZoom', 'baseLayers', 'mapLat', 'mapLon']),
    ...mapState('ui/leaflet', ['selectedPeriod', 'selectedVariable', 'cuts']),
    premiumTitle() {
      return premiumTitleText;
    },
    cutPoints() {
      return this.latlngs.length;
    },
    cutDistance() {
      let distance = 0;
      let distanceStr = '';
      if (this.latlngs.length >= 2) {
        for (let i = 1; i < this.cutPolyline._latlngs.length; i++) {
          distance += this.cutPolyline._latlngs[i].distanceTo(this.cutPolyline._latlngs[i - 1]);
        }
      }
      if (distance >= 10000) {
        distance /= 1000;
        distanceStr = `${distance.toFixed(2)} Kilometros`;
      } else {
        distanceStr = `${distance.toFixed(2)} metros`;
      }
      return distanceStr;
    }
  },
  async mounted() {
    const aeropuertos = this.cuts.airports;
    const radioayudas = this.cuts.navaids;
    // Conseguir el token si existe para autenticar la petición
    const token = await getToken();
    const headers = {};
    if (token !== '') {
      headers.Authorization = `Bearer ${token}`;
    }
    // cargar los datos de los aeropuertos
    let response = await fetch(`${serverApi}layers/${aeropuertos}`, {
      headers
    });
    if (response.status === 200) {
      const jsonResp = await response.json();
      this.airportGeojson = jsonResp.points;
    } else {
      this.errorMsg = 'Error al cargar los puntos de los aeropuertos';
    }

    // cargar los datos de las radioayudas
    response = await fetch(`${serverApi}layers/${radioayudas}`, {
      headers
    });
    if (response.status === 200) {
      const jsonResp = await response.json();
      this.navaidGeojson = jsonResp.points;
    } else {
      this.errorMsg = 'Error al cargar los puntos de las radioayudas';
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.selectorVisible = true;
    },
    navaidPopup(feat) {
      const props = feat.properties;
      /* let appendedData = '';
      for (let index = 0; index < props.data.length; index++) {
        const element = props.data[index];
        appendedData += `<div><b>Tipo:</b> ${element.type} <b>Frecuencia:</b> ${element.frequency}</div>`;
      } */
      return `<div class="meteo-popup-container">
        <div class="meteo-popup-header">
          <div class="meteo-popup-title">${props.name}</div>
        </div>
        <div class="meteo-popup-divider"></div>
        <div>
          <div class="meteo-popup-list-item"><b>Tipo:</b> ${props.further_data.Tipo} <b>Frecuencia:</b> ${props.further_data.Frecuencia}</div>
        </div>
      </div>
      `;
    },
    airportPopup(feat) {
      const props = feat.properties.further_data;
      let appendedData = '';
      delete props.Nombre;
      Object.keys(props).forEach((property) => {
        if (property !== 'Pista' && property !== 'Longitud') {
          appendedData += `<div class="meteo-popup-list-item"><b>${property}:</b> ${props[property] || 'no-data'}</div>`;
        }
      });
      return `<div class="meteo-popup-container">
        <div class="meteo-popup-header">
          <div class="meteo-popup-title">${feat.properties.name || ''}</div>
          <div meteo-popup-subtitle><i class="fas fa-road"></i> Pista: <b>${props.Pista || 'no data'}</b>, longitud: <b>${props.Longitud || 'no-data'}</b></div>
        </div>
        <div class="meteo-popup-divider"></div>
        <div>
          ${appendedData}
        </div>
      </div>
      `;
    },
    jsonEFNavaid(ft, ly) {
      ly.bindPopup(this.navaidPopup(ft), { closeButton: false, offset: L.point(0, 0) });
      ly.on('mouseover', () => { ly.openPopup(); });
      ly.on('mouseout', () => { ly.closePopup(); });
      ly.on('click', (e) => {
        this.addPointToPath(e);
      });
    },
    jsonEFAirport(ft, ly) {
      ly.bindPopup(this.airportPopup(ft), { closeButton: false, offset: L.point(0, 0) });
      ly.on('mouseover', () => { ly.openPopup(); });
      ly.on('mouseout', () => { ly.closePopup(); });
      ly.on('click', (e) => {
        this.addPointToPath(e);
      });
    },
    deletePath() {
      this.latlngs = [];
      this.lastPnt = null;
      this.requestCoordinates = [];
      this.dataLoaded = false;
    },
    jsonPntToAirport(feature, latlng) {
      const texto = feature.properties.name;
      const markerIcon = L.divIcon({
        iconSize: [15, 15], // size of the icon
        iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
        popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
        html: `<div style="background-color: #7cb342;border-radius: 100%; width: 15px; height: 15px;"></div><div class="custom-marker" style="font-size: 10px; text-align: center; margin-top: 2px; color: white;">${texto}</div>`
      });
      const marker = L.marker(latlng, {
        icon: markerIcon
      });
      return marker;
    },
    jsonPntToNavaid(feature, latlng) {
      const texto = feature.properties.name;
      const markerIcon = L.divIcon({
        iconSize: [15, 15], // size of the icon
        iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
        popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
        html: `<div style="background-color: #f44336; border-radius: 100%; width: 15px; height: 15px;"></div><div class="custom-marker" style="font-size: 10px; text-align: center; margin-top: 2px; color: white;">${texto}</div>`
      });

      const marker = L.marker(latlng, {
        icon: markerIcon
      });
      return marker;
    },
    geoAirportReady() {
      this.geoLayerAirport = this.$refs.geoLayerAirport.mapObject;
      this.$parent.$parent.layerControl.addOverlay(this.geoLayerAirport, 'Aeropuertos');
    },
    geoNavaidReady() {
      this.geoLayerNavaid = this.$refs.geoLayerNavaid.mapObject;
      this.$parent.$parent.layerControl.addOverlay(this.geoLayerNavaid, 'Radio-ayudas');
    },
    async loadCut() {
      // dehabilitar boton mientras los datos cargan
      this.disabledButton = true;
      let querys = `?variable=${this.selectedVariable.variable}&date=${this.selectedPeriod}`;
      querys += `&coords=${JSON.stringify(this.requestCoordinates)}`;
      // traer los valores de las variables extra si existen
      const extraParams = this.$refs.variablesControl.urlQueryParams();
      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      // Obtener la aplicación actual desde la URL
      const { app } = this.$route.params;
      const response = await fetch(`${serverApi}fronts/${app}${querys}${extraParams}`, {
        headers
      });
      if (response.status === 200) {
        const jsonResp = await response.json();
        this.coords = jsonResp.coords;
        this.data = jsonResp.data;
        this.dialog = true;
        this.dataLoaded = true;

        this.chartData = {
          data: jsonResp.data,
          coords: jsonResp.coords,
          title: `${this.selectedVariable.name} en ${this.selectedVariable.unit}`,
        };

        this.$bus.$emit('contour-data-loader', this.chartData);
        this.dialog = true;
        this.selectorVisible = false;
      } else {
        const data = await response.json();
        this.loadError = true;
        this.dialog = true;
        this.selectorVisible = false;
        this.dataLoaded = true;
        this.errorMsg = `${response.status} - ${data || response.statusText}`;
      }
      // habilitar el botón una vez la petición termine
      this.disabledButton = false;
    },
    cancelCut() {
      this.showTip = !this.showTip;
      /* this.latlngs = [];
      this.lastPnt = null; */
      this.errorMsg = '';
    },
    finishCut() {
      if (this.cutPolyline._latlngs.length < 2) {
        this.errorMsg = 'La trayectoria debe tener por lo menos dos puntos';
        return;
      }

      if (this.selectedVariable.variable === undefined) {
        this.errorMsg = 'Debe seleccionar una variable';
        return;
      }

      if (!this.selectedPeriod) {
        this.errorMsg = 'Debe seleccionar una hora';
        return;
      }

      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores';
        return;
      }

      // obtener los datos de las coordenadas de la ruta en el formato necesario
      const lineCoords = this.cutPolyline.toGeoJSON().geometry.coordinates;
      // Limpia el array de coordenadas para que no se dupliquen
      this.requestCoordinates = [];
      for (let i = 0; i < lineCoords.length; i++) {
        const pnt = lineCoords[i];
        this.requestCoordinates.push(...[pnt[1], pnt[0]]);
      }
      // this.cancelCut();

      this.loadCut();
    },
    polylineReady() {
      this.cutPolyline = this.$refs.cutPolyline.mapObject;
    },
    addPointToPath(e) {
      if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.lastPnt = {
          lat,
          lng,
        };
        // verificar que el punto no esta en la lista
        // si esta lo elimina
        const pIndex = this.latlngs.map((x, i) => x[0] === lat && x[1] === lng ? i : -1)
          .filter((x) => x > -1);
        if (pIndex.length > 0) {
          this.latlngs.splice(pIndex[0], 1);
        } else {
          this.latlngs.push([lat, lng]);
        }
      }
    },
    mapClick() {
      /* if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.lastPnt = {
          lat,
          lng,
        };
        this.latlngs.push([lat, lng]);
      } */
    },
  },
};
</script>

<style scoped>
.boton_emergente {
  margin-left: 0px !important;
  width: 160px !important;
}
.boton_control {
  width: 192px !important;
}
</style>
