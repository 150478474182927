<template lang="pug">
div(class="home bgNotFound" style="height:100%")
  cloud-rain(message="Página no encontrada")
  v-row(class="button-home-wrapper mb-8")
    v-col
      v-btn(color="blue" @click="$router.push('/')") regresar al inicio
</template>

<script>
// @ is an alias to /src
import CloudRain from '@/components/ui/CloudRain.vue';

export default {
  name: 'NotFound',
  components: {
    CloudRain
  }
};
</script>

<style scoped>
.button-home-wrapper {
  bottom: 0px;
  position: absolute;
  margin-left: calc(50vw - 105px);
  margin-bottom: 6%;
}
</style>
