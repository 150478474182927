import { apiClient } from './config.js';
import { getToken } from '../utils.js';

export default {
  async getRates() {
    const token = await getToken();
    if (token !== '') {
      apiClient.interceptors.request.use((config) => {
        config.headers = { ...{ Authorization: `Bearer ${token}` }, ...config.headers };
        return config;
      });
    }
    return apiClient.get('rates');
  },
  async savePayment(refCode, items) {
    const token = await getToken();
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ Authorization: `Bearer ${token}` }, ...config.headers };
      return config;
    });
    return apiClient.post(
      'payments',
      {
        ref_code: refCode,
        items
      }
    );
  },
  async validatePayment(refCode) {
    const token = await getToken();
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ Authorization: `Bearer ${token}` }, ...config.headers };
      return config;
    });
    return apiClient.post(`payments/${refCode}/validate_payment`);
  },
  async getCredits() {
    const token = await getToken();
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ Authorization: `Bearer ${token}` }, ...config.headers };
      return config;
    });
    return apiClient.get('credits/get_credits');
  },
  async getUserInfo() {
    const token = await getToken();
    const userName = localStorage.getItem('userId');
    apiClient.interceptors.request.use((config) => {
      config.headers = { ...{ Authorization: `Bearer ${token}` }, ...config.headers };
      return config;
    });
    return apiClient.get(`users/${userName}`);
  },
  async getCities(query) {
    return apiClient.get(`cities?search=${query}`);
  },
  async getSiigoIdentifications() {
    return apiClient.get('siigo_identifications');
  },
};
