<template lang="pug">
v-container(class="text-center")
  subscription-form(v-if="serviceValid")
  v-row(v-else class="justify-center")
    v-col(cols="4")
      v-card(
        width="400"
        color="red lighten-1"
        dark)
        v-card-title(class="text-h5 mb-1") El pago no esta en el sistema

        v-card-subtitle(class="text-left")
          | Comuníquese con el administrador del sitio para obtener el enlace de pago correcto.

        //- v-card-actions
        v-btn(class="mb-3" color="blue" @click="$router.push('/')") Ver portal

  v-container(class="d-flex flex-column" fill-height fluid v-if="serviceProducts.length > 0")
    v-expansion-panels(class="mr-0" style="width:400px" )
      v-expansion-panel
        v-expansion-panel-header(class="white--text" color="teal") VER PRODUCTOS ADQUIRIDOS
        v-expansion-panel-content
          v-card(
            class="mx-auto"
            max-width="400"
            tile)
            v-subheader(class="text-h6" inset) Servicios adquiridos ({{ serviceProducts.length }})
            v-list-item(
              three-line
              v-for="product in serviceProducts"
              :key="product.name")
              v-list-item-content
                v-list-item-title {{ product.name }}
                v-list-item-subtitle {{ convertCurrency(product.price) }}
                div(class="text-caption" v-html="product.desc")
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import SubscriptionForm from '@/components/forms/SubscriptionForm.vue';
import { currencyFormat } from '../utils';
import { serviceConfirmationUrl } from '../config.js';

export default {
  name: 'PaymentService',
  components: {
    SubscriptionForm
  },
  data() {
    return {
      isMounted: false,
    };
  },
  async mounted() {
    await this.getPaymentService(this.$route.params.paymentCode);
    // cambiar a la URL de confirmación de los servicios
    this.SET_VALUE({
      name: 'confirmationUrl',
      value: serviceConfirmationUrl
    });
    this.isMounted = true;
  },
  computed: {
    ...mapState('subscription', ['serviceAmount', 'serviceProducts', 'serviceValid'])
  },
  methods: {
    ...mapActions('meteocolombia', ['getPaymentService']),
    ...mapMutations('subscription', ['SET_VALUE']),
    convertCurrency(value) {
      return currencyFormat(value);
    },
  },
};
</script>

<style scoped>
.button-home-wrapper {
  bottom: 0px;
  position: absolute;
  margin-left: calc(50vw - 105px);
  margin-bottom: 6%;
}
</style>
