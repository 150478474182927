<template lang="pug">
div(class="loading-graph text-center")
  div
  div
  div
  span(class="font-weight-bold") Cargando...
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style scoped>
.loading-graph {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: calc(50% - 40px);
  margin-top: calc(13% - 40px);
}
.loading-graph div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #0771db82;
  animation: loading-graph 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  border-radius: 5px;
}

.loading-graph span{
    position: absolute;
    top: 65px;
    left: 0px;
    font-size: medium;
    font-weight: 600;
    color: rgba(0,0,0,0.65);
}
.loading-graph div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading-graph div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading-graph div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading-graph {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
</style>
