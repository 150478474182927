<template lang="pug">
div(class="flat-page-container bgLeafletControl")
  static-content
  div(class="home flat-page-content mx-auto mt-6" style="height:100%" v-html="htmlData")
</template>

<script>
import { mapMutations } from 'vuex';
import UiService from '@/services/UiService';
import StaticContent from '@/components/ui/StaticContent.vue';
import ContactUsForm from '@/components/forms/ContactUsForm.vue';
import FaqForm from '@/components/forms/FaqForm.vue';
import AirModelForm from '@/components/forms/AirModelForm.vue';
import LoginForm from '@/components/forms/LoginForm.vue';
import RegisterForm from '@/components/forms/RegisterForm.vue';
import SubscriptionForm from '@/components/forms/SubscriptionForm.vue';

export default {
  name: 'FlatPage',
  components: {
    StaticContent,
    AirModelForm,
    ContactUsForm,
    FaqForm,
    LoginForm,
    RegisterForm,
    SubscriptionForm,
  },
  methods: {
    ...mapMutations('ui', ['SHOW_ALERT']),
  },
  data() {
    return {
      htmlData: '',
      currentForm: 'ContactUsForm'
    };
  },
  mounted() {
    UiService.getFlatPage(this.$route.params.page)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          this.htmlData = resp.data.content;
          if ('form' in resp.data) {
            this.currentForm = resp.data.form;
          }
        } else {
          console.log('entro else resp');
          this.SHOW_ALERT({
            status: 'error',
            message: 'Error al cargar el contenido de la página'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.SHOW_ALERT({
          status: 'error',
          message: 'Error al cargar el contenido de la página'
        });
      });
  },
};
</script>
