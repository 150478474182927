<template lang="pug">
  div(class="captcha-container mx-auto")
    div(class="captcha-header py-2 d-flex px-2")
      span(class="text-body-1") Dibuje para verificar
      v-spacer
      v-icon(v-if="!captchaValidated" class="clickable" @click="refresh") mdi-refresh
    canvas(id="mc-canvas" width="200" height="154" class="circle mc-invalid")
      | Su navegador no admite el elemento canvas -
      |  se recomienda usar navegadores en sus versiones más recientes
    v-alert(
      class="mx-2 text-body-2 text-left"
      dense
      border="left"
      :type="messageType") {{message}}
    span(
      v-if="showError"
      class="text-caption px-1 red--text") resuelva el captcha antes de enviar el formulario
    </v-alert>
</template>

<script>
/* eslint-disable no-undef */
export default {
  model: {
    prop: 'captchaValidated',
    event: 'change'
  },
  props: {
    formId: {
      required: true,
      type: String
    },
    captchaValidated: {
      type: Boolean,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      message: 'Dibuje la forma',
      messageType: 'info',
      errorMsg: 'Por favor intente de nuevo',
      successMsg: '¡Captcha superado!',
      shapes: [
        'triangle',
        'x',
        'rectangle',
        'circle',
        'check',
        'caret',
        'zigzag',
        'arrow',
        'leftbracket',
        'rightbracket',
        'v',
        'delete',
        'star',
        'pigtail',
      ]
    };
  },
  mounted() {
    // suscribir el evento de validación del captcha
    document.addEventListener('captcha-validation', this.validationListener);
    this.$jqCaptcha(`#${this.formId}`).motionCaptcha({
      shapes: this.shapes,
      // This message is displayed if the user's browser doesn't support canvas:
      noCanvasMsg: 'Su navegador no soporta <canvas> - trate con Chrome, FF4, Safari or IE9.',
      // This could be any HTML string (eg. '<label>Draw this shit yo:</label>'):
      label: '<p>Dibuje la forma en la caja antes de enviar el formulario:</p>'
    });
  },
  beforeDestroy() {
    // Desuscribir el evento de validación del captcha
    document.removeEventListener('captcha-validation', null);
  },
  methods: {
    validationListener(e) {
      if (e.detail.type === 'fail') {
        this.message = this.errorMsg;
        this.messageType = 'error';
        this.$emit('change', false);
      } else {
        this.message = this.successMsg;
        this.messageType = 'success';
        this.captchaValidated = true;
        this.$emit('change', true);
      }
    },
    refresh() {
      const el = document.getElementById('mc-canvas');
      // limpiar el contenido del contenedor
      const context = el.getContext('2d');
      context.clearRect(0, 0, el.width, el.height);
      const classes = el.classList;
      for (let index = 0; index < classes.length; index++) {
        const element = classes[index];
        // si la clase esta en las del elemento eleiminarlas
        if (classes.contains(element)) {
          el.classList.remove(element);
        }
      }

      // agregar la nueva forma aleatoria
      el.classList.add(this.shapes[Math.floor(Math.random() * (this.shapes.length))]);
      // dejar valores iniciales
      this.message = 'Dibuje la forma';
      this.messageType = 'info';
    }
  },
};
</script>

<style scoped>
.captcha-container {
  width: 200px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgb(0 0 0 / 13%);
  padding-bottom: 1px;
  margin-bottom: 10px;
}
.captcha-header {
  background-color: rgba(0, 0, 0, 0.03);
}
#mc-canvas {
  border: solid #EEEEEE 2px;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
