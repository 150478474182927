export default {
  data() {
    return {
      mixinMarker: null,
    };
  },
  methods: {
    markerMixinReady() {
      this.mixinMarker = this.$refs.mixinMarker.mapObject;
    },
  }
};
