<template lang="pug">
  v-menu
    template(v-slot:activator="{ on, attrs }")
      v-btn(
        title="Seleccione una rampa de color para visualizar mejor la variable"
        style="width:100%"
        v-bind="attrs"
        v-on="on")
        color-ramp(style="display:block; width:100%" :ramp-name="selectedRamp" :show-name="false")
      span(class="pl-2")
        | Seleccione rampa {{selectedRamp ? `(${selectedRamp})` : ''}}
    div(:class="['ramps-container', 'box-scroll']")
      color-ramp(
        :class="['color-ramp-item', ramp === selectedRamp ? 'ramp-selected' : '']"
        v-for="ramp in ramps"
        @click.native="rampSelected(ramp)"
        :ramp-name="ramp")
</template>

<script>
import ColorRamp from '@/components/ui/ColorRamp.vue';

export default {
  name: 'ColorRampPicker',
  components: {
    ColorRamp
  },
  model: {
    prop: 'selectedRamp',
    event: 'change'
  },
  props: {
    selectedRamp: {
      type: String,
      default: 'OrRd'
    }
  },
  data() {
    return {
      ramps: [
        'Blues',
        'BrBG',
        'BuGn',
        'BuPu',
        'GnBu',
        'Greens',
        'Greys',
        'OrRd',
        'Oranges',
        'PRGn',
        'PiYG',
        'PuBu',
        'PuBuGn',
        'PuOr',
        'PuRd',
        'Purples',
        'RdBu',
        'RdGy',
        'RdPu',
        'RdYlBu',
        'RdYlGn',
        'Reds',
        'Spectral',
        'Viridis',
        'YlGn',
        'YlGnBu',
        'YlOrBr',
        'YlOrRd',
      ],
    };
  },
  methods: {
    rampSelected(rampName) {
      this.$emit('change', rampName);
    }
  }
};
</script>

<style scoped>
.ramps-container {
  position: relative;
  height: 200px;
  background-color: white;
  width: 100%;
}

.ramp-selected {
  background: antiquewhite;
  font-weight: 500;
  color: darkolivegreen;
}

.color-ramp-item {
  cursor: pointer;
}
.color-ramp-item:hover {
  background: rgba(187, 222, 251, 0.8);
}
.v-menu__content {
  background: white;
}

</style>
