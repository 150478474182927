<template lang="pug">
div(class="mt-8 text-center")
  v-card(class="mx-auto my-auto meteo-form text-center" max-width="400" outlined)
    v-card-subtitle(class="text-caption"
      ) Aquí puede cambiar su contraseña
    v-form(ref="form" v-model="valid" lazy-validation :id="formId")
      h5(class="text-normal text-center"
      ) Para cambiar su contraseña, ingresa los siguientes datos:
      v-text-field.mt-6(
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required,]"
        :type="show ? 'text' : 'password'"
        label="Ingrese su contraseña actual"
        hint="Al menos 8 caracteres"
        v-model="password"
        class="input-group--focused"
        @click:append="show = !show"
      )
      v-text-field(
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.minLength(8)]"
        :type="show1 ? 'text' : 'password'"
        label="Ingrese su contraseña nueva"
        hint="Al menos 8 caracteres"
        v-model="newPassword"
        class="input-group--focused"
        @click:append="show1 = !show1"
      )
      v-text-field(
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.minLength(8)]"
        :type="show2 ? 'text' : 'password'"
        label="Repita su contraseña nueva"
        hint="Al menos 8 caracteres"
        v-model="newPasswordRepeat"
        class="input-group--focused"
        @click:append="show2 = !show2"
      )
      motion-captcha.mt-8(
        :form-id="formId"
        v-model="validatedCaptcha"
        :show-error="captchaError"
        class="cuadro_capcha"
      )
      v-row
        v-col(cols="12" class="pt-7")
          v-btn(
            :disabled="!valid"
            color="success"
            @click="submit"
          ) Enviar
      v-alert.mt-5(
        v-if="showAlert"
        :type="alertType"
        border="top"
        color="red lighten-2"
        dark) {{alertMessage}}
</template>

<script>
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';
import { serverApi } from '@/config';

export default {
  name: 'ChangePassword',
  components: {
    MotionCaptcha,
  },
  mixins: [formMixin],
  data: () => ({
    formId: 'change-password-form',
    username: '',
    password: '',
    newPassword: '',
    newPasswordRepeat: '',
    show: false,
    show1: false,
    show2: false,
    valid: false,
    rules: {
      required: (v) => !!v || 'Este campo es requerido',
      minLength: (min) => (v) => v.length >= min || `Debe tener al menos ${min} caracteres`,
    },
    alertType: 'error',
    alertMessage: '',
    showAlert: false,
  }),
  computed: {
    passwordsMatch() {
      return this.newPassword === this.newPasswordRepeat;
    },
  },
  mounted() {
    this.showAlert = false;
    this.alertMessage = '';
    this.username = this.$store.state.subscription.userInfo.username;
  },
  methods: {
    submit() {
      if (!this.passwordsMatch) {
        this.alertMessage = 'Las contraseñas no coinciden.';
        this.showAlert = true;
        return;
      }

      const url = `${serverApi}change-password`;
      const data = {
        username: this.username,
        password: this.password,
        newPassword: this.newPassword,
      };
      // Usar fetch para enviar la solicitud al servidor
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) =>
          response.json().then((data) => ({
            status: response.status,
            data,
          })))
        .then(({ status, data }) => {
          if (status !== 200) {
            this.alertType = 'error';
          } else {
            this.alertType = 'success';
          }
          this.alertMessage = data.message;
          this.showAlert = true;
          this.password = '';
          this.newPassword = '';
          this.newPasswordRepeat = '';
        })
        .catch((error) => {
          this.alertMessage = error.message || error;
          this.alertType = 'error';
          this.showAlert = true;
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        });
    },
  },
};
</script>
