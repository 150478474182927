<template lang="pug">
  v-container
    view-banner(
      :title="title"
      :summary="summary"
    )

    v-container(class="mt-8" style="width:100%")
      v-row
        v-col(cols="6")
          v-card(class="mx-auto" max-width="344")
            v-img(
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px")

            v-card-title {{model.title1}}

            v-card-subtitle {{model.subtitle1}}

            v-card-actions
              v-btn(color="orange lighten-2" text) Ver más

              v-spacer

              v-btn(icon @click="show1 = !show1")
                v-icon {{ show1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}

            v-expand-transition
              div(v-show="show1")
                v-divider
                v-card-text {{model.content1}}

        v-col(cols="6")
          v-card(class="mx-auto" max-width="344")
            v-img(
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px")

            v-card-title {{model.title2}}

            v-card-subtitle {{model.subtitle2}}

            v-card-actions
              v-btn(color="orange lighten-2" text) Ver más

              v-spacer

              v-btn(icon @click="show2 = !show2")
                v-icon {{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}

            v-expand-transition
              div(v-show="show2")
                v-divider
                v-card-text {{model.content2}}

    h2(class="font-weight-light mt-8") Datos Ajustados al Territorio Colombiano
    span(class="text-caption")
      | Los datos atmosféricos que presta Meteocolombia S.A.S. a sus clientes se crearon
      | con el modelo regional de simulación atmosférica WRF versión 3.9.1 lanzada en agosto
      | de 2017. El modelo WRF es el sucesor del modelo MM5 y es altamente adaptable a
      | diferentes zonas geográficas y aplicaciones debido a que cuenta con más de 280
      | parámetros de configuración. La experiencia del equipo de Meteocolombia S.A.S en el
      | manejo y ajuste de estos modelos a las particularidades del territorio colombiano
      | se basa en más de 15 años de experiencia en simulación atmosférica dando lugar a la
      | constante optimización de los resultados. La versión que aplica Meteocolombia S.A.S.
      | para la generación de datos meteorológicos es una adaptación del modelo WRF a las
      | condiciones y necesidades del territorio colombiano. Algunas de las especificaciones son:

    v-container(class="mt-8" style="width:70%")
      v-row
        v-col(class="text-center indigo lighten-2 white--text" cols="6")
          div Resolución
          v-icon(class="white--text font-weght-light" style="font-size:65px") mdi-grid
        v-col(class="text-center my-auto" cols="6")
          | Resolución espacial de 6km (distancia horizontal entre puntos de grilla)
          | para todo el territorio nacional incluido San Andrés y Providencia.

        v-col(class="text-center my-auto" cols="6")
          | 28 niveles desde superficie hasta 50mb con una densidad máxima en superficie
        v-col(class="text-center indigo lighten-1 white--text" cols="6")
          div Niveles
          v-icon(class="white--text font-weght-light" style="font-size:65px")
            | mdi-layers-triple-outline

        v-col(class="text-center indigo lighten-2 white--text" cols="6")
          div Nubes
          v-icon(class="white--text font-weght-light" style="font-size:65px") mdi-cloud-outline
        v-col(class="text-center my-auto" cols="6")
          | Simulación explícita de las nubes con 5 tipos de hidrometeoros
          | (vapor, agua líquida, nieve, hielo y graupel)

        v-col(class="text-center my-auto" cols="6")
          | Simulación de la radiación de onda corta y larga separada por once
          | y dieciseis bandas respectivamente
        v-col(class="text-center indigo lighten-1 white--text" cols="6")
          div Simulación onda
          v-icon(class="white--text font-weght-light" style="font-size:65px")
            | mdi-sine-wave

        v-col(class="text-center indigo lighten-2 white--text" cols="6")
          div Capa limite
          v-icon(class="white--text font-weght-light" style="font-size:65px") mdi-layers-outline
        v-col(class="text-center my-auto" cols="6")
          | Capa límite según teoría de similitud

        v-col(class="text-center my-auto" cols="6")
          | Cálculo del balance hídrico en superficie, suelo y canopy
        v-col(class="text-center indigo lighten-1 white--text" cols="6")
          div Balance hídrico
          v-icon(class="white--text font-weght-light" style="font-size:65px")
            | mdi-water-outline

        v-col(class="text-center indigo lighten-2 white--text" cols="6")
          div Elevación
          v-icon(class="white--text font-weght-light" style="font-size:65px") mdi-terrain
        v-col(class="text-center my-auto" cols="6")
          | Modelo digital de elevación elaborado por el IGAC

        v-col(class="text-center my-auto" cols="6")
          | Datos de uso de suelo del sensor MODIS a una resolución de 500 metros
          | con fecha de actualización del 2012
        v-col(class="text-center indigo lighten-1 white--text" cols="6")
          div Datos suelo
          v-icon(class="white--text font-weght-light" style="font-size:65px")
            | mdi-satellite-variant

    air-model-form
</template>

<script>
import ViewBanner from '@/components/ViewBanner.vue';
import AirModelForm from '@/components/forms/AirModelForm';

export default {
  name: 'ModelInfo',
  components: {
    ViewBanner,
    AirModelForm,
  },
  data() {
    return {
      show1: false,
      show2: false,
      model: null,
      models: {
        aermod: {
          title1: 'AERMET-DAT',
          subtitle1: 'Datos meteorológicos de entrada a AERMET',
          content1: `En formato SAMSON (.SAM) y TD6201 (.UA). Con cada conjunto de datos solicitado
            se entregará un reporte de prueba de datos en el procesador AERMET y un resumen de las 
            principales variables entregadas con el fin de asegurar a usted la calidad de los datos.`,
          title2: 'AERMOD-PRO',
          subtitle2: 'Datos procesados listos para AERMOD',
          content2: `Meteocolombia le ofrece los datos meteorológicos en formato SFC y PLF listos
            para ser utilizados en el modelo AERMOD. Recibirá los datos metereológicos de superficie
            (.SFC) y datos metereológicos de altura (.PFL) además de los datos de uso de suelo para
            la determinación de los datos metereológicos.`,
        },
        calpuff: {
          title1: 'CALMET-DAT',
          subtitle1: 'Datos meteorológicos de entrada a CALMET',
          content1: `Con cada conjunto de datos solicitados se entregará un reporte de prueba de
            datos en el procesador CALMET y un resumen de las principales variables entregadas con el
            fin de asegurarle la calidad de los datos y su adecuado funcionamiento en cualquier
            versión de CALMET.`,
          title2: 'CALPUFF-PRO',
          subtitle2: 'Datos procesados listos para CALPUFF',
          content2: `Meteocolombia le ofrece los datos meteorológicos requeridos por CALPUFF en
            formatos MET.DAT y MET.AUX. Se entregará el archivo MET.INFO que le indicará que
            variables debe cambiar en el modelo para que los archivos sean funcionales, como
            número de capas verticales, tamaño de la grilla, etc. Se entregará además un reporte
            de prueba de los datos que garanticen el funcionamiento adecuado de los datos.`,
        }
      },
      title: 'Meteorología para el periodo 2011 a 2018',
      summary: `para todo el territorio nacional (continental y marítimo). Usted puede solicitar su cotización   aquí   indicando el nombre del sitio, coordenadas
        y periodo o diligenciando el formulario abajo. Entregamos Datos Meteorológicos de superficie y de altura generado por el modelo atmosférico de última generación WRF optimizado y validado para el territorio colombiano. Usted tiene a su disposición los datos en los formatos estándares TD6201,SAMSON, 3D.dat y CSV para los datos en superficie y altura. Ofrecemos datos con periodos desde 2 meses a ocho años con resolución temporal de una hora. La entrega de los datos se realiza dentro de las 48 horas hábiles siguientes a la hora de hacer efectiva la compra. Usted recibirá sus datos por correo y su factura le llega impresa por correo convencional.
        Nuevo Aproveche la Nueva Forma de Pago en línea por Nuevo. Con cada cotización que usted solicite recibirá el enlace de pago que le permite pagar por tarjeta de crédito, débito o en diversos puntos de pago.`,
    };
  },
  watch: {
    $route(to) {
      console.log(to);
      const modelName = to.params.model_name;
      this.model = this.models[modelName];
    }
  },
  created() {
    const modelName = this.$route.params.model_name;
    this.model = this.models[modelName];
  }
};
</script>
