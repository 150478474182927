export const serverProtocole = 'https';
// export const serverIp = 'meteocolombia.com.co:8080';
export const serverIp = process.env.VUE_APP_METEO_DOMAIN || 'meteocolombia.com.co:8080';
export const serverUrl = `${serverProtocole}://${serverIp}`;
export const serverApi = `${serverUrl}/api/`;
export const frontServerUrl = `${serverProtocole}://${serverIp}/`;
export const meteoCountry = process.env.VUE_APP_METEO_COUNTRY || 'COL';
export const tiffServerDomain = process.env.VUE_APP_TIFF_SERVER_DOMAIN || 'meteocolombia.com.co:8080';
export const tiffServerUrl = `${serverProtocole}://${tiffServerDomain}`;

// Settings for WebGis view
// base url route for WebGis view.
export const webGisRoute = 'webgis';

// Settings for Maps view
// Iniatial variable load in Maps
export const initialVariableLevel = 950;
// X wind speed component to load particles
export const xWindComponent = 'U10';
// Y wind speed component to load particles
export const yWindComponent = 'V10';
// chroma color ramp initial value to map
export const initialColorRamp = 'Viridis';
// chroma color ramp to particles animation
export const particlesColorRamp = 'Blues';

// Settings for Leaflet view
// icon to display in web gis, located in assets folder
export const locationIcon = 'circle_red.png';

// Settings for WindPower view
// path to KDE plot images in server
export const baseWindPowerPath = '/home/web/source/meteo-modules/src/wind_power/';
// Wind power ascii file path
export const windPowerAscii = `${serverUrl}/static/wind_power_speed_2017_2021.asc`;
// test data to generate the pdf file
export const textData = {
  tgext1: 'Energía anual promedia generada:',
  text2: 'Altura del rotor                Energía',
  text3: '1.) Esta función se obtuvo con los datos históricos horarios simulados con el modelo WRFV4.3 para el periodo 2020 - 2021 a una resolución de 6km. El modelo WRF ha sido ajustado a las particularidades del territorio mediante la optimización de su configuración y el ajuste de los datos de entrada de la topografía y uso de suelo. Las especificaciones del ajuste del modelo se pueden consultar en http://data.meteocolombia.com.co/Descrip_WRF2021.pdf',
  text4: '2.) El cálculo de la energía promedia asume una curva de potencia trapezoidal, donde la potencia es constante entre la velocidad nominal y la velocidad de salida. Entre la velocidad de entrada y velocidad nominal la potencia aumenta de forma lineal. Con los parámetros potencia y velocidad nominal, velocidad de entrada y velocidad de salida el usuario especifica implícitamente el diseño aerodinámico y electromecánico de cada turbina.',
  text5: '3.) Los valores de energía son estimaciones que se entregan al usuario como orientación para seleccionar el sitio de instalación y determinar la configuración óptima de la turbina. Por lo anterior Meteocolombia S.A.S. no responde por daños y perjuicios causados por el uso de esta información.'
};
// default pdf file name to save wind power file
export const pdfName = 'wind_power.pdf';

// Settings for Single File Components
// LocationPicker.vue
// initial map center latitude
export const initialLat = 7.04925;
// initial map center longitude
export const initialLon = -73.89544;
// initial map zoom
export const initialZoom = 5;

// Settings for ForeclastPlace view
// temperature variable.
export const tempVariable = 'T2';
// wind speed variable.
export const windVariable = 'UUVV10';
// rain variable.
export const rainVariables = ['prech', 'ACPRECHR', 'ACPRECST'];

// Settings for FrontEnd view
// base url route for FrontEnd view.
export const frontendRoute = 'fronts';

// Avalable vue apps
export const defaultAppRoute = process.env.VUE_APP_METEO_APP || 'mapas_col';
export const defaultAppType = process.env.VUE_APP_METEO_APP_TYPE || 'maps';
export const windRoseApp = 'rose';
export const windPowerApp = 'power';
export const soundingsApp = 'soundings';
export const mapsSeriesApp = 'maps';
export const seriesApp = 'series';
export const cutsApp = 'cuts';
export const solarEnergy = 'solar';
export const rampLabelFontSize = 12;
export const vueApps = [
  {
    name: windRoseApp,
    component: 'WindRoseControl'
  },
  {
    name: windPowerApp,
    component: 'WindPowerControl'
  },
  {
    name: soundingsApp,
    component: 'SoundingsControl'
  },
  {
    name: mapsSeriesApp,
    component: 'MapSeriesControl'
  },
  {
    name: seriesApp,
    component: 'SeriesControl'
  },
  {
    name: cutsApp,
    component: 'CutsControl'
  },
  {
    name: solarEnergy,
    component: 'SolarEnergyControl'
  }
];
// PayU variables
export const payUWebcheckout = 'https://gateway.payulatam.com/ppp-web-gateway/';
export const merchantId = '695261';
export const accountId = '698233';
export const ApiKey = 'W5e9kaedbHC3vePoenbC7SDEqq';
// export const confirmationUrl = `${serverApi}confirmation`;
export const confirmationUrl = 'https://meteobolivia.com/api/confirmation';
export const serviceConfirmationUrl = `${serverApi}service_confirmation`;
export const paymentCurrency = process.env.VUE_APP_METEO_CURRENCY || 'COP';
export const premiumTitleText = 'Acceda a las aplicaciones en su versión avanzada, con pronósticos de 48 horas en intervalos horarios además de otras aplicaciones especializadas.';
