<template lang="pug">
  div
    div(id="burst-12" v-if="showStar" @mouseover="showStar = false")
      span NUEVO
    div(v-else @mouseleave="showStar = true")
      meteo-blog
</template>

<script>
import MeteoBlog from '@/components/ui/MeteoBlog.vue';

export default {
  name: 'MeteoNews',
  components: {
    MeteoBlog,
  },
  data() {
    return {
      showStar: true
    };
  },
};
</script>

<style scoped>
#burst-12 {
  background: red;
  width: 80px;
  height: 80px;
  position: relative;
  text-align: center;
  display: flex;
  cursor: pointer;
}
#burst-12:before,
#burst-12:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  background: red;
}
#burst-12:before {
  transform: rotate(30deg);
}
#burst-12:after {
  transform: rotate(60deg);
}

#burst-12 span {
  color: white;
  z-index: 100;
  font-size: x-large;
  font-weight: 400;
  transform: rotate(315deg);
  padding-top: 20px;
}
</style>
