import { mapState } from 'vuex';
import { makeId } from '../utils';

export default {
  data() {
    return {
      showTip: true,
      realModules: {},
    };
  },
  computed: {
    ...mapState('ui/leaflet', ['currentAppRate'])
  },
  props: {
    controlModules: {
      type: Object,
      required: false,
    }
  },
  methods: {
    markerMixinReady() {
      this.mixinMarker = this.$refs.mixinMarker.mapObject;
    },
    toggleTip() {
      this.showTip = !this.showTip;
    },
    async printElement(elementId) {
      /**
       * Funcion para imprimir una imagen de la caja que se especifique
       *
       * @param {String} elementId El id del elemento
       * @public Este es un metodo publico
       */
      const el = document.getElementById(elementId);
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL'
      };
      const imageId = makeId(8);
      const output = await this.$html2canvas(el, options);
      const routeData = this.$router.resolve({ name: 'ForecastDetail', params: { imageId } });
      localStorage.setItem(imageId, output);
      window.open(routeData.href, '_blank');
    },
    showForecast(data, ghaphType, title) {
      /**
       * Funcion para ver el resultado de un pronostico en una ventana completa
       *
       * @param {Object} data datsos necesarios para mostrar el grafico
       * @param {String} ghaphType Tipo de grafico que debe ser mostrado
       * @public Este es un metodo publico
       */
      // crear el id para ubicar elemento en el localStorage
      const elementId = makeId(8);
      const routeData = this.$router.resolve({
        name: 'ForecastDetail',
        params: {
          elementId,
          ghaphType,
        }
      });
      // crear el id para ubicar elemento en el localStorage
      localStorage.setItem(elementId, JSON.stringify({
        data,
        title
      }));
      window.open(
        routeData.href,
        '_blank',
        'toolbar=0,location=no,height=500,width=520,scrollbars=yes,status=no,resizable=0'
      );
    },
    goPayment() {
      this.$bus.$emit('select-modal-form', {
        component: 'credit-manager',
        title: 'Adquiera créditos para las aplicaciones de Meteocolombia'
      });
    },
  }
};
