<template lang="pug">
  div(:style="{width: `${widthContainer}px`, height: `${heightContainer + 30}px`}")
    div(class="no-data-container" v-show="noData")
      v-icon(style="font-size:250%") mdi-power-plug-off
      span(class="font-weight-light") Oops! {{errorMsg}}.
    div(v-show="!noData")
      span(class="diagram-title" :style="{width: `${widthContainer}px`}")
        | {{graphTitle}}
      div(
        id="skewt"
        :style="{width: `${widthContainer}px`, paddingTop: '15px'}")
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';

export default {
  name: 'SkewTDiagram',
  props: {
    widthContainer: {
      type: Number,
      required: false,
      default: 400
    },
    heightContainer: {
      type: Number,
      required: false,
      default: 400
    }
  },
  data(instance) {
    const lines = instance.$attrs.skewtData || [];
    return {
      noData: false,
      errorMsg: '',
      graphTitle: '',
      tMin: -100,
      tMax: 40,
      tStep: 10,
      pLines: [1000, 850, 700, 500, 300, 200, 100],
      pTicks: [950, 900, 800, 750, 650, 600, 550, 450, 400, 350, 250, 150],
      topPressure: 100,
      basePressure: 1050,
      lines,
      skewtGroup: null,
      barbGroup: null,
      x: null,
      y: null,
      tan: Math.tan(55 * (Math.PI / 180)),
      unit: 'kt', // or kmh
      w: null,
      h: null,
      container: null,
      barbsize: 25,
    };
  },
  created() {
    this.$bus.$on('skewt-no-load', (error) => {
      this.errorMsg = error.error;
      this.noData = true;
    });
    this.$bus.$on('skewt-data-loaded', (data) => {
      this.noData = false;
      this.lines = data.data;
      this.graphTitle = `Diagrama Skew-T para el periodo ${data.date} en el punto (${data.lat.toFixed(4)}, ${data.lon.toFixed(4)})`;

      // clear previous diagram data
      const el = document.getElementById('skewt');
      el.innerHTML = '';

      this.initSkewt('#skewt');
      this.plot(this.lines);
    });
  },
  mounted() {
    if (this.lines) {
      // clear previous diagram data
      const el = document.getElementById('skewt');
      el.innerHTML = '';

      this.initSkewt('#skewt');
      this.plot(this.lines);
    }
  },
  methods: {
    plot(s) {
      const me = this;
      const { x, y } = this;
      const plotData = s || [];
      me.skewtGroup.selectAll('path').remove(); // clear previous paths from skew
      me.barbGroup.selectAll('use').remove(); // clear previous paths from barbs

      // salir si no hay datos
      if (plotData.length === 0) return;

      // skew-t stuff
      const skewtline = plotData.filter((d) => (d.temp > -1000 && d.dwpt > -1000));
      const skewtlines = [];
      skewtlines.push(skewtline);

      const templine = d3.line()
        .curve(d3.curveBasis)
        .x((d, i) => x(d.temp) + (y(me.basePressure)
          - y(d.press)) / me.tan).y((d, i) => y(d.press));
      const tempLines = me.skewtGroup.selectAll('templines')
        .data(skewtlines).enter().append('path')
        .attr('class', (d, i) => ((i < 10) ? 'temp skline' : 'temp mean'))
        .attr('clip-path', 'url(#clipper)')
        .attr('d', templine);

      const tempdewline = d3.line()
        .curve(d3.curveBasis)
        .x((d, i) => x(d.dwpt) + (y(me.basePressure)
          - y(d.press)) / me.tan).y((d, i) => y(d.press));
      const tempDewlines = me.skewtGroup.selectAll('tempdewlines')
        .data(skewtlines).enter().append('path')
        .attr('class', (d, i) => ((i < 10) ? 'dwpt skline' : 'dwpt mean'))
        .attr('clip-path', 'url(#clipper)')
        .attr('d', tempdewline);

      // barbs stuff
      const barbs = skewtline.filter((d) =>
        (d.wdir >= 0 && d.wspd >= 0 && d.press >= me.topPressure));
      const allbarbs = me.barbGroup.selectAll('barbs')
        .data(barbs).enter().append('use')
        // .attr('xlink:href', (d) => `#barb${Math.round(me.convert(d.wspd, 'kt') / 5) * 5}`) // 0,5,10,15,... always in kt
        .attr('xlink:href', (d) => `#barb${Math.round(d.wspd / 5) * 5}`) // 0,5,10,15,... always in kt
        .attr('transform', (d, i) => `translate(${me.w},${y(d.press)}) rotate(${d.wdir + 180})`);

      // mouse over
      this.drawToolTips(skewtlines[0]);
    },
    drawToolTips(skewtlines) {
      const { x, y } = this;
      const me = this;
      const lines = skewtlines.reverse();
      // Draw tooltips
      const tmpcfocus = this.skewtGroup
        .append('g').attr('class', 'focus tmpc').style('display', 'none');
      tmpcfocus.append('circle').attr('r', 4);
      tmpcfocus.append('text').attr('x', 9).attr('dy', '.35em');

      const dwpcfocus = this.skewtGroup
        .append('g').attr('class', 'focus dwpc').style('display', 'none');
      dwpcfocus.append('circle').attr('r', 4);
      dwpcfocus.append('text').attr('x', -9).attr('text-anchor', 'end').attr('dy', '.35em');

      const hghtfocus = this.skewtGroup.append('g').attr('class', 'focus').style('display', 'none');
      hghtfocus.append('text').attr('x', 0).attr('text-anchor', 'start').attr('dy', '.35em');

      const wspdfocus = this.skewtGroup
        .append('g').attr('class', 'focus windspeed').style('display', 'none');
      wspdfocus.append('text').attr('x', 0).attr('text-anchor', 'start').attr('dy', '.35em');

      const bisectTemp = d3.bisector((d) => d.press).left; // bisector function for tooltips
      this.container.append('rect')
        .attr('class', 'overlay')
        .attr('width', me.w)
        .attr('height', me.h)
        .on('mouseover', () => {
          tmpcfocus.style('display', null);
          dwpcfocus.style('display', null);
          hghtfocus.style('display', null);
          wspdfocus.style('display', null);
        })
        .on('mouseout', () => {
          tmpcfocus.style('display', 'none');
          dwpcfocus.style('display', 'none');
          hghtfocus.style('display', 'none');
          wspdfocus.style('display', 'none');
        })
        .on('mousemove', (e) => {
          const y0 = y.invert(d3.pointer(e)[1]); // get y value of mouse pointer in pressure space
          const i = bisectTemp(lines, y0, 1, lines.length - 1);
          const d0 = lines[i - 1];
          const d1 = lines[i];
          const d = y0 - d0.press > d1.press - y0 ? d1 : d0;
          tmpcfocus.attr('transform', `translate(${x(d.temp) + (y(me.basePressure) - y(d.press)) / me.tan},${y(d.press)})`);
          dwpcfocus.attr('transform', `translate(${x(d.dwpt) + (y(me.basePressure) - y(d.press)) / me.tan},${y(d.press)})`);
          hghtfocus.attr('transform', `translate(0,${y(d.press)})`);
          tmpcfocus.select('text').text(`${Math.round(d.temp)}°C`);
          dwpcfocus.select('text').text(`${Math.round(d.dwpt)}°C`);
          hghtfocus.select('text').text(`-- ${Math.round(d.hght)} m`); // hgt or hghtagl ???
          wspdfocus.attr('transform', `translate(${me.w - 65},${y(d.press)})`);
          wspdfocus.select('text').text(`${Math.round(d.wspd)} ${me.unit}`);
        });
    },
    convert(msvalue, unit) {
      switch (unit) {
        case 'kt':
          return msvalue * 1.943844492;
        case 'kmh':
          return msvalue * 3.6;
        default:
          return msvalue;
      }
    },
    makeBarbTemplates() {
      const me = this;
      const speeds = d3.range(5, 105, 5);
      const barbdef = this.container.append('defs');
      speeds.forEach((d) => {
        const thisbarb = barbdef.append('g').attr('id', `barb${d}`);
        const flags = Math.floor(d / 50);
        const pennants = Math.floor((d - flags * 50) / 10);
        const halfpennants = Math.floor((d - flags * 50 - pennants * 10) / 5);
        let px = me.barbsize;
        // Draw wind barb stems
        thisbarb.append('line').attr('x1', 0).attr('x2', 0).attr('y1', 0)
          .attr('y2', me.barbsize);
        // Draw wind barb flags and pennants for each stem
        for (let i = 0; i < flags; i++) {
          thisbarb.append('polyline')
            .attr('points', `0,${px} -10,${px} 0,${px - 4}`)
            .attr('class', 'flag');
          px -= 7;
        }
        // Draw pennants on each barb
        for (let i = 0; i < pennants; i++) {
          thisbarb.append('line')
            .attr('x1', 0)
            .attr('x2', -10)
            .attr('y1', px)
            .attr('y2', px + 4);
          px -= 3;
        }
        // Draw half-pennants on each barb
        for (let i = 0; i < halfpennants; i++) {
          thisbarb.append('line')
            .attr('x1', 0)
            .attr('x2', -5)
            .attr('y1', px)
            .attr('y2', px + 2);
          px -= 3;
        }
      });
    },
    initSkewt(div) {
      // salir si no hay datos
      if (!this.lines) return;

      const me = this;
      const wrapper = d3.select(div);
      let width = parseInt(wrapper.style('width'), 10);
      let height = width; // tofix
      const margin = {
        top: 30, right: 40, bottom: 20, left: 35
      }; // container margins
      // functions for Scales and axes. Note the inverted domain for the y-scale: bigger is up!
      const r = d3.scaleLinear().range([0, 300]).domain([0, 150]);
      const y2 = d3.scaleLinear();
      let w; let h; let x; let y; let xAxis; let yAxis; let
        yAxis2;
      const data = [];
      // containers
      const svg = wrapper.append('svg').attr('id', 'svg'); // main svg
      this.container = svg.append('g').attr('id', 'container'); // container
      const skewtbg = this.container.append('g').attr('id', 'skewtbg').attr('class', 'skewtbg');// background
      me.skewtGroup = this.container.append('g').attr('class', 'skewt'); // put skewt lines in this group
      me.barbGroup = this.container.append('g').attr('class', 'windbarb'); // put barbs in this group

      // local functions
      function setVariables() {
        width = parseInt(wrapper.style('width'), 10) - 10; // tofix: using -10 to prevent x overflow
        height = width; // to fix
        w = width - margin.left - margin.right;
        h = width - margin.top - margin.bottom;
        me.w = w;
        me.h = h;
        x = d3.scaleLinear().range([0, w]).domain([-50, me.tMax]);
        y = d3.scaleLog().range([0, h]).domain([me.topPressure, me.basePressure]);
        me.x = x;
        me.y = y;
        xAxis = d3.axisBottom().scale(x).tickSize(0, 0).ticks(10);
        yAxis = d3.axisLeft().scale(y).tickSize(0, 0).tickValues(me.pLines)
          .tickFormat(d3.format('.0d'));
        yAxis2 = d3.axisRight().scale(y).tickSize(5, 0).tickValues(me.pTicks);
      }

      const drawBackground = () => {
        // Add clipping path
        skewtbg.append('clipPath')
          .attr('id', 'clipper')
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', w)
          .attr('height', h);

        // Skewed temperature lines
        skewtbg.selectAll('templine')
          .data(d3.range(this.tMin, this.tMax, this.tStep))
          .enter().append('line')
          .attr('x1', (d) => x(d) - 0.5 + (y(me.basePressure) - y(100)) / me.tan)
          .attr('x2', (d) => x(d) - 0.5)
          .attr('y1', 0)
          .attr('y2', h)
          .attr('class', (d) => { if (d === 0) { return 'tempzero'; } return 'gridline'; })
          .attr('clip-path', 'url(#clipper)');

        // Logarithmic pressure lines
        skewtbg.selectAll('pressureline')
          .data(this.pLines.concat(this.pTicks))
          .enter().append('line')
          .attr('x1', 0)
          .attr('x2', w)
          .attr('y1', (d) => y(d))
          .attr('y2', (d) => y(d))
          .attr('class', 'gridline');

        // create array to plot dry adiabats
        const pp = d3.range(me.topPressure, me.basePressure + 1, 10);
        const dryad = d3.range(-30, 240, 20);
        const all = [];
        for (let i = 0; i < dryad.length; i++) {
          const z = [];
          for (let j = 0; j < pp.length; j++) { z.push(dryad[i]); }
          all.push(z);
        }

        const dryline = d3.line()
          .curve(d3.curveBasis)
          .x((d, i) => (x((273.15 + d) / ((1000 / pp[i]) ** 0.286) - 273.15)
            + (y(me.basePressure) - y(pp[i])) / me.tan))
          .y((d, i) => y(pp[i]));

        // Draw dry adiabats
        skewtbg.selectAll('dryadiabatline')
          .data(all)
          .enter().append('path')
          .attr('class', 'gridline')
          .attr('clip-path', 'url(#clipper)')
          .attr('d', dryline);

        // Line along right edge of plot
        skewtbg.append('line')
          .attr('x1', w - 0.5)
          .attr('x2', w - 0.5)
          .attr('y1', 0)
          .attr('y2', h)
          .attr('class', 'gridline');

        // Add axes
        skewtbg.append('g').attr('class', 'x axis').attr('transform', `translate(0,${h - 0.5})`).call(xAxis);
        skewtbg.append('g').attr('class', 'y axis').attr('transform', 'translate(-0.5,0)').call(yAxis);
        skewtbg.append('g').attr('class', 'y axis ticks').attr('transform', 'translate(-0.5,0)').call(yAxis2);
      };

      function resize() {
        skewtbg.selectAll('*').remove();
        setVariables();
        svg.attr('width', w + margin.right + margin.left).attr('height', h + margin.top + margin.bottom);
        me.container.attr('transform', `translate(${margin.left},${margin.top})`);
        drawBackground();
        me.makeBarbTemplates();
        me.plot(me.lines);
      }

      // assigns d3 events
      d3.select(window).on('resize', resize);

      const clear = (s) => {
        me.skewtGroup.selectAll('path').remove(); // clear previous paths from skew
        me.barbGroup.selectAll('use').remove(); // clear previous paths  from barbs
        // must clear tooltips!
        me.container.append('rect')
          .attr('class', 'overlay')
          .attr('width', w)
          .attr('height', h)
          .on('mouseover', () => false)
          .on('mouseout', () => false)
          .on('mousemove', () => false);
      };

      setVariables();
      resize();
      this.plot(this.lines);
      // this.generatemixRatios();
    }
  }
};
</script>

<style scoped>
.control-container {
  display: flex;
  zoom: 0.8;
  height: 20px;
  margin-left: 15px;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 45%;
}

.diagram-title {
  font-size: 60%;
  font-weight: 500;
  text-align: center;
  display: table-header-group;
  position: absolute;
}
</style>
