<template lang="pug">
div
  transition(name="slide")
    l-control.control-container(
      v-show="dialog"
      position="bottomleft"
      style="margin: 0px; position: absolute; bottom: 0px; left: 0px; width: 98vw;")
      div(style="min-height:290px")
        div(
          style="box-shadow: 0px 3px 4px rgb(0 0 0 / 50%);"
          class="text-center blue darken-2 white--text py-1 text-subtitle-2")
          | Resultado gráfica series de tiempo
          v-btn(class="float-right pb-2" icon dark @click="dialog = false")
            v-icon mdi-close
        //- bar-loader(v-if="loadingGraph")
        div(class="d-flex")
          v-switch(
            class="mt-0"
            @click="lineGraph = !lineGraph"
            color="orange darken-3"
            title="Cambiar tipo de grafico")
          span(class="mt-2") Graficar con {{lineGraph === true ? 'barras' : 'líneas' }}
        forecast-line-chart(
          v-show="lineGraph === true"
          :graphLabels="netCDFPeriods"
          :variables="[]"
          style="height:250px")
        forecast-bar-chart(
          v-show="lineGraph === false"
          :graphLabels="netCDFPeriods"
          :variables="[]"
          style="height:250px")
  l-marker(
    v-if="selectedPnt"
    :lat-lng="selectedPnt"
    :icon="icon"
    @ready="markerMixinReady"
    ref="mixinMarker")
  l-control.control-container.bgLeafletControl(
    :style="{width: showTip === true ? '300px' : '50px'}"
    position="topright")
    .control-icon.py-1.px-1(
      v-if="!showTip",
      @click="toggleTip",
      style="cursor: pointer"
      title="Consultar serie de tiempo"
    )
      v-icon(large) mdi-chart-areaspline
    .control-tip(v-else)
      .text-h5.mb-1
        | Generar series de tiempo
        v-chip(
          v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
          class="pa-1 float-right"
          color="green"
          :title="premiumTitle"
          @click="goPayment"
          outlined)
          v-icon mdi-cart-arrow-down
          |<b>Comprar</b>
      v-divider
      .my-2
        | Seleccione un punto en el mapa para construir una serie de
        | tiempo con la variable seleccionada.
      v-divider(v-if="selectedPnt")
      .my-2(v-if="selectedPnt")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Punto seleccionado
        div {{selectedPnt.lat}} | {{selectedPnt.lng}}
      v-divider(v-if="true")
      .my-2(v-if="true")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Ingresar datos
          div(style="zoom: 0.8")
            forecast-variables(ref="variablesControl")
      v-divider
      span(v-if="errorMsg" class="mt-2 control-error errorLefletControl--text") {{errorMsg}}
      .mt-2.d-flex
        v-btn(
          x-small
          color="errorLefletControl"
          class="ma-2 white--text"
          @click="cancel") Cerrar
            v-icon(right dark small) mdi-close-circle
        v-spacer
        v-btn(
          :loading="disabledButton"
          :disabled="disabledButton"
          x-small
          color="successLefletControl"
          class="ma-2 white--text"
          @click="generateSeries") Generar serie
            v-icon(right dark small) mdi-check-circle
</template>

<script>
import { mapState } from 'vuex';
import {
  LMarker,
  LControl,
} from 'vue2-leaflet';
import { icon } from 'leaflet';
import ForecastLineChart from '@/components/Forecast/ForecastLineChart.vue';
import ForecastBarChart from '@/components/Forecast/ForecastBarChart.vue';
import markerMixin from '@/mixins/markerMixin.js';
import lfControlMixin from '@/mixins/lfControlMixin.js';
import ForecastVariables from '@/components/Forecast/ForecastVariables.vue';
import { serverApi, frontendRoute, premiumTitleText } from '@/config';
import { getToken } from '@/utils.js';

export default {
  name: 'SeriesControl',
  components: {
    LMarker,
    LControl,
    ForecastLineChart,
    ForecastBarChart,
    ForecastVariables,
  },
  mixins: [markerMixin, lfControlMixin],
  data() {
    return {
      dialog: false,
      icon: icon({
        iconUrl: require('@/assets/marker-icon.png'),
        shadowUrl: require('@/assets/marker-shadow.png'),
        iconSize: [25, 37],
        iconAnchor: [16, 37]
      }),
      loadingGraph: false,
      errorMsg: '',
      selectedPnt: null,
      dataLoaded: false,
      lineGraph: true,
      disabledButton: false,
    };
  },
  computed: {
    ...mapState('gis', ['initialMapZoom', 'baseLayers', 'mapLat', 'mapLon']),
    ...mapState('ui/leaflet', ['netCDFVariables', 'netCDFPeriods', 'netCDFLevels',
      'selectedLevel', 'selectedVariable']),
    premiumTitle() {
      return premiumTitleText;
    },
  },
  methods: {
    cancel() {
      this.showTip = !this.showTip;
      this.selectedPnt = null;
      this.errorMsg = '';
    },
    async generateSeries() {
      if (!this.selectedPnt) {
        this.errorMsg = 'Debe seleccionar un punto sobre el mapa.';
        return;
      }

      if (!this.selectedLevel) {
        if (this.selectedVariable.type3D) {
          this.errorMsg = 'Debe seleccionar un nivel.';
          return;
        }
      }

      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores.';
        return;
      }

      this.errorMsg = '';
      this.loadingGraph = true;
      this.disabledButton = true;
      // se obtinen los datos geoJSON de la polylinea
      const coords = this.mixinMarker.toGeoJSON().geometry.coordinates;
      // hacer peticion para cargar datos de la serie
      let querys = `lat=${coords[1]}&lon=${coords[0]}&variable=${this.selectedVariable.variable}`;
      // traer los valores de las variables extra si existen
      const extraParams = this.$refs.variablesControl.urlQueryParams();
      if (this.selectedVariable.type3D) {
        querys += `&level=${this.selectedLevel}`;
      }
      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      fetch(`${serverApi}${frontendRoute}/${this.controlModules.series}?${querys}${extraParams}`, {
        headers
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.$bus.$emit('add-line-dataset', {
            labels: this.netCDFPeriods,
            data: responseJson.data,
            fill: false,
            title: `${this.selectedVariable.name} [${this.selectedVariable.unit}]`
          });
          this.$bus.$emit('add-bar-dataset', {
            labels: this.netCDFPeriods,
            data: responseJson.data,
            fill: false,
            title: `${this.selectedVariable.name} [${this.selectedVariable.unit}]`
          });
          this.dialog = true;
          this.loadingGraph = false;
          this.disabledButton = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingGraph = false;
          this.disabledButton = false;
        });
    },
    toggleTip() {
      this.showTip = !this.showTip;
    },
    mapClick(e) {
      if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.selectedPnt = {
          lat,
          lng,
        };
      }
    },
  },
};
</script>

<style scoped>
.control-container {
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-radius: 5px;
}

.control-icon {
  height: 44px;
  width: 44px;
}

.control-tip {
  padding: 5px;
  height: auto;
  width: 350px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
