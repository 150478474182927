<template lang="pug">
  div(class="no-data-container" :style="{height: heightVal}")
    div(class="info-wrapper")
      v-icon(style="font-size:250%") mdi-power-plug-off
      span(class="font-weight-light") Oops! {{errorMsg}}.
</template>

<script>
export default {
  props: {
    heightVal: {
      type: [Number, String],
      required: true
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.no-data-container {
  text-align: center;
  position: relative;
  height: 300px;
}
.info-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: calc(18vh - 48px);
}
</style>
