<template lang="pug">
  div
    v-text-field(
      v-model="coordinates"
      :hint="helpText"
      :label="label"
      :rules="[rules.validateCoordinates]"
      prepend-icon="mdi-map-marker"
      @click:prepend="testFun")
    v-dialog(
      v-model="showDialog"
      width="500")
      v-card
        v-card-title(class="white--text text-h5 blue darken-1 font-weight-light")
          | {{label}}
        v-card-subtitle(class="white--text font-weght-light pb-2")
          | Haga click en el mapa para extraer las coordenadas
        v-card-text(class="my-0")
          div(class="map-container")
            l-map(
              :zoom="zoom"
              :center="center"
              @click="mapClick"
              ref="mapPicker"
            )
              l-wms-tile-layer(
                base-url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                name="Open Street"
              )
              l-marker(v-if="marker" :lat-lng="marker")
          v-row(justify="center" style="max-height:45px")
            v-col(cols="6")
              v-subheader Latitud: {{lat}}
            v-col(cols="6")
              v-subheader Longitud: {{lng}}
        v-divider
        v-card-actions
          v-spacer
          v-btn(
            color="primary"
            text
            @click="setCoordinates") Aceptar
</template>

<script>
import { latLng } from 'leaflet';
import {
  LMap, LWMSTileLayer, LMarker, LIcon
} from 'vue2-leaflet';
import { initialLat, initialLon, initialZoom } from '../config.js';

export default {
  name: 'LocationPicker',
  components: {
    LMap,
    LMarker,
    LIcon,
    'l-wms-tile-layer': LWMSTileLayer,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      center: latLng(initialLat, initialLon),
      zoom: initialZoom,
      marker: null,
      coordinates: '',
      lat: '',
      lng: '',
      rules: {
        validateCoordinates: (v) => {
          if (v === '') {
            return true;
          }
          const coords = String(v).trim().split(' ');
          if (coords.length !== 2) {
            return 'Solo deben existir dos valores de coordenadas';
          }
          const regexNumber = /[+-]?([0-9]*?[.])?[0-9]+/;
          if (!(regexNumber.test(coords[0]) && regexNumber.test(coords[1]))) {
            return 'Las coordenadas deben ser valores numericos';
          }
          return true;
        }
      },
    };
  },
  watch: {
    showDialog(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        setTimeout(() => {
          // mapObject is a property that is part of leaflet
          this.$refs.mapPicker.mapObject.invalidateSize();
        }, 200);
      }
    }
  },
  methods: {
    testFun() {
      this.showDialog = true;
    },
    mapClick(e) {
      console.log('map click', e.latlng.lat);
      this.marker = latLng(e.latlng.lat, e.latlng.lng);
      this.lat = e.latlng.lat.toFixed(6);
      this.lng = e.latlng.lng.toFixed(6);
    },
    setCoordinates() {
      this.showDialog = false;
      this.coordinates = `${this.lat} ${this.lng}`;
    }
  },
};
</script>

<style scoped>
  .map-container {
    width: 100%;
    height: 300px;
  }
</style>
