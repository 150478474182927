<template lang="pug">
  v-text-field(
    v-model="value"
    :label="label"
    type="text",
    @input="handleInput")
</template>

<script>
// $event.target.value
export default {
  name: 'CurrencyInput',
  props: ['label', 'value'],
  data() {
    return {
      mutableValue: this.value
    };
  },
  methods: {
    handleInput(value) {
      console.log('handleInput', value);
      // value = new Intl.NumberFormat().format(value);
      let formatValue = value.replaceAll(',', '').replaceAll('.', '');
      console.log('parseInt', formatValue);
      formatValue = new Intl.NumberFormat().format(parseInt(formatValue, 10));
      console.log('formatValue', formatValue);
      this.$emit('input', formatValue);
    }
  }
};
</script>
