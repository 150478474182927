(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
  'use strict';
  
  
  var zlib_inflate = require('./zlib/inflate');
  var utils        = require('./utils/common');
  var strings      = require('./utils/strings');
  var c            = require('./zlib/constants');
  var msg          = require('./zlib/messages');
  var ZStream      = require('./zlib/zstream');
  var GZheader     = require('./zlib/gzheader');
  
  var toString = Object.prototype.toString;
  
  /**
   * class Inflate
   *
   * Generic JS-style wrapper for zlib calls. If you don't need
   * streaming behaviour - use more simple functions: [[inflate]]
   * and [[inflateRaw]].
   **/
  
  /* internal
    * inflate.chunks -> Array
    *
    * Chunks of output data, if [[Inflate#onData]] not overriden.
    **/
  
  /**
   * Inflate.result -> Uint8Array|Array|String
   *
   * Uncompressed result, generated by default [[Inflate#onData]]
   * and [[Inflate#onEnd]] handlers. Filled after you push last chunk
   * (call [[Inflate#push]] with `Z_FINISH` / `true` param) or if you
   * push a chunk with explicit flush (call [[Inflate#push]] with
   * `Z_SYNC_FLUSH` param).
   **/
  
  /**
   * Inflate.err -> Number
   *
   * Error code after inflate finished. 0 (Z_OK) on success.
   * Should be checked if broken data possible.
   **/
  
  /**
   * Inflate.msg -> String
   *
   * Error message, if [[Inflate.err]] != 0
   **/
  
  
  /**
   * new Inflate(options)
   * - options (Object): zlib inflate options.
   *
   * Creates new inflator instance with specified params. Throws exception
   * on bad params. Supported options:
   *
   * - `windowBits`
   * - `dictionary`
   *
   * [http://zlib.net/manual.html#Advanced](http://zlib.net/manual.html#Advanced)
   * for more information on these.
   *
   * Additional options, for internal needs:
   *
   * - `chunkSize` - size of generated data chunks (16K by default)
   * - `raw` (Boolean) - do raw inflate
   * - `to` (String) - if equal to 'string', then result will be converted
   *   from utf8 to utf16 (javascript) string. When string output requested,
   *   chunk length can differ from `chunkSize`, depending on content.
   *
   * By default, when no options set, autodetect deflate/gzip data format via
   * wrapper header.
   *
   * ##### Example:
   *
   * ```javascript
   * var pako = require('pako')
   *   , chunk1 = Uint8Array([1,2,3,4,5,6,7,8,9])
   *   , chunk2 = Uint8Array([10,11,12,13,14,15,16,17,18,19]);
   *
   * var inflate = new pako.Inflate({ level: 3});
   *
   * inflate.push(chunk1, false);
   * inflate.push(chunk2, true);  // true -> last chunk
   *
   * if (inflate.err) { throw new Error(inflate.err); }
   *
   * console.log(inflate.result);
   * ```
   **/
  function Inflate(options) {
    if (!(this instanceof Inflate)) return new Inflate(options);
  
    this.options = utils.assign({
      chunkSize: 16384,
      windowBits: 0,
      to: ''
    }, options || {});
  
    var opt = this.options;
  
    // Force window size for `raw` data, if not set directly,
    // because we have no header for autodetect.
    if (opt.raw && (opt.windowBits >= 0) && (opt.windowBits < 16)) {
      opt.windowBits = -opt.windowBits;
      if (opt.windowBits === 0) { opt.windowBits = -15; }
    }
  
    // If `windowBits` not defined (and mode not raw) - set autodetect flag for gzip/deflate
    if ((opt.windowBits >= 0) && (opt.windowBits < 16) &&
        !(options && options.windowBits)) {
      opt.windowBits += 32;
    }
  
    // Gzip header has no info about windows size, we can do autodetect only
    // for deflate. So, if window size not set, force it to max when gzip possible
    if ((opt.windowBits > 15) && (opt.windowBits < 48)) {
      // bit 3 (16) -> gzipped data
      // bit 4 (32) -> autodetect gzip/deflate
      if ((opt.windowBits & 15) === 0) {
        opt.windowBits |= 15;
      }
    }
  
    this.err    = 0;      // error code, if happens (0 = Z_OK)
    this.msg    = '';     // error message
    this.ended  = false;  // used to avoid multiple onEnd() calls
    this.chunks = [];     // chunks of compressed data
  
    this.strm   = new ZStream();
    this.strm.avail_out = 0;
  
    var status  = zlib_inflate.inflateInit2(
      this.strm,
      opt.windowBits
    );
  
    if (status !== c.Z_OK) {
      throw new Error(msg[status]);
    }
  
    this.header = new GZheader();
  
    zlib_inflate.inflateGetHeader(this.strm, this.header);
  }
  
  /**
   * Inflate#push(data[, mode]) -> Boolean
   * - data (Uint8Array|Array|ArrayBuffer|String): input data
   * - mode (Number|Boolean): 0..6 for corresponding Z_NO_FLUSH..Z_TREE modes.
   *   See constants. Skipped or `false` means Z_NO_FLUSH, `true` meansh Z_FINISH.
   *
   * Sends input data to inflate pipe, generating [[Inflate#onData]] calls with
   * new output chunks. Returns `true` on success. The last data block must have
   * mode Z_FINISH (or `true`). That will flush internal pending buffers and call
   * [[Inflate#onEnd]]. For interim explicit flushes (without ending the stream) you
   * can use mode Z_SYNC_FLUSH, keeping the decompression context.
   *
   * On fail call [[Inflate#onEnd]] with error code and return false.
   *
   * We strongly recommend to use `Uint8Array` on input for best speed (output
   * format is detected automatically). Also, don't skip last param and always
   * use the same type in your code (boolean or number). That will improve JS speed.
   *
   * For regular `Array`-s make sure all elements are [0..255].
   *
   * ##### Example
   *
   * ```javascript
   * push(chunk, false); // push one of data chunks
   * ...
   * push(chunk, true);  // push last chunk
   * ```
   **/
  Inflate.prototype.push = function (data, mode) {
    var strm = this.strm;
    var chunkSize = this.options.chunkSize;
    var dictionary = this.options.dictionary;
    var status, _mode;
    var next_out_utf8, tail, utf8str;
    var dict;
  
    // Flag to properly process Z_BUF_ERROR on testing inflate call
    // when we check that all output data was flushed.
    var allowBufError = false;
  
    if (this.ended) { return false; }
    _mode = (mode === ~~mode) ? mode : ((mode === true) ? c.Z_FINISH : c.Z_NO_FLUSH);
  
    // Convert data if needed
    if (typeof data === 'string') {
      // Only binary strings can be decompressed on practice
      strm.input = strings.binstring2buf(data);
    } else if (toString.call(data) === '[object ArrayBuffer]') {
      strm.input = new Uint8Array(data);
    } else {
      strm.input = data;
    }
  
    strm.next_in = 0;
    strm.avail_in = strm.input.length;
  
    do {
      if (strm.avail_out === 0) {
        strm.output = new utils.Buf8(chunkSize);
        strm.next_out = 0;
        strm.avail_out = chunkSize;
      }
  
      status = zlib_inflate.inflate(strm, c.Z_NO_FLUSH);    /* no bad return value */
  
      if (status === c.Z_NEED_DICT && dictionary) {
        // Convert data if needed
        if (typeof dictionary === 'string') {
          dict = strings.string2buf(dictionary);
        } else if (toString.call(dictionary) === '[object ArrayBuffer]') {
          dict = new Uint8Array(dictionary);
        } else {
          dict = dictionary;
        }
  
        status = zlib_inflate.inflateSetDictionary(this.strm, dict);
  
      }
  
      if (status === c.Z_BUF_ERROR && allowBufError === true) {
        status = c.Z_OK;
        allowBufError = false;
      }
  
      if (status !== c.Z_STREAM_END && status !== c.Z_OK) {
        this.onEnd(status);
        this.ended = true;
        return false;
      }
  
      if (strm.next_out) {
        if (strm.avail_out === 0 || status === c.Z_STREAM_END || (strm.avail_in === 0 && (_mode === c.Z_FINISH || _mode === c.Z_SYNC_FLUSH))) {
  
          if (this.options.to === 'string') {
  
            next_out_utf8 = strings.utf8border(strm.output, strm.next_out);
  
            tail = strm.next_out - next_out_utf8;
            utf8str = strings.buf2string(strm.output, next_out_utf8);
  
            // move tail
            strm.next_out = tail;
            strm.avail_out = chunkSize - tail;
            if (tail) { utils.arraySet(strm.output, strm.output, next_out_utf8, tail, 0); }
  
            this.onData(utf8str);
  
          } else {
            this.onData(utils.shrinkBuf(strm.output, strm.next_out));
          }
        }
      }
  
      // When no more input data, we should check that internal inflate buffers
      // are flushed. The only way to do it when avail_out = 0 - run one more
      // inflate pass. But if output data not exists, inflate return Z_BUF_ERROR.
      // Here we set flag to process this error properly.
      //
      // NOTE. Deflate does not return error in this case and does not needs such
      // logic.
      if (strm.avail_in === 0 && strm.avail_out === 0) {
        allowBufError = true;
      }
  
    } while ((strm.avail_in > 0 || strm.avail_out === 0) && status !== c.Z_STREAM_END);
  
    if (status === c.Z_STREAM_END) {
      _mode = c.Z_FINISH;
    }
  
    // Finalize on the last chunk.
    if (_mode === c.Z_FINISH) {
      status = zlib_inflate.inflateEnd(this.strm);
      this.onEnd(status);
      this.ended = true;
      return status === c.Z_OK;
    }
  
    // callback interim results if Z_SYNC_FLUSH.
    if (_mode === c.Z_SYNC_FLUSH) {
      this.onEnd(c.Z_OK);
      strm.avail_out = 0;
      return true;
    }
  
    return true;
  };
  
  
  /**
   * Inflate#onData(chunk) -> Void
   * - chunk (Uint8Array|Array|String): ouput data. Type of array depends
   *   on js engine support. When string output requested, each chunk
   *   will be string.
   *
   * By default, stores data blocks in `chunks[]` property and glue
   * those in `onEnd`. Override this handler, if you need another behaviour.
   **/
  Inflate.prototype.onData = function (chunk) {
    this.chunks.push(chunk);
  };
  
  
  /**
   * Inflate#onEnd(status) -> Void
   * - status (Number): inflate status. 0 (Z_OK) on success,
   *   other if not.
   *
   * Called either after you tell inflate that the input stream is
   * complete (Z_FINISH) or should be flushed (Z_SYNC_FLUSH)
   * or if an error happened. By default - join collected chunks,
   * free memory and fill `results` / `err` properties.
   **/
  Inflate.prototype.onEnd = function (status) {
    // On success - join
    if (status === c.Z_OK) {
      if (this.options.to === 'string') {
        // Glue & convert here, until we teach pako to send
        // utf8 alligned strings to onData
        this.result = this.chunks.join('');
      } else {
        this.result = utils.flattenChunks(this.chunks);
      }
    }
    this.chunks = [];
    this.err = status;
    this.msg = this.strm.msg;
  };
  
  
  /**
   * inflate(data[, options]) -> Uint8Array|Array|String
   * - data (Uint8Array|Array|String): input data to decompress.
   * - options (Object): zlib inflate options.
   *
   * Decompress `data` with inflate/ungzip and `options`. Autodetect
   * format via wrapper header by default. That's why we don't provide
   * separate `ungzip` method.
   *
   * Supported options are:
   *
   * - windowBits
   *
   * [http://zlib.net/manual.html#Advanced](http://zlib.net/manual.html#Advanced)
   * for more information.
   *
   * Sugar (options):
   *
   * - `raw` (Boolean) - say that we work with raw stream, if you don't wish to specify
   *   negative windowBits implicitly.
   * - `to` (String) - if equal to 'string', then result will be converted
   *   from utf8 to utf16 (javascript) string. When string output requested,
   *   chunk length can differ from `chunkSize`, depending on content.
   *
   *
   * ##### Example:
   *
   * ```javascript
   * var pako = require('pako')
   *   , input = pako.deflate([1,2,3,4,5,6,7,8,9])
   *   , output;
   *
   * try {
   *   output = pako.inflate(input);
   * } catch (err)
   *   console.log(err);
   * }
   * ```
   **/
  function inflate(input, options) {
    var inflator = new Inflate(options);
  
    inflator.push(input, true);
  
    // That will never happens, if you don't cheat with options :)
    if (inflator.err) { throw inflator.msg; }
  
    return inflator.result;
  }
  
  
  /**
   * inflateRaw(data[, options]) -> Uint8Array|Array|String
   * - data (Uint8Array|Array|String): input data to decompress.
   * - options (Object): zlib inflate options.
   *
   * The same as [[inflate]], but creates raw data, without wrapper
   * (header and adler32 crc).
   **/
  function inflateRaw(input, options) {
    options = options || {};
    options.raw = true;
    return inflate(input, options);
  }
  
  
  /**
   * ungzip(data[, options]) -> Uint8Array|Array|String
   * - data (Uint8Array|Array|String): input data to decompress.
   * - options (Object): zlib inflate options.
   *
   * Just shortcut to [[inflate]], because it autodetects format
   * by header.content. Done for convenience.
   **/
  
  
  exports.Inflate = Inflate;
  exports.inflate = inflate;
  exports.inflateRaw = inflateRaw;
  exports.ungzip  = inflate;
  
  },{"./utils/common":2,"./utils/strings":3,"./zlib/constants":5,"./zlib/gzheader":7,"./zlib/inflate":9,"./zlib/messages":11,"./zlib/zstream":12}],2:[function(require,module,exports){
  'use strict';
  
  
  var TYPED_OK =  (typeof Uint8Array !== 'undefined') &&
                  (typeof Uint16Array !== 'undefined') &&
                  (typeof Int32Array !== 'undefined');
  
  
  exports.assign = function (obj /*from1, from2, from3, ...*/) {
    var sources = Array.prototype.slice.call(arguments, 1);
    while (sources.length) {
      var source = sources.shift();
      if (!source) { continue; }
  
      if (typeof source !== 'object') {
        throw new TypeError(source + 'must be non-object');
      }
  
      for (var p in source) {
        if (source.hasOwnProperty(p)) {
          obj[p] = source[p];
        }
      }
    }
  
    return obj;
  };
  
  
  // reduce buffer size, avoiding mem copy
  exports.shrinkBuf = function (buf, size) {
    if (buf.length === size) { return buf; }
    if (buf.subarray) { return buf.subarray(0, size); }
    buf.length = size;
    return buf;
  };
  
  
  var fnTyped = {
    arraySet: function (dest, src, src_offs, len, dest_offs) {
      if (src.subarray && dest.subarray) {
        dest.set(src.subarray(src_offs, src_offs + len), dest_offs);
        return;
      }
      // Fallback to ordinary array
      for (var i = 0; i < len; i++) {
        dest[dest_offs + i] = src[src_offs + i];
      }
    },
    // Join array of chunks to single array.
    flattenChunks: function (chunks) {
      var i, l, len, pos, chunk, result;
  
      // calculate data length
      len = 0;
      for (i = 0, l = chunks.length; i < l; i++) {
        len += chunks[i].length;
      }
  
      // join chunks
      result = new Uint8Array(len);
      pos = 0;
      for (i = 0, l = chunks.length; i < l; i++) {
        chunk = chunks[i];
        result.set(chunk, pos);
        pos += chunk.length;
      }
  
      return result;
    }
  };
  
  var fnUntyped = {
    arraySet: function (dest, src, src_offs, len, dest_offs) {
      for (var i = 0; i < len; i++) {
        dest[dest_offs + i] = src[src_offs + i];
      }
    },
    // Join array of chunks to single array.
    flattenChunks: function (chunks) {
      return [].concat.apply([], chunks);
    }
  };
  
  
  // Enable/Disable typed arrays use, for testing
  //
  exports.setTyped = function (on) {
    if (on) {
      exports.Buf8  = Uint8Array;
      exports.Buf16 = Uint16Array;
      exports.Buf32 = Int32Array;
      exports.assign(exports, fnTyped);
    } else {
      exports.Buf8  = Array;
      exports.Buf16 = Array;
      exports.Buf32 = Array;
      exports.assign(exports, fnUntyped);
    }
  };
  
  exports.setTyped(TYPED_OK);
  
  },{}],3:[function(require,module,exports){
  // String encode/decode helpers
  'use strict';
  
  
  var utils = require('./common');
  
  
  // Quick check if we can use fast array to bin string conversion
  //
  // - apply(Array) can fail on Android 2.2
  // - apply(Uint8Array) can fail on iOS 5.1 Safary
  //
  var STR_APPLY_OK = true;
  var STR_APPLY_UIA_OK = true;
  
  try { String.fromCharCode.apply(null, [ 0 ]); } catch (__) { STR_APPLY_OK = false; }
  try { String.fromCharCode.apply(null, new Uint8Array(1)); } catch (__) { STR_APPLY_UIA_OK = false; }
  
  
  // Table with utf8 lengths (calculated by first byte of sequence)
  // Note, that 5 & 6-byte values and some 4-byte values can not be represented in JS,
  // because max possible codepoint is 0x10ffff
  var _utf8len = new utils.Buf8(256);
  for (var q = 0; q < 256; q++) {
    _utf8len[q] = (q >= 252 ? 6 : q >= 248 ? 5 : q >= 240 ? 4 : q >= 224 ? 3 : q >= 192 ? 2 : 1);
  }
  _utf8len[254] = _utf8len[254] = 1; // Invalid sequence start
  
  
  // convert string to array (typed, when possible)
  exports.string2buf = function (str) {
    var buf, c, c2, m_pos, i, str_len = str.length, buf_len = 0;
  
    // count binary size
    for (m_pos = 0; m_pos < str_len; m_pos++) {
      c = str.charCodeAt(m_pos);
      if ((c & 0xfc00) === 0xd800 && (m_pos + 1 < str_len)) {
        c2 = str.charCodeAt(m_pos + 1);
        if ((c2 & 0xfc00) === 0xdc00) {
          c = 0x10000 + ((c - 0xd800) << 10) + (c2 - 0xdc00);
          m_pos++;
        }
      }
      buf_len += c < 0x80 ? 1 : c < 0x800 ? 2 : c < 0x10000 ? 3 : 4;
    }
  
    // allocate buffer
    buf = new utils.Buf8(buf_len);
  
    // convert
    for (i = 0, m_pos = 0; i < buf_len; m_pos++) {
      c = str.charCodeAt(m_pos);
      if ((c & 0xfc00) === 0xd800 && (m_pos + 1 < str_len)) {
        c2 = str.charCodeAt(m_pos + 1);
        if ((c2 & 0xfc00) === 0xdc00) {
          c = 0x10000 + ((c - 0xd800) << 10) + (c2 - 0xdc00);
          m_pos++;
        }
      }
      if (c < 0x80) {
        /* one byte */
        buf[i++] = c;
      } else if (c < 0x800) {
        /* two bytes */
        buf[i++] = 0xC0 | (c >>> 6);
        buf[i++] = 0x80 | (c & 0x3f);
      } else if (c < 0x10000) {
        /* three bytes */
        buf[i++] = 0xE0 | (c >>> 12);
        buf[i++] = 0x80 | (c >>> 6 & 0x3f);
        buf[i++] = 0x80 | (c & 0x3f);
      } else {
        /* four bytes */
        buf[i++] = 0xf0 | (c >>> 18);
        buf[i++] = 0x80 | (c >>> 12 & 0x3f);
        buf[i++] = 0x80 | (c >>> 6 & 0x3f);
        buf[i++] = 0x80 | (c & 0x3f);
      }
    }
  
    return buf;
  };
  
  // Helper (used in 2 places)
  function buf2binstring(buf, len) {
    // use fallback for big arrays to avoid stack overflow
    if (len < 65537) {
      if ((buf.subarray && STR_APPLY_UIA_OK) || (!buf.subarray && STR_APPLY_OK)) {
        return String.fromCharCode.apply(null, utils.shrinkBuf(buf, len));
      }
    }
  
    var result = '';
    for (var i = 0; i < len; i++) {
      result += String.fromCharCode(buf[i]);
    }
    return result;
  }
  
  
  // Convert byte array to binary string
  exports.buf2binstring = function (buf) {
    return buf2binstring(buf, buf.length);
  };
  
  
  // Convert binary string (typed, when possible)
  exports.binstring2buf = function (str) {
    var buf = new utils.Buf8(str.length);
    for (var i = 0, len = buf.length; i < len; i++) {
      buf[i] = str.charCodeAt(i);
    }
    return buf;
  };
  
  
  // convert array to string
  exports.buf2string = function (buf, max) {
    var i, out, c, c_len;
    var len = max || buf.length;
  
    // Reserve max possible length (2 words per char)
    // NB: by unknown reasons, Array is significantly faster for
    //     String.fromCharCode.apply than Uint16Array.
    var utf16buf = new Array(len * 2);
  
    for (out = 0, i = 0; i < len;) {
      c = buf[i++];
      // quick process ascii
      if (c < 0x80) { utf16buf[out++] = c; continue; }
  
      c_len = _utf8len[c];
      // skip 5 & 6 byte codes
      if (c_len > 4) { utf16buf[out++] = 0xfffd; i += c_len - 1; continue; }
  
      // apply mask on first byte
      c &= c_len === 2 ? 0x1f : c_len === 3 ? 0x0f : 0x07;
      // join the rest
      while (c_len > 1 && i < len) {
        c = (c << 6) | (buf[i++] & 0x3f);
        c_len--;
      }
  
      // terminated by end of string?
      if (c_len > 1) { utf16buf[out++] = 0xfffd; continue; }
  
      if (c < 0x10000) {
        utf16buf[out++] = c;
      } else {
        c -= 0x10000;
        utf16buf[out++] = 0xd800 | ((c >> 10) & 0x3ff);
        utf16buf[out++] = 0xdc00 | (c & 0x3ff);
      }
    }
  
    return buf2binstring(utf16buf, out);
  };
  
  
  // Calculate max possible position in utf8 buffer,
  // that will not break sequence. If that's not possible
  // - (very small limits) return max size as is.
  //
  // buf[] - utf8 bytes array
  // max   - length limit (mandatory);
  exports.utf8border = function (buf, max) {
    var pos;
  
    max = max || buf.length;
    if (max > buf.length) { max = buf.length; }
  
    // go back from last position, until start of sequence found
    pos = max - 1;
    while (pos >= 0 && (buf[pos] & 0xC0) === 0x80) { pos--; }
  
    // Fuckup - very small and broken sequence,
    // return max, because we should return something anyway.
    if (pos < 0) { return max; }
  
    // If we came to start of buffer - that means vuffer is too small,
    // return max too.
    if (pos === 0) { return max; }
  
    return (pos + _utf8len[buf[pos]] > max) ? pos : max;
  };
  
  },{"./common":2}],4:[function(require,module,exports){
  'use strict';
  
  // Note: adler32 takes 12% for level 0 and 2% for level 6.
  // It doesn't worth to make additional optimizationa as in original.
  // Small size is preferable.
  
  function adler32(adler, buf, len, pos) {
    var s1 = (adler & 0xffff) |0,
        s2 = ((adler >>> 16) & 0xffff) |0,
        n = 0;
  
    while (len !== 0) {
      // Set limit ~ twice less than 5552, to keep
      // s2 in 31-bits, because we force signed ints.
      // in other case %= will fail.
      n = len > 2000 ? 2000 : len;
      len -= n;
  
      do {
        s1 = (s1 + buf[pos++]) |0;
        s2 = (s2 + s1) |0;
      } while (--n);
  
      s1 %= 65521;
      s2 %= 65521;
    }
  
    return (s1 | (s2 << 16)) |0;
  }
  
  
  module.exports = adler32;
  
  },{}],5:[function(require,module,exports){
  'use strict';
  
  
  module.exports = {
  
    /* Allowed flush values; see deflate() and inflate() below for details */
    Z_NO_FLUSH:         0,
    Z_PARTIAL_FLUSH:    1,
    Z_SYNC_FLUSH:       2,
    Z_FULL_FLUSH:       3,
    Z_FINISH:           4,
    Z_BLOCK:            5,
    Z_TREES:            6,
  
    /* Return codes for the compression/decompression functions. Negative values
    * are errors, positive values are used for special but normal events.
    */
    Z_OK:               0,
    Z_STREAM_END:       1,
    Z_NEED_DICT:        2,
    Z_ERRNO:           -1,
    Z_STREAM_ERROR:    -2,
    Z_DATA_ERROR:      -3,
    //Z_MEM_ERROR:     -4,
    Z_BUF_ERROR:       -5,
    //Z_VERSION_ERROR: -6,
  
    /* compression levels */
    Z_NO_COMPRESSION:         0,
    Z_BEST_SPEED:             1,
    Z_BEST_COMPRESSION:       9,
    Z_DEFAULT_COMPRESSION:   -1,
  
  
    Z_FILTERED:               1,
    Z_HUFFMAN_ONLY:           2,
    Z_RLE:                    3,
    Z_FIXED:                  4,
    Z_DEFAULT_STRATEGY:       0,
  
    /* Possible values of the data_type field (though see inflate()) */
    Z_BINARY:                 0,
    Z_TEXT:                   1,
    //Z_ASCII:                1, // = Z_TEXT (deprecated)
    Z_UNKNOWN:                2,
  
    /* The deflate compression method */
    Z_DEFLATED:               8
    //Z_NULL:                 null // Use -1 or null inline, depending on var type
  };
  
  },{}],6:[function(require,module,exports){
  'use strict';
  
  // Note: we can't get significant speed boost here.
  // So write code to minimize size - no pregenerated tables
  // and array tools dependencies.
  
  
  // Use ordinary array, since untyped makes no boost here
  function makeTable() {
    var c, table = [];
  
    for (var n = 0; n < 256; n++) {
      c = n;
      for (var k = 0; k < 8; k++) {
        c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
      }
      table[n] = c;
    }
  
    return table;
  }
  
  // Create table on load. Just 255 signed longs. Not a problem.
  var crcTable = makeTable();
  
  
  function crc32(crc, buf, len, pos) {
    var t = crcTable,
        end = pos + len;
  
    crc ^= -1;
  
    for (var i = pos; i < end; i++) {
      crc = (crc >>> 8) ^ t[(crc ^ buf[i]) & 0xFF];
    }
  
    return (crc ^ (-1)); // >>> 0;
  }
  
  
  module.exports = crc32;
  
  },{}],7:[function(require,module,exports){
  'use strict';
  
  
  function GZheader() {
    /* true if compressed data believed to be text */
    this.text       = 0;
    /* modification time */
    this.time       = 0;
    /* extra flags (not used when writing a gzip file) */
    this.xflags     = 0;
    /* operating system */
    this.os         = 0;
    /* pointer to extra field or Z_NULL if none */
    this.extra      = null;
    /* extra field length (valid if extra != Z_NULL) */
    this.extra_len  = 0; // Actually, we don't need it in JS,
                          // but leave for few code modifications
  
    //
    // Setup limits is not necessary because in js we should not preallocate memory
    // for inflate use constant limit in 65536 bytes
    //
  
    /* space at extra (only when reading header) */
    // this.extra_max  = 0;
    /* pointer to zero-terminated file name or Z_NULL */
    this.name       = '';
    /* space at name (only when reading header) */
    // this.name_max   = 0;
    /* pointer to zero-terminated comment or Z_NULL */
    this.comment    = '';
    /* space at comment (only when reading header) */
    // this.comm_max   = 0;
    /* true if there was or will be a header crc */
    this.hcrc       = 0;
    /* true when done reading gzip header (not used when writing a gzip file) */
    this.done       = false;
  }
  
  module.exports = GZheader;
  
  },{}],8:[function(require,module,exports){
  'use strict';
  
  // See state defs from inflate.js
  var BAD = 30;       /* got a data error -- remain here until reset */
  var TYPE = 12;      /* i: waiting for type bits, including last-flag bit */
  
  /*
      Decode literal, length, and distance codes and write out the resulting
      literal and match bytes until either not enough input or output is
      available, an end-of-block is encountered, or a data error is encountered.
      When large enough input and output buffers are supplied to inflate(), for
      example, a 16K input buffer and a 64K output buffer, more than 95% of the
      inflate execution time is spent in this routine.
  
      Entry assumptions:
  
          state.mode === LEN
          strm.avail_in >= 6
          strm.avail_out >= 258
          start >= strm.avail_out
          state.bits < 8
  
      On return, state.mode is one of:
  
          LEN -- ran out of enough output space or enough available input
          TYPE -- reached end of block code, inflate() to interpret next block
          BAD -- error in block data
  
      Notes:
  
      - The maximum input bits used by a length/distance pair is 15 bits for the
        length code, 5 bits for the length extra, 15 bits for the distance code,
        and 13 bits for the distance extra.  This totals 48 bits, or six bytes.
        Therefore if strm.avail_in >= 6, then there is enough input to avoid
        checking for available input while decoding.
  
      - The maximum bytes that a single length/distance pair can output is 258
        bytes, which is the maximum length that can be coded.  inflate_fast()
        requires strm.avail_out >= 258 for each loop to avoid checking for
        output space.
    */
  module.exports = function inflate_fast(strm, start) {
    var state;
    var _in;                    /* local strm.input */
    var last;                   /* have enough input while in < last */
    var _out;                   /* local strm.output */
    var beg;                    /* inflate()'s initial strm.output */
    var end;                    /* while out < end, enough space available */
  //#ifdef INFLATE_STRICT
    var dmax;                   /* maximum distance from zlib header */
  //#endif
    var wsize;                  /* window size or zero if not using window */
    var whave;                  /* valid bytes in the window */
    var wnext;                  /* window write index */
    // Use `s_window` instead `window`, avoid conflict with instrumentation tools
    var s_window;               /* allocated sliding window, if wsize != 0 */
    var hold;                   /* local strm.hold */
    var bits;                   /* local strm.bits */
    var lcode;                  /* local strm.lencode */
    var dcode;                  /* local strm.distcode */
    var lmask;                  /* mask for first level of length codes */
    var dmask;                  /* mask for first level of distance codes */
    var here;                   /* retrieved table entry */
    var op;                     /* code bits, operation, extra bits, or */
                                /*  window position, window bytes to copy */
    var len;                    /* match length, unused bytes */
    var dist;                   /* match distance */
    var from;                   /* where to copy match from */
    var from_source;
  
  
    var input, output; // JS specific, because we have no pointers
  
    /* copy state to local variables */
    state = strm.state;
    //here = state.here;
    _in = strm.next_in;
    input = strm.input;
    last = _in + (strm.avail_in - 5);
    _out = strm.next_out;
    output = strm.output;
    beg = _out - (start - strm.avail_out);
    end = _out + (strm.avail_out - 257);
  //#ifdef INFLATE_STRICT
    dmax = state.dmax;
  //#endif
    wsize = state.wsize;
    whave = state.whave;
    wnext = state.wnext;
    s_window = state.window;
    hold = state.hold;
    bits = state.bits;
    lcode = state.lencode;
    dcode = state.distcode;
    lmask = (1 << state.lenbits) - 1;
    dmask = (1 << state.distbits) - 1;
  
  
    /* decode literals and length/distances until end-of-block or not enough
        input data or output space */
  
    top:
    do {
      if (bits < 15) {
        hold += input[_in++] << bits;
        bits += 8;
        hold += input[_in++] << bits;
        bits += 8;
      }
  
      here = lcode[hold & lmask];
  
      dolen:
      for (;;) { // Goto emulation
        op = here >>> 24/*here.bits*/;
        hold >>>= op;
        bits -= op;
        op = (here >>> 16) & 0xff/*here.op*/;
        if (op === 0) {                          /* literal */
          //Tracevv((stderr, here.val >= 0x20 && here.val < 0x7f ?
          //        "inflate:         literal '%c'\n" :
          //        "inflate:         literal 0x%02x\n", here.val));
          output[_out++] = here & 0xffff/*here.val*/;
        }
        else if (op & 16) {                     /* length base */
          len = here & 0xffff/*here.val*/;
          op &= 15;                           /* number of extra bits */
          if (op) {
            if (bits < op) {
              hold += input[_in++] << bits;
              bits += 8;
            }
            len += hold & ((1 << op) - 1);
            hold >>>= op;
            bits -= op;
          }
          //Tracevv((stderr, "inflate:         length %u\n", len));
          if (bits < 15) {
            hold += input[_in++] << bits;
            bits += 8;
            hold += input[_in++] << bits;
            bits += 8;
          }
          here = dcode[hold & dmask];
  
          dodist:
          for (;;) { // goto emulation
            op = here >>> 24/*here.bits*/;
            hold >>>= op;
            bits -= op;
            op = (here >>> 16) & 0xff/*here.op*/;
  
            if (op & 16) {                      /* distance base */
              dist = here & 0xffff/*here.val*/;
              op &= 15;                       /* number of extra bits */
              if (bits < op) {
                hold += input[_in++] << bits;
                bits += 8;
                if (bits < op) {
                  hold += input[_in++] << bits;
                  bits += 8;
                }
              }
              dist += hold & ((1 << op) - 1);
  //#ifdef INFLATE_STRICT
              if (dist > dmax) {
                strm.msg = 'invalid distance too far back';
                state.mode = BAD;
                break top;
              }
  //#endif
              hold >>>= op;
              bits -= op;
              //Tracevv((stderr, "inflate:         distance %u\n", dist));
              op = _out - beg;                /* max distance in output */
              if (dist > op) {                /* see if copy from window */
                op = dist - op;               /* distance back in window */
                if (op > whave) {
                  if (state.sane) {
                    strm.msg = 'invalid distance too far back';
                    state.mode = BAD;
                    break top;
                  }
  
  // (!) This block is disabled in zlib defailts,
  // don't enable it for binary compatibility
  //#ifdef INFLATE_ALLOW_INVALID_DISTANCE_TOOFAR_ARRR
  //                if (len <= op - whave) {
  //                  do {
  //                    output[_out++] = 0;
  //                  } while (--len);
  //                  continue top;
  //                }
  //                len -= op - whave;
  //                do {
  //                  output[_out++] = 0;
  //                } while (--op > whave);
  //                if (op === 0) {
  //                  from = _out - dist;
  //                  do {
  //                    output[_out++] = output[from++];
  //                  } while (--len);
  //                  continue top;
  //                }
  //#endif
                }
                from = 0; // window index
                from_source = s_window;
                if (wnext === 0) {           /* very common case */
                  from += wsize - op;
                  if (op < len) {         /* some from window */
                    len -= op;
                    do {
                      output[_out++] = s_window[from++];
                    } while (--op);
                    from = _out - dist;  /* rest from output */
                    from_source = output;
                  }
                }
                else if (wnext < op) {      /* wrap around window */
                  from += wsize + wnext - op;
                  op -= wnext;
                  if (op < len) {         /* some from end of window */
                    len -= op;
                    do {
                      output[_out++] = s_window[from++];
                    } while (--op);
                    from = 0;
                    if (wnext < len) {  /* some from start of window */
                      op = wnext;
                      len -= op;
                      do {
                        output[_out++] = s_window[from++];
                      } while (--op);
                      from = _out - dist;      /* rest from output */
                      from_source = output;
                    }
                  }
                }
                else {                      /* contiguous in window */
                  from += wnext - op;
                  if (op < len) {         /* some from window */
                    len -= op;
                    do {
                      output[_out++] = s_window[from++];
                    } while (--op);
                    from = _out - dist;  /* rest from output */
                    from_source = output;
                  }
                }
                while (len > 2) {
                  output[_out++] = from_source[from++];
                  output[_out++] = from_source[from++];
                  output[_out++] = from_source[from++];
                  len -= 3;
                }
                if (len) {
                  output[_out++] = from_source[from++];
                  if (len > 1) {
                    output[_out++] = from_source[from++];
                  }
                }
              }
              else {
                from = _out - dist;          /* copy direct from output */
                do {                        /* minimum length is three */
                  output[_out++] = output[from++];
                  output[_out++] = output[from++];
                  output[_out++] = output[from++];
                  len -= 3;
                } while (len > 2);
                if (len) {
                  output[_out++] = output[from++];
                  if (len > 1) {
                    output[_out++] = output[from++];
                  }
                }
              }
            }
            else if ((op & 64) === 0) {          /* 2nd level distance code */
              here = dcode[(here & 0xffff)/*here.val*/ + (hold & ((1 << op) - 1))];
              continue dodist;
            }
            else {
              strm.msg = 'invalid distance code';
              state.mode = BAD;
              break top;
            }
  
            break; // need to emulate goto via "continue"
          }
        }
        else if ((op & 64) === 0) {              /* 2nd level length code */
          here = lcode[(here & 0xffff)/*here.val*/ + (hold & ((1 << op) - 1))];
          continue dolen;
        }
        else if (op & 32) {                     /* end-of-block */
          //Tracevv((stderr, "inflate:         end of block\n"));
          state.mode = TYPE;
          break top;
        }
        else {
          strm.msg = 'invalid literal/length code';
          state.mode = BAD;
          break top;
        }
  
        break; // need to emulate goto via "continue"
      }
    } while (_in < last && _out < end);
  
    /* return unused bytes (on entry, bits < 8, so in won't go too far back) */
    len = bits >> 3;
    _in -= len;
    bits -= len << 3;
    hold &= (1 << bits) - 1;
  
    /* update state and return */
    strm.next_in = _in;
    strm.next_out = _out;
    strm.avail_in = (_in < last ? 5 + (last - _in) : 5 - (_in - last));
    strm.avail_out = (_out < end ? 257 + (end - _out) : 257 - (_out - end));
    state.hold = hold;
    state.bits = bits;
    return;
  };
  
  },{}],9:[function(require,module,exports){
  'use strict';
  
  
  var utils         = require('../utils/common');
  var adler32       = require('./adler32');
  var crc32         = require('./crc32');
  var inflate_fast  = require('./inffast');
  var inflate_table = require('./inftrees');
  
  var CODES = 0;
  var LENS = 1;
  var DISTS = 2;
  
  /* Public constants ==========================================================*/
  /* ===========================================================================*/
  
  
  /* Allowed flush values; see deflate() and inflate() below for details */
  //var Z_NO_FLUSH      = 0;
  //var Z_PARTIAL_FLUSH = 1;
  //var Z_SYNC_FLUSH    = 2;
  //var Z_FULL_FLUSH    = 3;
  var Z_FINISH        = 4;
  var Z_BLOCK         = 5;
  var Z_TREES         = 6;
  
  
  /* Return codes for the compression/decompression functions. Negative values
    * are errors, positive values are used for special but normal events.
    */
  var Z_OK            = 0;
  var Z_STREAM_END    = 1;
  var Z_NEED_DICT     = 2;
  //var Z_ERRNO         = -1;
  var Z_STREAM_ERROR  = -2;
  var Z_DATA_ERROR    = -3;
  var Z_MEM_ERROR     = -4;
  var Z_BUF_ERROR     = -5;
  //var Z_VERSION_ERROR = -6;
  
  /* The deflate compression method */
  var Z_DEFLATED  = 8;
  
  
  /* STATES ====================================================================*/
  /* ===========================================================================*/
  
  
  var    HEAD = 1;       /* i: waiting for magic header */
  var    FLAGS = 2;      /* i: waiting for method and flags (gzip) */
  var    TIME = 3;       /* i: waiting for modification time (gzip) */
  var    OS = 4;         /* i: waiting for extra flags and operating system (gzip) */
  var    EXLEN = 5;      /* i: waiting for extra length (gzip) */
  var    EXTRA = 6;      /* i: waiting for extra bytes (gzip) */
  var    NAME = 7;       /* i: waiting for end of file name (gzip) */
  var    COMMENT = 8;    /* i: waiting for end of comment (gzip) */
  var    HCRC = 9;       /* i: waiting for header crc (gzip) */
  var    DICTID = 10;    /* i: waiting for dictionary check value */
  var    DICT = 11;      /* waiting for inflateSetDictionary() call */
  var        TYPE = 12;      /* i: waiting for type bits, including last-flag bit */
  var        TYPEDO = 13;    /* i: same, but skip check to exit inflate on new block */
  var        STORED = 14;    /* i: waiting for stored size (length and complement) */
  var        COPY_ = 15;     /* i/o: same as COPY below, but only first time in */
  var        COPY = 16;      /* i/o: waiting for input or output to copy stored block */
  var        TABLE = 17;     /* i: waiting for dynamic block table lengths */
  var        LENLENS = 18;   /* i: waiting for code length code lengths */
  var        CODELENS = 19;  /* i: waiting for length/lit and distance code lengths */
  var            LEN_ = 20;      /* i: same as LEN below, but only first time in */
  var            LEN = 21;       /* i: waiting for length/lit/eob code */
  var            LENEXT = 22;    /* i: waiting for length extra bits */
  var            DIST = 23;      /* i: waiting for distance code */
  var            DISTEXT = 24;   /* i: waiting for distance extra bits */
  var            MATCH = 25;     /* o: waiting for output space to copy string */
  var            LIT = 26;       /* o: waiting for output space to write literal */
  var    CHECK = 27;     /* i: waiting for 32-bit check value */
  var    LENGTH = 28;    /* i: waiting for 32-bit length (gzip) */
  var    DONE = 29;      /* finished check, done -- remain here until reset */
  var    BAD = 30;       /* got a data error -- remain here until reset */
  var    MEM = 31;       /* got an inflate() memory error -- remain here until reset */
  var    SYNC = 32;      /* looking for synchronization bytes to restart inflate() */
  
  /* ===========================================================================*/
  
  
  
  var ENOUGH_LENS = 852;
  var ENOUGH_DISTS = 592;
  //var ENOUGH =  (ENOUGH_LENS+ENOUGH_DISTS);
  
  var MAX_WBITS = 15;
  /* 32K LZ77 window */
  var DEF_WBITS = MAX_WBITS;
  
  
  function zswap32(q) {
    return  (((q >>> 24) & 0xff) +
            ((q >>> 8) & 0xff00) +
            ((q & 0xff00) << 8) +
            ((q & 0xff) << 24));
  }
  
  
  function InflateState() {
    this.mode = 0;             /* current inflate mode */
    this.last = false;          /* true if processing last block */
    this.wrap = 0;              /* bit 0 true for zlib, bit 1 true for gzip */
    this.havedict = false;      /* true if dictionary provided */
    this.flags = 0;             /* gzip header method and flags (0 if zlib) */
    this.dmax = 0;              /* zlib header max distance (INFLATE_STRICT) */
    this.check = 0;             /* protected copy of check value */
    this.total = 0;             /* protected copy of output count */
    // TODO: may be {}
    this.head = null;           /* where to save gzip header information */
  
    /* sliding window */
    this.wbits = 0;             /* log base 2 of requested window size */
    this.wsize = 0;             /* window size or zero if not using window */
    this.whave = 0;             /* valid bytes in the window */
    this.wnext = 0;             /* window write index */
    this.window = null;         /* allocated sliding window, if needed */
  
    /* bit accumulator */
    this.hold = 0;              /* input bit accumulator */
    this.bits = 0;              /* number of bits in "in" */
  
    /* for string and stored block copying */
    this.length = 0;            /* literal or length of data to copy */
    this.offset = 0;            /* distance back to copy string from */
  
    /* for table and code decoding */
    this.extra = 0;             /* extra bits needed */
  
    /* fixed and dynamic code tables */
    this.lencode = null;          /* starting table for length/literal codes */
    this.distcode = null;         /* starting table for distance codes */
    this.lenbits = 0;           /* index bits for lencode */
    this.distbits = 0;          /* index bits for distcode */
  
    /* dynamic table building */
    this.ncode = 0;             /* number of code length code lengths */
    this.nlen = 0;              /* number of length code lengths */
    this.ndist = 0;             /* number of distance code lengths */
    this.have = 0;              /* number of code lengths in lens[] */
    this.next = null;              /* next available space in codes[] */
  
    this.lens = new utils.Buf16(320); /* temporary storage for code lengths */
    this.work = new utils.Buf16(288); /* work area for code table building */
  
    /*
      because we don't have pointers in js, we use lencode and distcode directly
      as buffers so we don't need codes
    */
    //this.codes = new utils.Buf32(ENOUGH);       /* space for code tables */
    this.lendyn = null;              /* dynamic table for length/literal codes (JS specific) */
    this.distdyn = null;             /* dynamic table for distance codes (JS specific) */
    this.sane = 0;                   /* if false, allow invalid distance too far */
    this.back = 0;                   /* bits back of last unprocessed length/lit */
    this.was = 0;                    /* initial length of match */
  }
  
  function inflateResetKeep(strm) {
    var state;
  
    if (!strm || !strm.state) { return Z_STREAM_ERROR; }
    state = strm.state;
    strm.total_in = strm.total_out = state.total = 0;
    strm.msg = ''; /*Z_NULL*/
    if (state.wrap) {       /* to support ill-conceived Java test suite */
      strm.adler = state.wrap & 1;
    }
    state.mode = HEAD;
    state.last = 0;
    state.havedict = 0;
    state.dmax = 32768;
    state.head = null/*Z_NULL*/;
    state.hold = 0;
    state.bits = 0;
    //state.lencode = state.distcode = state.next = state.codes;
    state.lencode = state.lendyn = new utils.Buf32(ENOUGH_LENS);
    state.distcode = state.distdyn = new utils.Buf32(ENOUGH_DISTS);
  
    state.sane = 1;
    state.back = -1;
    //Tracev((stderr, "inflate: reset\n"));
    return Z_OK;
  }
  
  function inflateReset(strm) {
    var state;
  
    if (!strm || !strm.state) { return Z_STREAM_ERROR; }
    state = strm.state;
    state.wsize = 0;
    state.whave = 0;
    state.wnext = 0;
    return inflateResetKeep(strm);
  
  }
  
  function inflateReset2(strm, windowBits) {
    var wrap;
    var state;
  
    /* get the state */
    if (!strm || !strm.state) { return Z_STREAM_ERROR; }
    state = strm.state;
  
    /* extract wrap request from windowBits parameter */
    if (windowBits < 0) {
      wrap = 0;
      windowBits = -windowBits;
    }
    else {
      wrap = (windowBits >> 4) + 1;
      if (windowBits < 48) {
        windowBits &= 15;
      }
    }
  
    /* set number of window bits, free window if different */
    if (windowBits && (windowBits < 8 || windowBits > 15)) {
      return Z_STREAM_ERROR;
    }
    if (state.window !== null && state.wbits !== windowBits) {
      state.window = null;
    }
  
    /* update state and reset the rest of it */
    state.wrap = wrap;
    state.wbits = windowBits;
    return inflateReset(strm);
  }
  
  function inflateInit2(strm, windowBits) {
    var ret;
    var state;
  
    if (!strm) { return Z_STREAM_ERROR; }
    //strm.msg = Z_NULL;                 /* in case we return an error */
  
    state = new InflateState();
  
    //if (state === Z_NULL) return Z_MEM_ERROR;
    //Tracev((stderr, "inflate: allocated\n"));
    strm.state = state;
    state.window = null/*Z_NULL*/;
    ret = inflateReset2(strm, windowBits);
    if (ret !== Z_OK) {
      strm.state = null/*Z_NULL*/;
    }
    return ret;
  }
  
  function inflateInit(strm) {
    return inflateInit2(strm, DEF_WBITS);
  }
  
  
  /*
    Return state with length and distance decoding tables and index sizes set to
    fixed code decoding.  Normally this returns fixed tables from inffixed.h.
    If BUILDFIXED is defined, then instead this routine builds the tables the
    first time it's called, and returns those tables the first time and
    thereafter.  This reduces the size of the code by about 2K bytes, in
    exchange for a little execution time.  However, BUILDFIXED should not be
    used for threaded applications, since the rewriting of the tables and virgin
    may not be thread-safe.
    */
  var virgin = true;
  
  var lenfix, distfix; // We have no pointers in JS, so keep tables separate
  
  function fixedtables(state) {
    /* build fixed huffman tables if first call (may not be thread safe) */
    if (virgin) {
      var sym;
  
      lenfix = new utils.Buf32(512);
      distfix = new utils.Buf32(32);
  
      /* literal/length table */
      sym = 0;
      while (sym < 144) { state.lens[sym++] = 8; }
      while (sym < 256) { state.lens[sym++] = 9; }
      while (sym < 280) { state.lens[sym++] = 7; }
      while (sym < 288) { state.lens[sym++] = 8; }
  
      inflate_table(LENS,  state.lens, 0, 288, lenfix,   0, state.work, { bits: 9 });
  
      /* distance table */
      sym = 0;
      while (sym < 32) { state.lens[sym++] = 5; }
  
      inflate_table(DISTS, state.lens, 0, 32,   distfix, 0, state.work, { bits: 5 });
  
      /* do this just once */
      virgin = false;
    }
  
    state.lencode = lenfix;
    state.lenbits = 9;
    state.distcode = distfix;
    state.distbits = 5;
  }
  
  
  /*
    Update the window with the last wsize (normally 32K) bytes written before
    returning.  If window does not exist yet, create it.  This is only called
    when a window is already in use, or when output has been written during this
    inflate call, but the end of the deflate stream has not been reached yet.
    It is also called to create a window for dictionary data when a dictionary
    is loaded.
  
    Providing output buffers larger than 32K to inflate() should provide a speed
    advantage, since only the last 32K of output is copied to the sliding window
    upon return from inflate(), and since all distances after the first 32K of
    output will fall in the output data, making match copies simpler and faster.
    The advantage may be dependent on the size of the processor's data caches.
    */
  function updatewindow(strm, src, end, copy) {
    var dist;
    var state = strm.state;
  
    /* if it hasn't been done already, allocate space for the window */
    if (state.window === null) {
      state.wsize = 1 << state.wbits;
      state.wnext = 0;
      state.whave = 0;
  
      state.window = new utils.Buf8(state.wsize);
    }
  
    /* copy state->wsize or less output bytes into the circular window */
    if (copy >= state.wsize) {
      utils.arraySet(state.window, src, end - state.wsize, state.wsize, 0);
      state.wnext = 0;
      state.whave = state.wsize;
    }
    else {
      dist = state.wsize - state.wnext;
      if (dist > copy) {
        dist = copy;
      }
      //zmemcpy(state->window + state->wnext, end - copy, dist);
      utils.arraySet(state.window, src, end - copy, dist, state.wnext);
      copy -= dist;
      if (copy) {
        //zmemcpy(state->window, end - copy, copy);
        utils.arraySet(state.window, src, end - copy, copy, 0);
        state.wnext = copy;
        state.whave = state.wsize;
      }
      else {
        state.wnext += dist;
        if (state.wnext === state.wsize) { state.wnext = 0; }
        if (state.whave < state.wsize) { state.whave += dist; }
      }
    }
    return 0;
  }
  
  function inflate(strm, flush) {
    var state;
    var input, output;          // input/output buffers
    var next;                   /* next input INDEX */
    var put;                    /* next output INDEX */
    var have, left;             /* available input and output */
    var hold;                   /* bit buffer */
    var bits;                   /* bits in bit buffer */
    var _in, _out;              /* save starting available input and output */
    var copy;                   /* number of stored or match bytes to copy */
    var from;                   /* where to copy match bytes from */
    var from_source;
    var here = 0;               /* current decoding table entry */
    var here_bits, here_op, here_val; // paked "here" denormalized (JS specific)
    //var last;                   /* parent table entry */
    var last_bits, last_op, last_val; // paked "last" denormalized (JS specific)
    var len;                    /* length to copy for repeats, bits to drop */
    var ret;                    /* return code */
    var hbuf = new utils.Buf8(4);    /* buffer for gzip header crc calculation */
    var opts;
  
    var n; // temporary var for NEED_BITS
  
    var order = /* permutation of code lengths */
      [ 16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15 ];
  
  
    if (!strm || !strm.state || !strm.output ||
        (!strm.input && strm.avail_in !== 0)) {
      return Z_STREAM_ERROR;
    }
  
    state = strm.state;
    if (state.mode === TYPE) { state.mode = TYPEDO; }    /* skip check */
  
  
    //--- LOAD() ---
    put = strm.next_out;
    output = strm.output;
    left = strm.avail_out;
    next = strm.next_in;
    input = strm.input;
    have = strm.avail_in;
    hold = state.hold;
    bits = state.bits;
    //---
  
    _in = have;
    _out = left;
    ret = Z_OK;
  
    inf_leave: // goto emulation
    for (;;) {
      switch (state.mode) {
      case HEAD:
        if (state.wrap === 0) {
          state.mode = TYPEDO;
          break;
        }
        //=== NEEDBITS(16);
        while (bits < 16) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        if ((state.wrap & 2) && hold === 0x8b1f) {  /* gzip header */
          state.check = 0/*crc32(0L, Z_NULL, 0)*/;
          //=== CRC2(state.check, hold);
          hbuf[0] = hold & 0xff;
          hbuf[1] = (hold >>> 8) & 0xff;
          state.check = crc32(state.check, hbuf, 2, 0);
          //===//
  
          //=== INITBITS();
          hold = 0;
          bits = 0;
          //===//
          state.mode = FLAGS;
          break;
        }
        state.flags = 0;           /* expect zlib header */
        if (state.head) {
          state.head.done = false;
        }
        if (!(state.wrap & 1) ||   /* check if zlib header allowed */
          (((hold & 0xff)/*BITS(8)*/ << 8) + (hold >> 8)) % 31) {
          strm.msg = 'incorrect header check';
          state.mode = BAD;
          break;
        }
        if ((hold & 0x0f)/*BITS(4)*/ !== Z_DEFLATED) {
          strm.msg = 'unknown compression method';
          state.mode = BAD;
          break;
        }
        //--- DROPBITS(4) ---//
        hold >>>= 4;
        bits -= 4;
        //---//
        len = (hold & 0x0f)/*BITS(4)*/ + 8;
        if (state.wbits === 0) {
          state.wbits = len;
        }
        else if (len > state.wbits) {
          strm.msg = 'invalid window size';
          state.mode = BAD;
          break;
        }
        state.dmax = 1 << len;
        //Tracev((stderr, "inflate:   zlib header ok\n"));
        strm.adler = state.check = 1/*adler32(0L, Z_NULL, 0)*/;
        state.mode = hold & 0x200 ? DICTID : TYPE;
        //=== INITBITS();
        hold = 0;
        bits = 0;
        //===//
        break;
      case FLAGS:
        //=== NEEDBITS(16); */
        while (bits < 16) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        state.flags = hold;
        if ((state.flags & 0xff) !== Z_DEFLATED) {
          strm.msg = 'unknown compression method';
          state.mode = BAD;
          break;
        }
        if (state.flags & 0xe000) {
          strm.msg = 'unknown header flags set';
          state.mode = BAD;
          break;
        }
        if (state.head) {
          state.head.text = ((hold >> 8) & 1);
        }
        if (state.flags & 0x0200) {
          //=== CRC2(state.check, hold);
          hbuf[0] = hold & 0xff;
          hbuf[1] = (hold >>> 8) & 0xff;
          state.check = crc32(state.check, hbuf, 2, 0);
          //===//
        }
        //=== INITBITS();
        hold = 0;
        bits = 0;
        //===//
        state.mode = TIME;
        /* falls through */
      case TIME:
        //=== NEEDBITS(32); */
        while (bits < 32) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        if (state.head) {
          state.head.time = hold;
        }
        if (state.flags & 0x0200) {
          //=== CRC4(state.check, hold)
          hbuf[0] = hold & 0xff;
          hbuf[1] = (hold >>> 8) & 0xff;
          hbuf[2] = (hold >>> 16) & 0xff;
          hbuf[3] = (hold >>> 24) & 0xff;
          state.check = crc32(state.check, hbuf, 4, 0);
          //===
        }
        //=== INITBITS();
        hold = 0;
        bits = 0;
        //===//
        state.mode = OS;
        /* falls through */
      case OS:
        //=== NEEDBITS(16); */
        while (bits < 16) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        if (state.head) {
          state.head.xflags = (hold & 0xff);
          state.head.os = (hold >> 8);
        }
        if (state.flags & 0x0200) {
          //=== CRC2(state.check, hold);
          hbuf[0] = hold & 0xff;
          hbuf[1] = (hold >>> 8) & 0xff;
          state.check = crc32(state.check, hbuf, 2, 0);
          //===//
        }
        //=== INITBITS();
        hold = 0;
        bits = 0;
        //===//
        state.mode = EXLEN;
        /* falls through */
      case EXLEN:
        if (state.flags & 0x0400) {
          //=== NEEDBITS(16); */
          while (bits < 16) {
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
          }
          //===//
          state.length = hold;
          if (state.head) {
            state.head.extra_len = hold;
          }
          if (state.flags & 0x0200) {
            //=== CRC2(state.check, hold);
            hbuf[0] = hold & 0xff;
            hbuf[1] = (hold >>> 8) & 0xff;
            state.check = crc32(state.check, hbuf, 2, 0);
            //===//
          }
          //=== INITBITS();
          hold = 0;
          bits = 0;
          //===//
        }
        else if (state.head) {
          state.head.extra = null/*Z_NULL*/;
        }
        state.mode = EXTRA;
        /* falls through */
      case EXTRA:
        if (state.flags & 0x0400) {
          copy = state.length;
          if (copy > have) { copy = have; }
          if (copy) {
            if (state.head) {
              len = state.head.extra_len - state.length;
              if (!state.head.extra) {
                // Use untyped array for more conveniend processing later
                state.head.extra = new Array(state.head.extra_len);
              }
              utils.arraySet(
                state.head.extra,
                input,
                next,
                // extra field is limited to 65536 bytes
                // - no need for additional size check
                copy,
                /*len + copy > state.head.extra_max - len ? state.head.extra_max : copy,*/
                len
              );
              //zmemcpy(state.head.extra + len, next,
              //        len + copy > state.head.extra_max ?
              //        state.head.extra_max - len : copy);
            }
            if (state.flags & 0x0200) {
              state.check = crc32(state.check, input, copy, next);
            }
            have -= copy;
            next += copy;
            state.length -= copy;
          }
          if (state.length) { break inf_leave; }
        }
        state.length = 0;
        state.mode = NAME;
        /* falls through */
      case NAME:
        if (state.flags & 0x0800) {
          if (have === 0) { break inf_leave; }
          copy = 0;
          do {
            // TODO: 2 or 1 bytes?
            len = input[next + copy++];
            /* use constant limit because in js we should not preallocate memory */
            if (state.head && len &&
                (state.length < 65536 /*state.head.name_max*/)) {
              state.head.name += String.fromCharCode(len);
            }
          } while (len && copy < have);
  
          if (state.flags & 0x0200) {
            state.check = crc32(state.check, input, copy, next);
          }
          have -= copy;
          next += copy;
          if (len) { break inf_leave; }
        }
        else if (state.head) {
          state.head.name = null;
        }
        state.length = 0;
        state.mode = COMMENT;
        /* falls through */
      case COMMENT:
        if (state.flags & 0x1000) {
          if (have === 0) { break inf_leave; }
          copy = 0;
          do {
            len = input[next + copy++];
            /* use constant limit because in js we should not preallocate memory */
            if (state.head && len &&
                (state.length < 65536 /*state.head.comm_max*/)) {
              state.head.comment += String.fromCharCode(len);
            }
          } while (len && copy < have);
          if (state.flags & 0x0200) {
            state.check = crc32(state.check, input, copy, next);
          }
          have -= copy;
          next += copy;
          if (len) { break inf_leave; }
        }
        else if (state.head) {
          state.head.comment = null;
        }
        state.mode = HCRC;
        /* falls through */
      case HCRC:
        if (state.flags & 0x0200) {
          //=== NEEDBITS(16); */
          while (bits < 16) {
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
          }
          //===//
          if (hold !== (state.check & 0xffff)) {
            strm.msg = 'header crc mismatch';
            state.mode = BAD;
            break;
          }
          //=== INITBITS();
          hold = 0;
          bits = 0;
          //===//
        }
        if (state.head) {
          state.head.hcrc = ((state.flags >> 9) & 1);
          state.head.done = true;
        }
        strm.adler = state.check = 0;
        state.mode = TYPE;
        break;
      case DICTID:
        //=== NEEDBITS(32); */
        while (bits < 32) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        strm.adler = state.check = zswap32(hold);
        //=== INITBITS();
        hold = 0;
        bits = 0;
        //===//
        state.mode = DICT;
        /* falls through */
      case DICT:
        if (state.havedict === 0) {
          //--- RESTORE() ---
          strm.next_out = put;
          strm.avail_out = left;
          strm.next_in = next;
          strm.avail_in = have;
          state.hold = hold;
          state.bits = bits;
          //---
          return Z_NEED_DICT;
        }
        strm.adler = state.check = 1/*adler32(0L, Z_NULL, 0)*/;
        state.mode = TYPE;
        /* falls through */
      case TYPE:
        if (flush === Z_BLOCK || flush === Z_TREES) { break inf_leave; }
        /* falls through */
      case TYPEDO:
        if (state.last) {
          //--- BYTEBITS() ---//
          hold >>>= bits & 7;
          bits -= bits & 7;
          //---//
          state.mode = CHECK;
          break;
        }
        //=== NEEDBITS(3); */
        while (bits < 3) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        state.last = (hold & 0x01)/*BITS(1)*/;
        //--- DROPBITS(1) ---//
        hold >>>= 1;
        bits -= 1;
        //---//
  
        switch ((hold & 0x03)/*BITS(2)*/) {
        case 0:                             /* stored block */
          //Tracev((stderr, "inflate:     stored block%s\n",
          //        state.last ? " (last)" : ""));
          state.mode = STORED;
          break;
        case 1:                             /* fixed block */
          fixedtables(state);
          //Tracev((stderr, "inflate:     fixed codes block%s\n",
          //        state.last ? " (last)" : ""));
          state.mode = LEN_;             /* decode codes */
          if (flush === Z_TREES) {
            //--- DROPBITS(2) ---//
            hold >>>= 2;
            bits -= 2;
            //---//
            break inf_leave;
          }
          break;
        case 2:                             /* dynamic block */
          //Tracev((stderr, "inflate:     dynamic codes block%s\n",
          //        state.last ? " (last)" : ""));
          state.mode = TABLE;
          break;
        case 3:
          strm.msg = 'invalid block type';
          state.mode = BAD;
        }
        //--- DROPBITS(2) ---//
        hold >>>= 2;
        bits -= 2;
        //---//
        break;
      case STORED:
        //--- BYTEBITS() ---// /* go to byte boundary */
        hold >>>= bits & 7;
        bits -= bits & 7;
        //---//
        //=== NEEDBITS(32); */
        while (bits < 32) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        if ((hold & 0xffff) !== ((hold >>> 16) ^ 0xffff)) {
          strm.msg = 'invalid stored block lengths';
          state.mode = BAD;
          break;
        }
        state.length = hold & 0xffff;
        //Tracev((stderr, "inflate:       stored length %u\n",
        //        state.length));
        //=== INITBITS();
        hold = 0;
        bits = 0;
        //===//
        state.mode = COPY_;
        if (flush === Z_TREES) { break inf_leave; }
        /* falls through */
      case COPY_:
        state.mode = COPY;
        /* falls through */
      case COPY:
        copy = state.length;
        if (copy) {
          if (copy > have) { copy = have; }
          if (copy > left) { copy = left; }
          if (copy === 0) { break inf_leave; }
          //--- zmemcpy(put, next, copy); ---
          utils.arraySet(output, input, next, copy, put);
          //---//
          have -= copy;
          next += copy;
          left -= copy;
          put += copy;
          state.length -= copy;
          break;
        }
        //Tracev((stderr, "inflate:       stored end\n"));
        state.mode = TYPE;
        break;
      case TABLE:
        //=== NEEDBITS(14); */
        while (bits < 14) {
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
        }
        //===//
        state.nlen = (hold & 0x1f)/*BITS(5)*/ + 257;
        //--- DROPBITS(5) ---//
        hold >>>= 5;
        bits -= 5;
        //---//
        state.ndist = (hold & 0x1f)/*BITS(5)*/ + 1;
        //--- DROPBITS(5) ---//
        hold >>>= 5;
        bits -= 5;
        //---//
        state.ncode = (hold & 0x0f)/*BITS(4)*/ + 4;
        //--- DROPBITS(4) ---//
        hold >>>= 4;
        bits -= 4;
        //---//
  //#ifndef PKZIP_BUG_WORKAROUND
        if (state.nlen > 286 || state.ndist > 30) {
          strm.msg = 'too many length or distance symbols';
          state.mode = BAD;
          break;
        }
  //#endif
        //Tracev((stderr, "inflate:       table sizes ok\n"));
        state.have = 0;
        state.mode = LENLENS;
        /* falls through */
      case LENLENS:
        while (state.have < state.ncode) {
          //=== NEEDBITS(3);
          while (bits < 3) {
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
          }
          //===//
          state.lens[order[state.have++]] = (hold & 0x07);//BITS(3);
          //--- DROPBITS(3) ---//
          hold >>>= 3;
          bits -= 3;
          //---//
        }
        while (state.have < 19) {
          state.lens[order[state.have++]] = 0;
        }
        // We have separate tables & no pointers. 2 commented lines below not needed.
        //state.next = state.codes;
        //state.lencode = state.next;
        // Switch to use dynamic table
        state.lencode = state.lendyn;
        state.lenbits = 7;
  
        opts = { bits: state.lenbits };
        ret = inflate_table(CODES, state.lens, 0, 19, state.lencode, 0, state.work, opts);
        state.lenbits = opts.bits;
  
        if (ret) {
          strm.msg = 'invalid code lengths set';
          state.mode = BAD;
          break;
        }
        //Tracev((stderr, "inflate:       code lengths ok\n"));
        state.have = 0;
        state.mode = CODELENS;
        /* falls through */
      case CODELENS:
        while (state.have < state.nlen + state.ndist) {
          for (;;) {
            here = state.lencode[hold & ((1 << state.lenbits) - 1)];/*BITS(state.lenbits)*/
            here_bits = here >>> 24;
            here_op = (here >>> 16) & 0xff;
            here_val = here & 0xffff;
  
            if ((here_bits) <= bits) { break; }
            //--- PULLBYTE() ---//
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
            //---//
          }
          if (here_val < 16) {
            //--- DROPBITS(here.bits) ---//
            hold >>>= here_bits;
            bits -= here_bits;
            //---//
            state.lens[state.have++] = here_val;
          }
          else {
            if (here_val === 16) {
              //=== NEEDBITS(here.bits + 2);
              n = here_bits + 2;
              while (bits < n) {
                if (have === 0) { break inf_leave; }
                have--;
                hold += input[next++] << bits;
                bits += 8;
              }
              //===//
              //--- DROPBITS(here.bits) ---//
              hold >>>= here_bits;
              bits -= here_bits;
              //---//
              if (state.have === 0) {
                strm.msg = 'invalid bit length repeat';
                state.mode = BAD;
                break;
              }
              len = state.lens[state.have - 1];
              copy = 3 + (hold & 0x03);//BITS(2);
              //--- DROPBITS(2) ---//
              hold >>>= 2;
              bits -= 2;
              //---//
            }
            else if (here_val === 17) {
              //=== NEEDBITS(here.bits + 3);
              n = here_bits + 3;
              while (bits < n) {
                if (have === 0) { break inf_leave; }
                have--;
                hold += input[next++] << bits;
                bits += 8;
              }
              //===//
              //--- DROPBITS(here.bits) ---//
              hold >>>= here_bits;
              bits -= here_bits;
              //---//
              len = 0;
              copy = 3 + (hold & 0x07);//BITS(3);
              //--- DROPBITS(3) ---//
              hold >>>= 3;
              bits -= 3;
              //---//
            }
            else {
              //=== NEEDBITS(here.bits + 7);
              n = here_bits + 7;
              while (bits < n) {
                if (have === 0) { break inf_leave; }
                have--;
                hold += input[next++] << bits;
                bits += 8;
              }
              //===//
              //--- DROPBITS(here.bits) ---//
              hold >>>= here_bits;
              bits -= here_bits;
              //---//
              len = 0;
              copy = 11 + (hold & 0x7f);//BITS(7);
              //--- DROPBITS(7) ---//
              hold >>>= 7;
              bits -= 7;
              //---//
            }
            if (state.have + copy > state.nlen + state.ndist) {
              strm.msg = 'invalid bit length repeat';
              state.mode = BAD;
              break;
            }
            while (copy--) {
              state.lens[state.have++] = len;
            }
          }
        }
  
        /* handle error breaks in while */
        if (state.mode === BAD) { break; }
  
        /* check for end-of-block code (better have one) */
        if (state.lens[256] === 0) {
          strm.msg = 'invalid code -- missing end-of-block';
          state.mode = BAD;
          break;
        }
  
        /* build code tables -- note: do not change the lenbits or distbits
            values here (9 and 6) without reading the comments in inftrees.h
            concerning the ENOUGH constants, which depend on those values */
        state.lenbits = 9;
  
        opts = { bits: state.lenbits };
        ret = inflate_table(LENS, state.lens, 0, state.nlen, state.lencode, 0, state.work, opts);
        // We have separate tables & no pointers. 2 commented lines below not needed.
        // state.next_index = opts.table_index;
        state.lenbits = opts.bits;
        // state.lencode = state.next;
  
        if (ret) {
          strm.msg = 'invalid literal/lengths set';
          state.mode = BAD;
          break;
        }
  
        state.distbits = 6;
        //state.distcode.copy(state.codes);
        // Switch to use dynamic table
        state.distcode = state.distdyn;
        opts = { bits: state.distbits };
        ret = inflate_table(DISTS, state.lens, state.nlen, state.ndist, state.distcode, 0, state.work, opts);
        // We have separate tables & no pointers. 2 commented lines below not needed.
        // state.next_index = opts.table_index;
        state.distbits = opts.bits;
        // state.distcode = state.next;
  
        if (ret) {
          strm.msg = 'invalid distances set';
          state.mode = BAD;
          break;
        }
        //Tracev((stderr, 'inflate:       codes ok\n'));
        state.mode = LEN_;
        if (flush === Z_TREES) { break inf_leave; }
        /* falls through */
      case LEN_:
        state.mode = LEN;
        /* falls through */
      case LEN:
        if (have >= 6 && left >= 258) {
          //--- RESTORE() ---
          strm.next_out = put;
          strm.avail_out = left;
          strm.next_in = next;
          strm.avail_in = have;
          state.hold = hold;
          state.bits = bits;
          //---
          inflate_fast(strm, _out);
          //--- LOAD() ---
          put = strm.next_out;
          output = strm.output;
          left = strm.avail_out;
          next = strm.next_in;
          input = strm.input;
          have = strm.avail_in;
          hold = state.hold;
          bits = state.bits;
          //---
  
          if (state.mode === TYPE) {
            state.back = -1;
          }
          break;
        }
        state.back = 0;
        for (;;) {
          here = state.lencode[hold & ((1 << state.lenbits) - 1)];  /*BITS(state.lenbits)*/
          here_bits = here >>> 24;
          here_op = (here >>> 16) & 0xff;
          here_val = here & 0xffff;
  
          if (here_bits <= bits) { break; }
          //--- PULLBYTE() ---//
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
          //---//
        }
        if (here_op && (here_op & 0xf0) === 0) {
          last_bits = here_bits;
          last_op = here_op;
          last_val = here_val;
          for (;;) {
            here = state.lencode[last_val +
                    ((hold & ((1 << (last_bits + last_op)) - 1))/*BITS(last.bits + last.op)*/ >> last_bits)];
            here_bits = here >>> 24;
            here_op = (here >>> 16) & 0xff;
            here_val = here & 0xffff;
  
            if ((last_bits + here_bits) <= bits) { break; }
            //--- PULLBYTE() ---//
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
            //---//
          }
          //--- DROPBITS(last.bits) ---//
          hold >>>= last_bits;
          bits -= last_bits;
          //---//
          state.back += last_bits;
        }
        //--- DROPBITS(here.bits) ---//
        hold >>>= here_bits;
        bits -= here_bits;
        //---//
        state.back += here_bits;
        state.length = here_val;
        if (here_op === 0) {
          //Tracevv((stderr, here.val >= 0x20 && here.val < 0x7f ?
          //        "inflate:         literal '%c'\n" :
          //        "inflate:         literal 0x%02x\n", here.val));
          state.mode = LIT;
          break;
        }
        if (here_op & 32) {
          //Tracevv((stderr, "inflate:         end of block\n"));
          state.back = -1;
          state.mode = TYPE;
          break;
        }
        if (here_op & 64) {
          strm.msg = 'invalid literal/length code';
          state.mode = BAD;
          break;
        }
        state.extra = here_op & 15;
        state.mode = LENEXT;
        /* falls through */
      case LENEXT:
        if (state.extra) {
          //=== NEEDBITS(state.extra);
          n = state.extra;
          while (bits < n) {
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
          }
          //===//
          state.length += hold & ((1 << state.extra) - 1)/*BITS(state.extra)*/;
          //--- DROPBITS(state.extra) ---//
          hold >>>= state.extra;
          bits -= state.extra;
          //---//
          state.back += state.extra;
        }
        //Tracevv((stderr, "inflate:         length %u\n", state.length));
        state.was = state.length;
        state.mode = DIST;
        /* falls through */
      case DIST:
        for (;;) {
          here = state.distcode[hold & ((1 << state.distbits) - 1)];/*BITS(state.distbits)*/
          here_bits = here >>> 24;
          here_op = (here >>> 16) & 0xff;
          here_val = here & 0xffff;
  
          if ((here_bits) <= bits) { break; }
          //--- PULLBYTE() ---//
          if (have === 0) { break inf_leave; }
          have--;
          hold += input[next++] << bits;
          bits += 8;
          //---//
        }
        if ((here_op & 0xf0) === 0) {
          last_bits = here_bits;
          last_op = here_op;
          last_val = here_val;
          for (;;) {
            here = state.distcode[last_val +
                    ((hold & ((1 << (last_bits + last_op)) - 1))/*BITS(last.bits + last.op)*/ >> last_bits)];
            here_bits = here >>> 24;
            here_op = (here >>> 16) & 0xff;
            here_val = here & 0xffff;
  
            if ((last_bits + here_bits) <= bits) { break; }
            //--- PULLBYTE() ---//
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
            //---//
          }
          //--- DROPBITS(last.bits) ---//
          hold >>>= last_bits;
          bits -= last_bits;
          //---//
          state.back += last_bits;
        }
        //--- DROPBITS(here.bits) ---//
        hold >>>= here_bits;
        bits -= here_bits;
        //---//
        state.back += here_bits;
        if (here_op & 64) {
          strm.msg = 'invalid distance code';
          state.mode = BAD;
          break;
        }
        state.offset = here_val;
        state.extra = (here_op) & 15;
        state.mode = DISTEXT;
        /* falls through */
      case DISTEXT:
        if (state.extra) {
          //=== NEEDBITS(state.extra);
          n = state.extra;
          while (bits < n) {
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
          }
          //===//
          state.offset += hold & ((1 << state.extra) - 1)/*BITS(state.extra)*/;
          //--- DROPBITS(state.extra) ---//
          hold >>>= state.extra;
          bits -= state.extra;
          //---//
          state.back += state.extra;
        }
  //#ifdef INFLATE_STRICT
        if (state.offset > state.dmax) {
          strm.msg = 'invalid distance too far back';
          state.mode = BAD;
          break;
        }
  //#endif
        //Tracevv((stderr, "inflate:         distance %u\n", state.offset));
        state.mode = MATCH;
        /* falls through */
      case MATCH:
        if (left === 0) { break inf_leave; }
        copy = _out - left;
        if (state.offset > copy) {         /* copy from window */
          copy = state.offset - copy;
          if (copy > state.whave) {
            if (state.sane) {
              strm.msg = 'invalid distance too far back';
              state.mode = BAD;
              break;
            }
  // (!) This block is disabled in zlib defailts,
  // don't enable it for binary compatibility
  //#ifdef INFLATE_ALLOW_INVALID_DISTANCE_TOOFAR_ARRR
  //          Trace((stderr, "inflate.c too far\n"));
  //          copy -= state.whave;
  //          if (copy > state.length) { copy = state.length; }
  //          if (copy > left) { copy = left; }
  //          left -= copy;
  //          state.length -= copy;
  //          do {
  //            output[put++] = 0;
  //          } while (--copy);
  //          if (state.length === 0) { state.mode = LEN; }
  //          break;
  //#endif
          }
          if (copy > state.wnext) {
            copy -= state.wnext;
            from = state.wsize - copy;
          }
          else {
            from = state.wnext - copy;
          }
          if (copy > state.length) { copy = state.length; }
          from_source = state.window;
        }
        else {                              /* copy from output */
          from_source = output;
          from = put - state.offset;
          copy = state.length;
        }
        if (copy > left) { copy = left; }
        left -= copy;
        state.length -= copy;
        do {
          output[put++] = from_source[from++];
        } while (--copy);
        if (state.length === 0) { state.mode = LEN; }
        break;
      case LIT:
        if (left === 0) { break inf_leave; }
        output[put++] = state.length;
        left--;
        state.mode = LEN;
        break;
      case CHECK:
        if (state.wrap) {
          //=== NEEDBITS(32);
          while (bits < 32) {
            if (have === 0) { break inf_leave; }
            have--;
            // Use '|' insdead of '+' to make sure that result is signed
            hold |= input[next++] << bits;
            bits += 8;
          }
          //===//
          _out -= left;
          strm.total_out += _out;
          state.total += _out;
          if (_out) {
            strm.adler = state.check =
                /*UPDATE(state.check, put - _out, _out);*/
                (state.flags ? crc32(state.check, output, _out, put - _out) : adler32(state.check, output, _out, put - _out));
  
          }
          _out = left;
          // NB: crc32 stored as signed 32-bit int, zswap32 returns signed too
          if ((state.flags ? hold : zswap32(hold)) !== state.check) {
            strm.msg = 'incorrect data check';
            state.mode = BAD;
            break;
          }
          //=== INITBITS();
          hold = 0;
          bits = 0;
          //===//
          //Tracev((stderr, "inflate:   check matches trailer\n"));
        }
        state.mode = LENGTH;
        /* falls through */
      case LENGTH:
        if (state.wrap && state.flags) {
          //=== NEEDBITS(32);
          while (bits < 32) {
            if (have === 0) { break inf_leave; }
            have--;
            hold += input[next++] << bits;
            bits += 8;
          }
          //===//
          if (hold !== (state.total & 0xffffffff)) {
            strm.msg = 'incorrect length check';
            state.mode = BAD;
            break;
          }
          //=== INITBITS();
          hold = 0;
          bits = 0;
          //===//
          //Tracev((stderr, "inflate:   length matches trailer\n"));
        }
        state.mode = DONE;
        /* falls through */
      case DONE:
        ret = Z_STREAM_END;
        break inf_leave;
      case BAD:
        ret = Z_DATA_ERROR;
        break inf_leave;
      case MEM:
        return Z_MEM_ERROR;
      case SYNC:
        /* falls through */
      default:
        return Z_STREAM_ERROR;
      }
    }
  
    // inf_leave <- here is real place for "goto inf_leave", emulated via "break inf_leave"
  
    /*
        Return from inflate(), updating the total counts and the check value.
        If there was no progress during the inflate() call, return a buffer
        error.  Call updatewindow() to create and/or update the window state.
        Note: a memory error from inflate() is non-recoverable.
      */
  
    //--- RESTORE() ---
    strm.next_out = put;
    strm.avail_out = left;
    strm.next_in = next;
    strm.avail_in = have;
    state.hold = hold;
    state.bits = bits;
    //---
  
    if (state.wsize || (_out !== strm.avail_out && state.mode < BAD &&
                        (state.mode < CHECK || flush !== Z_FINISH))) {
      if (updatewindow(strm, strm.output, strm.next_out, _out - strm.avail_out)) {
        state.mode = MEM;
        return Z_MEM_ERROR;
      }
    }
    _in -= strm.avail_in;
    _out -= strm.avail_out;
    strm.total_in += _in;
    strm.total_out += _out;
    state.total += _out;
    if (state.wrap && _out) {
      strm.adler = state.check = /*UPDATE(state.check, strm.next_out - _out, _out);*/
        (state.flags ? crc32(state.check, output, _out, strm.next_out - _out) : adler32(state.check, output, _out, strm.next_out - _out));
    }
    strm.data_type = state.bits + (state.last ? 64 : 0) +
                      (state.mode === TYPE ? 128 : 0) +
                      (state.mode === LEN_ || state.mode === COPY_ ? 256 : 0);
    if (((_in === 0 && _out === 0) || flush === Z_FINISH) && ret === Z_OK) {
      ret = Z_BUF_ERROR;
    }
    return ret;
  }
  
  function inflateEnd(strm) {
  
    if (!strm || !strm.state /*|| strm->zfree == (free_func)0*/) {
      return Z_STREAM_ERROR;
    }
  
    var state = strm.state;
    if (state.window) {
      state.window = null;
    }
    strm.state = null;
    return Z_OK;
  }
  
  function inflateGetHeader(strm, head) {
    var state;
  
    /* check state */
    if (!strm || !strm.state) { return Z_STREAM_ERROR; }
    state = strm.state;
    if ((state.wrap & 2) === 0) { return Z_STREAM_ERROR; }
  
    /* save header structure */
    state.head = head;
    head.done = false;
    return Z_OK;
  }
  
  function inflateSetDictionary(strm, dictionary) {
    var dictLength = dictionary.length;
  
    var state;
    var dictid;
    var ret;
  
    /* check state */
    if (!strm /* == Z_NULL */ || !strm.state /* == Z_NULL */) { return Z_STREAM_ERROR; }
    state = strm.state;
  
    if (state.wrap !== 0 && state.mode !== DICT) {
      return Z_STREAM_ERROR;
    }
  
    /* check for correct dictionary identifier */
    if (state.mode === DICT) {
      dictid = 1; /* adler32(0, null, 0)*/
      /* dictid = adler32(dictid, dictionary, dictLength); */
      dictid = adler32(dictid, dictionary, dictLength, 0);
      if (dictid !== state.check) {
        return Z_DATA_ERROR;
      }
    }
    /* copy dictionary to window using updatewindow(), which will amend the
      existing dictionary if appropriate */
    ret = updatewindow(strm, dictionary, dictLength, dictLength);
    if (ret) {
      state.mode = MEM;
      return Z_MEM_ERROR;
    }
    state.havedict = 1;
    // Tracev((stderr, "inflate:   dictionary set\n"));
    return Z_OK;
  }
  
  exports.inflateReset = inflateReset;
  exports.inflateReset2 = inflateReset2;
  exports.inflateResetKeep = inflateResetKeep;
  exports.inflateInit = inflateInit;
  exports.inflateInit2 = inflateInit2;
  exports.inflate = inflate;
  exports.inflateEnd = inflateEnd;
  exports.inflateGetHeader = inflateGetHeader;
  exports.inflateSetDictionary = inflateSetDictionary;
  exports.inflateInfo = 'pako inflate (from Nodeca project)';
  
  /* Not implemented
  exports.inflateCopy = inflateCopy;
  exports.inflateGetDictionary = inflateGetDictionary;
  exports.inflateMark = inflateMark;
  exports.inflatePrime = inflatePrime;
  exports.inflateSync = inflateSync;
  exports.inflateSyncPoint = inflateSyncPoint;
  exports.inflateUndermine = inflateUndermine;
  */
  
  },{"../utils/common":2,"./adler32":4,"./crc32":6,"./inffast":8,"./inftrees":10}],10:[function(require,module,exports){
  'use strict';
  
  
  var utils = require('../utils/common');
  
  var MAXBITS = 15;
  var ENOUGH_LENS = 852;
  var ENOUGH_DISTS = 592;
  //var ENOUGH = (ENOUGH_LENS+ENOUGH_DISTS);
  
  var CODES = 0;
  var LENS = 1;
  var DISTS = 2;
  
  var lbase = [ /* Length codes 257..285 base */
    3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19, 23, 27, 31,
    35, 43, 51, 59, 67, 83, 99, 115, 131, 163, 195, 227, 258, 0, 0
  ];
  
  var lext = [ /* Length codes 257..285 extra */
    16, 16, 16, 16, 16, 16, 16, 16, 17, 17, 17, 17, 18, 18, 18, 18,
    19, 19, 19, 19, 20, 20, 20, 20, 21, 21, 21, 21, 16, 72, 78
  ];
  
  var dbase = [ /* Distance codes 0..29 base */
    1, 2, 3, 4, 5, 7, 9, 13, 17, 25, 33, 49, 65, 97, 129, 193,
    257, 385, 513, 769, 1025, 1537, 2049, 3073, 4097, 6145,
    8193, 12289, 16385, 24577, 0, 0
  ];
  
  var dext = [ /* Distance codes 0..29 extra */
    16, 16, 16, 16, 17, 17, 18, 18, 19, 19, 20, 20, 21, 21, 22, 22,
    23, 23, 24, 24, 25, 25, 26, 26, 27, 27,
    28, 28, 29, 29, 64, 64
  ];
  
  module.exports = function inflate_table(type, lens, lens_index, codes, table, table_index, work, opts)
  {
    var bits = opts.bits;
        //here = opts.here; /* table entry for duplication */
  
    var len = 0;               /* a code's length in bits */
    var sym = 0;               /* index of code symbols */
    var min = 0, max = 0;          /* minimum and maximum code lengths */
    var root = 0;              /* number of index bits for root table */
    var curr = 0;              /* number of index bits for current table */
    var drop = 0;              /* code bits to drop for sub-table */
    var left = 0;                   /* number of prefix codes available */
    var used = 0;              /* code entries in table used */
    var huff = 0;              /* Huffman code */
    var incr;              /* for incrementing code, index */
    var fill;              /* index for replicating entries */
    var low;               /* low bits for current root entry */
    var mask;              /* mask for low root bits */
    var next;             /* next available space in table */
    var base = null;     /* base value table to use */
    var base_index = 0;
  //  var shoextra;    /* extra bits table to use */
    var end;                    /* use base and extra for symbol > end */
    var count = new utils.Buf16(MAXBITS + 1); //[MAXBITS+1];    /* number of codes of each length */
    var offs = new utils.Buf16(MAXBITS + 1); //[MAXBITS+1];     /* offsets in table for each length */
    var extra = null;
    var extra_index = 0;
  
    var here_bits, here_op, here_val;
  
    /*
      Process a set of code lengths to create a canonical Huffman code.  The
      code lengths are lens[0..codes-1].  Each length corresponds to the
      symbols 0..codes-1.  The Huffman code is generated by first sorting the
      symbols by length from short to long, and retaining the symbol order
      for codes with equal lengths.  Then the code starts with all zero bits
      for the first code of the shortest length, and the codes are integer
      increments for the same length, and zeros are appended as the length
      increases.  For the deflate format, these bits are stored backwards
      from their more natural integer increment ordering, and so when the
      decoding tables are built in the large loop below, the integer codes
      are incremented backwards.
  
      This routine assumes, but does not check, that all of the entries in
      lens[] are in the range 0..MAXBITS.  The caller must assure this.
      1..MAXBITS is interpreted as that code length.  zero means that that
      symbol does not occur in this code.
  
      The codes are sorted by computing a count of codes for each length,
      creating from that a table of starting indices for each length in the
      sorted table, and then entering the symbols in order in the sorted
      table.  The sorted table is work[], with that space being provided by
      the caller.
  
      The length counts are used for other purposes as well, i.e. finding
      the minimum and maximum length codes, determining if there are any
      codes at all, checking for a valid set of lengths, and looking ahead
      at length counts to determine sub-table sizes when building the
      decoding tables.
      */
  
    /* accumulate lengths for codes (assumes lens[] all in 0..MAXBITS) */
    for (len = 0; len <= MAXBITS; len++) {
      count[len] = 0;
    }
    for (sym = 0; sym < codes; sym++) {
      count[lens[lens_index + sym]]++;
    }
  
    /* bound code lengths, force root to be within code lengths */
    root = bits;
    for (max = MAXBITS; max >= 1; max--) {
      if (count[max] !== 0) { break; }
    }
    if (root > max) {
      root = max;
    }
    if (max === 0) {                     /* no symbols to code at all */
      //table.op[opts.table_index] = 64;  //here.op = (var char)64;    /* invalid code marker */
      //table.bits[opts.table_index] = 1;   //here.bits = (var char)1;
      //table.val[opts.table_index++] = 0;   //here.val = (var short)0;
      table[table_index++] = (1 << 24) | (64 << 16) | 0;
  
  
      //table.op[opts.table_index] = 64;
      //table.bits[opts.table_index] = 1;
      //table.val[opts.table_index++] = 0;
      table[table_index++] = (1 << 24) | (64 << 16) | 0;
  
      opts.bits = 1;
      return 0;     /* no symbols, but wait for decoding to report error */
    }
    for (min = 1; min < max; min++) {
      if (count[min] !== 0) { break; }
    }
    if (root < min) {
      root = min;
    }
  
    /* check for an over-subscribed or incomplete set of lengths */
    left = 1;
    for (len = 1; len <= MAXBITS; len++) {
      left <<= 1;
      left -= count[len];
      if (left < 0) {
        return -1;
      }        /* over-subscribed */
    }
    if (left > 0 && (type === CODES || max !== 1)) {
      return -1;                      /* incomplete set */
    }
  
    /* generate offsets into symbol table for each length for sorting */
    offs[1] = 0;
    for (len = 1; len < MAXBITS; len++) {
      offs[len + 1] = offs[len] + count[len];
    }
  
    /* sort symbols by length, by symbol order within each length */
    for (sym = 0; sym < codes; sym++) {
      if (lens[lens_index + sym] !== 0) {
        work[offs[lens[lens_index + sym]]++] = sym;
      }
    }
  
    /*
      Create and fill in decoding tables.  In this loop, the table being
      filled is at next and has curr index bits.  The code being used is huff
      with length len.  That code is converted to an index by dropping drop
      bits off of the bottom.  For codes where len is less than drop + curr,
      those top drop + curr - len bits are incremented through all values to
      fill the table with replicated entries.
  
      root is the number of index bits for the root table.  When len exceeds
      root, sub-tables are created pointed to by the root entry with an index
      of the low root bits of huff.  This is saved in low to check for when a
      new sub-table should be started.  drop is zero when the root table is
      being filled, and drop is root when sub-tables are being filled.
  
      When a new sub-table is needed, it is necessary to look ahead in the
      code lengths to determine what size sub-table is needed.  The length
      counts are used for this, and so count[] is decremented as codes are
      entered in the tables.
  
      used keeps track of how many table entries have been allocated from the
      provided *table space.  It is checked for LENS and DIST tables against
      the constants ENOUGH_LENS and ENOUGH_DISTS to guard against changes in
      the initial root table size constants.  See the comments in inftrees.h
      for more information.
  
      sym increments through all symbols, and the loop terminates when
      all codes of length max, i.e. all codes, have been processed.  This
      routine permits incomplete codes, so another loop after this one fills
      in the rest of the decoding tables with invalid code markers.
      */
  
    /* set up for code type */
    // poor man optimization - use if-else instead of switch,
    // to avoid deopts in old v8
    if (type === CODES) {
      base = extra = work;    /* dummy value--not used */
      end = 19;
  
    } else if (type === LENS) {
      base = lbase;
      base_index -= 257;
      extra = lext;
      extra_index -= 257;
      end = 256;
  
    } else {                    /* DISTS */
      base = dbase;
      extra = dext;
      end = -1;
    }
  
    /* initialize opts for loop */
    huff = 0;                   /* starting code */
    sym = 0;                    /* starting code symbol */
    len = min;                  /* starting code length */
    next = table_index;              /* current table to fill in */
    curr = root;                /* current table index bits */
    drop = 0;                   /* current bits to drop from code for index */
    low = -1;                   /* trigger new sub-table when len > root */
    used = 1 << root;          /* use root table entries */
    mask = used - 1;            /* mask for comparing low */
  
    /* check available table space */
    if ((type === LENS && used > ENOUGH_LENS) ||
      (type === DISTS && used > ENOUGH_DISTS)) {
      return 1;
    }
  
    var i = 0;
    /* process all codes and make table entries */
    for (;;) {
      i++;
      /* create table entry */
      here_bits = len - drop;
      if (work[sym] < end) {
        here_op = 0;
        here_val = work[sym];
      }
      else if (work[sym] > end) {
        here_op = extra[extra_index + work[sym]];
        here_val = base[base_index + work[sym]];
      }
      else {
        here_op = 32 + 64;         /* end of block */
        here_val = 0;
      }
  
      /* replicate for those indices with low len bits equal to huff */
      incr = 1 << (len - drop);
      fill = 1 << curr;
      min = fill;                 /* save offset to next table */
      do {
        fill -= incr;
        table[next + (huff >> drop) + fill] = (here_bits << 24) | (here_op << 16) | here_val |0;
      } while (fill !== 0);
  
      /* backwards increment the len-bit code huff */
      incr = 1 << (len - 1);
      while (huff & incr) {
        incr >>= 1;
      }
      if (incr !== 0) {
        huff &= incr - 1;
        huff += incr;
      } else {
        huff = 0;
      }
  
      /* go to next symbol, update count, len */
      sym++;
      if (--count[len] === 0) {
        if (len === max) { break; }
        len = lens[lens_index + work[sym]];
      }
  
      /* create new sub-table if needed */
      if (len > root && (huff & mask) !== low) {
        /* if first time, transition to sub-tables */
        if (drop === 0) {
          drop = root;
        }
  
        /* increment past last table */
        next += min;            /* here min is 1 << curr */
  
        /* determine length of next table */
        curr = len - drop;
        left = 1 << curr;
        while (curr + drop < max) {
          left -= count[curr + drop];
          if (left <= 0) { break; }
          curr++;
          left <<= 1;
        }
  
        /* check for enough space */
        used += 1 << curr;
        if ((type === LENS && used > ENOUGH_LENS) ||
          (type === DISTS && used > ENOUGH_DISTS)) {
          return 1;
        }
  
        /* point entry in root table to sub-table */
        low = huff & mask;
        /*table.op[low] = curr;
        table.bits[low] = root;
        table.val[low] = next - opts.table_index;*/
        table[low] = (root << 24) | (curr << 16) | (next - table_index) |0;
      }
    }
  
    /* fill in remaining table entry if code is incomplete (guaranteed to have
      at most one remaining entry, since if the code is incomplete, the
      maximum code length that was allowed to get this far is one bit) */
    if (huff !== 0) {
      //table.op[next + huff] = 64;            /* invalid code marker */
      //table.bits[next + huff] = len - drop;
      //table.val[next + huff] = 0;
      table[next + huff] = ((len - drop) << 24) | (64 << 16) |0;
    }
  
    /* set return parameters */
    //opts.table_index += used;
    opts.bits = root;
    return 0;
  };
  
  },{"../utils/common":2}],11:[function(require,module,exports){
  'use strict';
  
  module.exports = {
    2:      'need dictionary',     /* Z_NEED_DICT       2  */
    1:      'stream end',          /* Z_STREAM_END      1  */
    0:      '',                    /* Z_OK              0  */
    '-1':   'file error',          /* Z_ERRNO         (-1) */
    '-2':   'stream error',        /* Z_STREAM_ERROR  (-2) */
    '-3':   'data error',          /* Z_DATA_ERROR    (-3) */
    '-4':   'insufficient memory', /* Z_MEM_ERROR     (-4) */
    '-5':   'buffer error',        /* Z_BUF_ERROR     (-5) */
    '-6':   'incompatible version' /* Z_VERSION_ERROR (-6) */
  };
  
  },{}],12:[function(require,module,exports){
  'use strict';
  
  
  function ZStream() {
    /* next input byte */
    this.input = null; // JS specific, because we have no pointers
    this.next_in = 0;
    /* number of bytes available at input */
    this.avail_in = 0;
    /* total number of input bytes read so far */
    this.total_in = 0;
    /* next output byte should be put there */
    this.output = null; // JS specific, because we have no pointers
    this.next_out = 0;
    /* remaining free space at output */
    this.avail_out = 0;
    /* total number of bytes output so far */
    this.total_out = 0;
    /* last error message, NULL if no error */
    this.msg = ''/*Z_NULL*/;
    /* not visible by applications */
    this.state = null;
    /* best guess about the data type: binary or text */
    this.data_type = 2/*Z_UNKNOWN*/;
    /* adler32 value of the uncompressed data */
    this.adler = 0;
  }
  
  module.exports = ZStream;
  
  },{}],13:[function(require,module,exports){
  "use strict";
  
  function AbstractDecoder() {}
  
  AbstractDecoder.prototype = {
    isAsync: function isAsync() {
      // TODO: check if async reading func is enabled or not.
      return typeof this.decodeBlock === "undefined";
    }
  };
  
  module.exports = AbstractDecoder;
  
  },{}],14:[function(require,module,exports){
  "use strict";
  
  var AbstractDecoder = require("../abstractdecoder.js");
  var pakoInflate = require('pako/lib/inflate').inflate;
  
  function DeflateDecoder() {}
  
  DeflateDecoder.prototype = Object.create(AbstractDecoder.prototype);
  DeflateDecoder.prototype.constructor = DeflateDecoder;
  DeflateDecoder.prototype.decodeBlock = function (buffer) {
    return pakoInflate(new Uint8Array(buffer)).buffer;
  };
  
  module.exports = DeflateDecoder;
  
  },{"../abstractdecoder.js":13,"pako/lib/inflate":1}],15:[function(require,module,exports){
  "use strict";
  
  //var lzwCompress = require("lzwcompress");
  
  var AbstractDecoder = require("../abstractdecoder.js");
  
  var MIN_BITS = 9;
  var MAX_BITS = 12;
  var CLEAR_CODE = 256; // clear code
  var EOI_CODE = 257; // end of information
  
  function LZW() {
    this.littleEndian = false;
    this.position = 0;
  
    this._makeEntryLookup = false;
    this.dictionary = [];
  }
  
  LZW.prototype = {
    constructor: LZW,
    initDictionary: function initDictionary() {
      this.dictionary = new Array(258);
      this.entryLookup = {};
      this.byteLength = MIN_BITS;
      for (var i = 0; i <= 257; i++) {
        // i really feal like i <= 257, but I get strange unknown words that way.
        this.dictionary[i] = [i];
        if (this._makeEntryLookup) {
          this.entryLookup[i] = i;
        }
      }
    },
  
    decompress: function decompress(input) {
      this._makeEntryLookup = false; // for speed
      this.initDictionary();
      this.position = 0;
      this.result = [];
      if (!input.buffer) {
        input = new Uint8Array(input);
      }
      var mydataview = new DataView(input.buffer);
      var code = this.getNext(mydataview);
      var oldCode;
      while (code !== EOI_CODE) {
        if (code === CLEAR_CODE) {
          this.initDictionary();
          code = this.getNext(mydataview);
          while (code === CLEAR_CODE) {
            code = this.getNext(mydataview);
          }
          if (code > CLEAR_CODE) {
            throw 'corrupted code at scanline ' + code;
          }
          if (code === EOI_CODE) {
            break;
          } else {
            var val = this.dictionary[code];
            this.appendArray(this.result, val);
            oldCode = code;
          }
        } else {
          if (this.dictionary[code] !== undefined) {
            var _val = this.dictionary[code];
            this.appendArray(this.result, _val);
            var newVal = this.dictionary[oldCode].concat(this.dictionary[code][0]);
            this.addToDictionary(newVal);
            oldCode = code;
          } else {
            var oldVal = this.dictionary[oldCode];
            if (!oldVal) {
              throw "Bogus entry. Not in dictionary, " + oldCode + " / " + this.dictionary.length + ", position: " + this.position;
            }
            var _newVal = oldVal.concat(this.dictionary[oldCode][0]);
            this.appendArray(this.result, _newVal);
            this.addToDictionary(_newVal);
            oldCode = code;
          }
        }
        // This is strange. It seems like the
        if (this.dictionary.length >= Math.pow(2, this.byteLength) - 1) {
          this.byteLength++;
        }
        code = this.getNext(mydataview);
      }
      return new Uint8Array(this.result);
    },
  
    appendArray: function appendArray(dest, source) {
      for (var i = 0; i < source.length; i++) {
        dest.push(source[i]);
      }
      return dest;
    },
  
    haveBytesChanged: function haveBytesChanged() {
      if (this.dictionary.length >= Math.pow(2, this.byteLength)) {
        this.byteLength++;
        return true;
      }
      return false;
    },
  
    addToDictionary: function addToDictionary(arr) {
      this.dictionary.push(arr);
      if (this._makeEntryLookup) {
        this.entryLookup[arr] = this.dictionary.length - 1;
      }
      this.haveBytesChanged();
      return this.dictionary.length - 1;
    },
  
    getNext: function getNext(dataview) {
      var byte = this.getByte(dataview, this.position, this.byteLength);
      this.position += this.byteLength;
      return byte;
    },
  
    // This binary representation might actually be as fast as the completely illegible bit shift approach
    //
    getByte: function getByte(dataview, position, length) {
      var d = position % 8;
      var a = Math.floor(position / 8);
      var de = 8 - d;
      var ef = position + length - (a + 1) * 8;
      var fg = 8 * (a + 2) - (position + length);
      var dg = (a + 2) * 8 - position;
      fg = Math.max(0, fg);
      if (a >= dataview.byteLength) {
        console.warn('ran off the end of the buffer before finding EOI_CODE (end on input code)');
        return EOI_CODE;
      }
      var chunk1 = dataview.getUint8(a, this.littleEndian) & Math.pow(2, 8 - d) - 1;
      chunk1 = chunk1 << length - de;
      var chunks = chunk1;
      if (a + 1 < dataview.byteLength) {
        var chunk2 = dataview.getUint8(a + 1, this.littleEndian) >>> fg;
        chunk2 = chunk2 << Math.max(0, length - dg);
        chunks += chunk2;
      }
      if (ef > 8 && a + 2 < dataview.byteLength) {
        var hi = (a + 3) * 8 - (position + length);
        var chunk3 = dataview.getUint8(a + 2, this.littleEndian) >>> hi;
        chunks += chunk3;
      }
      return chunks;
    },
  
    // compress has not been optimized and uses a uint8 array to hold binary values.
    compress: function compress(input) {
      this._makeEntryLookup = true;
      this.initDictionary();
      this.position = 0;
      var resultBits = [];
      var omega = [];
      resultBits = this.appendArray(resultBits, this.binaryFromByte(CLEAR_CODE, this.byteLength)); // resultBits.concat(Array.from(this.binaryFromByte(this.CLEAR_CODE, this.byteLength)))
      for (var i = 0; i < input.length; i++) {
        var k = [input[i]];
        var omk = omega.concat(k);
        if (this.entryLookup[omk] !== undefined) {
          omega = omk;
        } else {
          var _code = this.entryLookup[omega];
          var _bin = this.binaryFromByte(_code, this.byteLength);
          resultBits = this.appendArray(resultBits, _bin);
          this.addToDictionary(omk);
          omega = k;
          if (this.dictionary.length >= Math.pow(2, MAX_BITS)) {
            resultBits = this.appendArray(resultBits, this.binaryFromByte(CLEAR_CODE, this.byteLength));
            this.initDictionary();
          }
        }
      }
      var code = this.entryLookup[omega];
      var bin = this.binaryFromByte(code, this.byteLength);
      resultBits = this.appendArray(resultBits, bin);
      resultBits = resultBits = this.appendArray(resultBits, this.binaryFromByte(EOI_CODE, this.byteLength));
      this.binary = resultBits;
      this.result = this.binaryToUint8(resultBits);
      return this.result;
    },
  
    byteFromCode: function byteFromCode(code) {
      var res = this.dictionary[code];
      return res;
    },
  
    binaryFromByte: function binaryFromByte(byte) {
      var byteLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
  
      var res = new Uint8Array(byteLength);
      for (var i = 0; i < res.length; i++) {
        var mask = Math.pow(2, i);
        var isOne = (byte & mask) > 0;
        res[res.length - 1 - i] = isOne;
      }
      return res;
    },
  
    binaryToNumber: function binaryToNumber(bin) {
      var res = 0;
      for (var i = 0; i < bin.length; i++) {
        res += Math.pow(2, bin.length - i - 1) * bin[i];
      }
      return res;
    },
  
    inputToBinary: function inputToBinary(input) {
      var inputByteLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
  
      var res = new Uint8Array(input.length * inputByteLength);
      for (var i = 0; i < input.length; i++) {
        var bin = this.binaryFromByte(input[i], inputByteLength);
        res.set(bin, i * inputByteLength);
      }
      return res;
    },
  
    binaryToUint8: function binaryToUint8(bin) {
      var result = new Uint8Array(Math.ceil(bin.length / 8));
      var index = 0;
      for (var i = 0; i < bin.length; i += 8) {
        var val = 0;
        for (var j = 0; j < 8 && i + j < bin.length; j++) {
          val = val + bin[i + j] * Math.pow(2, 8 - j - 1);
        }
        result[index] = val;
        index++;
      }
      return result;
    }
  };
  
  // the actual decoder interface
  
  function LZWDecoder() {
    this.decompressor = new LZW();
  }
  
  LZWDecoder.prototype = Object.create(AbstractDecoder.prototype);
  LZWDecoder.prototype.constructor = LZWDecoder;
  LZWDecoder.prototype.decodeBlock = function (buffer) {
    return this.decompressor.decompress(buffer).buffer;
  };
  
  module.exports = LZWDecoder;
  
  },{"../abstractdecoder.js":13}],16:[function(require,module,exports){
  "use strict";
  
  var AbstractDecoder = require("../abstractdecoder.js");
  
  function PackbitsDecoder() {}
  
  PackbitsDecoder.prototype = Object.create(AbstractDecoder.prototype);
  PackbitsDecoder.prototype.constructor = PackbitsDecoder;
  PackbitsDecoder.prototype.decodeBlock = function (buffer) {
    var dataView = new DataView(buffer);
    var out = [];
    var i, j;
  
    for (i = 0; i < buffer.byteLength; ++i) {
      var header = dataView.getInt8(i);
      if (header < 0) {
        var next = dataView.getUint8(i + 1);
        header = -header;
        for (j = 0; j <= header; ++j) {
          out.push(next);
        }
        i += 1;
      } else {
        for (j = 0; j <= header; ++j) {
          out.push(dataView.getUint8(i + j + 1));
        }
        i += header + 1;
      }
    }
    return new Uint8Array(out).buffer;
  };
  
  module.exports = PackbitsDecoder;
  
  },{"../abstractdecoder.js":13}],17:[function(require,module,exports){
  "use strict";
  
  var AbstractDecoder = require("../abstractdecoder.js");
  
  function RawDecoder() {}
  
  RawDecoder.prototype = Object.create(AbstractDecoder.prototype);
  RawDecoder.prototype.constructor = RawDecoder;
  RawDecoder.prototype.decodeBlock = function (buffer) {
    return buffer;
  };
  
  module.exports = RawDecoder;
  
  },{"../abstractdecoder.js":13}],18:[function(require,module,exports){
  "use strict";
  
  var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();
  
  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
  
  var DataView64 = function () {
    function DataView64(arrayBuffer) {
      _classCallCheck(this, DataView64);
  
      this._dataView = new DataView(arrayBuffer);
    }
  
    _createClass(DataView64, [{
      key: "getUint64",
      value: function getUint64(offset, littleEndian) {
        var left = this.getUint32(offset, littleEndian);
        var right = this.getUint32(offset + 4, littleEndian);
        if (littleEndian) {
          return left << 32 | right;
        }
        return right << 32 | left;
      }
    }, {
      key: "getInt64",
      value: function getInt64(offset, littleEndian) {
        var left, right;
        if (littleEndian) {
          left = this.getInt32(offset, littleEndian);
          right = this.getUint32(offset + 4, littleEndian);
  
          return left << 32 | right;
        }
        left = this.getUint32(offset, littleEndian);
        right = this.getInt32(offset + 4, littleEndian);
        return right << 32 | left;
      }
    }, {
      key: "getUint8",
      value: function getUint8(offset, littleEndian) {
        return this._dataView.getUint8(offset, littleEndian);
      }
    }, {
      key: "getInt8",
      value: function getInt8(offset, littleEndian) {
        return this._dataView.getInt8(offset, littleEndian);
      }
    }, {
      key: "getUint16",
      value: function getUint16(offset, littleEndian) {
        return this._dataView.getUint16(offset, littleEndian);
      }
    }, {
      key: "getInt16",
      value: function getInt16(offset, littleEndian) {
        return this._dataView.getInt16(offset, littleEndian);
      }
    }, {
      key: "getUint32",
      value: function getUint32(offset, littleEndian) {
        return this._dataView.getUint32(offset, littleEndian);
      }
    }, {
      key: "getInt32",
      value: function getInt32(offset, littleEndian) {
        return this._dataView.getInt32(offset, littleEndian);
      }
    }, {
      key: "getFloat32",
      value: function getFloat32(offset, littleEndian) {
        return this._dataView.getFloat32(offset, littleEndian);
      }
    }, {
      key: "getFloat64",
      value: function getFloat64(offset, littleEndian) {
        return this._dataView.getFloat64(offset, littleEndian);
      }
    }, {
      key: "buffer",
      get: function get() {
        return this._dataView.buffer;
      }
    }]);
  
    return DataView64;
  }();
  
  module.exports = DataView64;
  
  },{}],19:[function(require,module,exports){
  "use strict";
  
  var globals = require("./globals.js");
  var GeoTIFFImage = require("./geotiffimage.js");
  var DataView64 = require("./dataview64.js");
  
  var fieldTypes = globals.fieldTypes,
      fieldTagNames = globals.fieldTagNames,
      arrayFields = globals.arrayFields,
      geoKeyNames = globals.geoKeyNames;
  
  /**
   * The abstraction for a whole GeoTIFF file.
   * @constructor
   * @param {ArrayBuffer} rawData the raw data stream of the file as an ArrayBuffer.
   * @param {Object} [options] further options.
   * @param {Boolean} [options.cache=false] whether or not decoded tiles shall be cached.
   */
  function GeoTIFF(rawData, options) {
    this.dataView = new DataView64(rawData);
    options = options || {};
    this.cache = options.cache || false;
  
    var BOM = this.dataView.getUint16(0, 0);
    if (BOM === 0x4949) {
      this.littleEndian = true;
    } else if (BOM === 0x4D4D) {
      this.littleEndian = false;
    } else {
      throw new TypeError("Invalid byte order value.");
    }
  
    var magicNumber = this.dataView.getUint16(2, this.littleEndian);
    if (this.dataView.getUint16(2, this.littleEndian) === 42) {
      this.bigTiff = false;
    } else if (magicNumber === 43) {
      this.bigTiff = true;
      var offsetBytesize = this.dataView.getUint16(4, this.littleEndian);
      if (offsetBytesize !== 8) {
        throw new Error("Unsupported offset byte-size.");
      }
    } else {
      throw new TypeError("Invalid magic number.");
    }
  
    this.fileDirectories = this.parseFileDirectories(this.getOffset(this.bigTiff ? 8 : 4));
  }
  
  GeoTIFF.prototype = {
    getOffset: function getOffset(offset) {
      if (this.bigTiff) {
        return this.dataView.getUint64(offset, this.littleEndian);
      }
      return this.dataView.getUint32(offset, this.littleEndian);
    },
  
    getFieldTypeLength: function getFieldTypeLength(fieldType) {
      switch (fieldType) {
        case fieldTypes.BYTE:case fieldTypes.ASCII:case fieldTypes.SBYTE:case fieldTypes.UNDEFINED:
          return 1;
        case fieldTypes.SHORT:case fieldTypes.SSHORT:
          return 2;
        case fieldTypes.LONG:case fieldTypes.SLONG:case fieldTypes.FLOAT:
          return 4;
        case fieldTypes.RATIONAL:case fieldTypes.SRATIONAL:case fieldTypes.DOUBLE:
        case fieldTypes.LONG8:case fieldTypes.SLONG8:case fieldTypes.IFD8:
          return 8;
        default:
          throw new RangeError("Invalid field type: " + fieldType);
      }
    },
  
    getValues: function getValues(fieldType, count, offset) {
      var values = null;
      var readMethod = null;
      var fieldTypeLength = this.getFieldTypeLength(fieldType);
      var i;
  
      switch (fieldType) {
        case fieldTypes.BYTE:case fieldTypes.ASCII:case fieldTypes.UNDEFINED:
          values = new Uint8Array(count);readMethod = this.dataView.getUint8;
          break;
        case fieldTypes.SBYTE:
          values = new Int8Array(count);readMethod = this.dataView.getInt8;
          break;
        case fieldTypes.SHORT:
          values = new Uint16Array(count);readMethod = this.dataView.getUint16;
          break;
        case fieldTypes.SSHORT:
          values = new Int16Array(count);readMethod = this.dataView.getInt16;
          break;
        case fieldTypes.LONG:
          values = new Uint32Array(count);readMethod = this.dataView.getUint32;
          break;
        case fieldTypes.SLONG:
          values = new Int32Array(count);readMethod = this.dataView.getInt32;
          break;
        case fieldTypes.LONG8:case fieldTypes.IFD8:
          values = new Array(count);readMethod = this.dataView.getUint64;
          break;
        case fieldTypes.SLONG8:
          values = new Array(count);readMethod = this.dataView.getInt64;
          break;
        case fieldTypes.RATIONAL:
          values = new Uint32Array(count * 2);readMethod = this.dataView.getUint32;
          break;
        case fieldTypes.SRATIONAL:
          values = new Int32Array(count * 2);readMethod = this.dataView.getInt32;
          break;
        case fieldTypes.FLOAT:
          values = new Float32Array(count);readMethod = this.dataView.getFloat32;
          break;
        case fieldTypes.DOUBLE:
          values = new Float64Array(count);readMethod = this.dataView.getFloat64;
          break;
        default:
          throw new RangeError("Invalid field type: " + fieldType);
      }
  
      // normal fields
      if (!(fieldType === fieldTypes.RATIONAL || fieldType === fieldTypes.SRATIONAL)) {
        for (i = 0; i < count; ++i) {
          values[i] = readMethod.call(this.dataView, offset + i * fieldTypeLength, this.littleEndian);
        }
      }
      // RATIONAL or SRATIONAL
      else {
          for (i = 0; i < count; i += 2) {
            values[i] = readMethod.call(this.dataView, offset + i * fieldTypeLength, this.littleEndian);
            values[i + 1] = readMethod.call(this.dataView, offset + (i * fieldTypeLength + 4), this.littleEndian);
          }
        }
  
      if (fieldType === fieldTypes.ASCII) {
        return String.fromCharCode.apply(null, values);
      }
      return values;
    },
  
    getFieldValues: function getFieldValues(fieldTag, fieldType, typeCount, valueOffset) {
      var fieldValues;
      var fieldTypeLength = this.getFieldTypeLength(fieldType);
  
      if (fieldTypeLength * typeCount <= (this.bigTiff ? 8 : 4)) {
        fieldValues = this.getValues(fieldType, typeCount, valueOffset);
      } else {
        var actualOffset = this.getOffset(valueOffset);
        fieldValues = this.getValues(fieldType, typeCount, actualOffset);
      }
  
      if (typeCount === 1 && arrayFields.indexOf(fieldTag) === -1 && !(fieldType === fieldTypes.RATIONAL || fieldType === fieldTypes.SRATIONAL)) {
        return fieldValues[0];
      }
  
      return fieldValues;
    },
  
    parseGeoKeyDirectory: function parseGeoKeyDirectory(fileDirectory) {
      var rawGeoKeyDirectory = fileDirectory.GeoKeyDirectory;
      if (!rawGeoKeyDirectory) {
        return null;
      }
  
      var geoKeyDirectory = {};
      for (var i = 4; i < rawGeoKeyDirectory[3] * 4; i += 4) {
        var key = geoKeyNames[rawGeoKeyDirectory[i]],
            location = rawGeoKeyDirectory[i + 1] ? fieldTagNames[rawGeoKeyDirectory[i + 1]] : null,
            count = rawGeoKeyDirectory[i + 2],
            offset = rawGeoKeyDirectory[i + 3];
  
        var value = null;
        if (!location) {
          value = offset;
        } else {
          value = fileDirectory[location];
          if (typeof value === "undefined" || value === null) {
            throw new Error("Could not get value of geoKey '" + key + "'.");
          } else if (typeof value === "string") {
            value = value.substring(offset, offset + count - 1);
          } else if (value.subarray) {
            value = value.subarray(offset, offset + count - 1);
          }
        }
        geoKeyDirectory[key] = value;
      }
      return geoKeyDirectory;
    },
  
    parseFileDirectories: function parseFileDirectories(byteOffset) {
      var nextIFDByteOffset = byteOffset;
      var fileDirectories = [];
  
      while (nextIFDByteOffset !== 0x00000000) {
        var numDirEntries = this.bigTiff ? this.dataView.getUint64(nextIFDByteOffset, this.littleEndian) : this.dataView.getUint16(nextIFDByteOffset, this.littleEndian);
  
        var fileDirectory = {};
        var i = nextIFDByteOffset + (this.bigTiff ? 8 : 2);
        for (var entryCount = 0; entryCount < numDirEntries; i += this.bigTiff ? 20 : 12, ++entryCount) {
          var fieldTag = this.dataView.getUint16(i, this.littleEndian);
          var fieldType = this.dataView.getUint16(i + 2, this.littleEndian);
          var typeCount = this.bigTiff ? this.dataView.getUint64(i + 4, this.littleEndian) : this.dataView.getUint32(i + 4, this.littleEndian);
  
          fileDirectory[fieldTagNames[fieldTag]] = this.getFieldValues(fieldTag, fieldType, typeCount, i + (this.bigTiff ? 12 : 8));
        }
        fileDirectories.push([fileDirectory, this.parseGeoKeyDirectory(fileDirectory)]);
  
        nextIFDByteOffset = this.getOffset(i);
      }
      return fileDirectories;
    },
  
    /**
     * Get the n-th internal subfile a an image. By default, the first is returned.
     *
     * @param {Number} [index=0] the index of the image to return.
     * @returns {GeoTIFFImage} the image at the given index
     */
    getImage: function getImage(index) {
      index = index || 0;
      var fileDirectoryAndGeoKey = this.fileDirectories[index];
      if (!fileDirectoryAndGeoKey) {
        throw new RangeError("Invalid image index");
      }
      return new GeoTIFFImage(fileDirectoryAndGeoKey[0], fileDirectoryAndGeoKey[1], this.dataView, this.littleEndian, this.cache);
    },
  
    /**
     * Returns the count of the internal subfiles.
     *
     * @returns {Number} the number of internal subfile images
     */
    getImageCount: function getImageCount() {
      return this.fileDirectories.length;
    }
  };
  
  module.exports = GeoTIFF;
  
  },{"./dataview64.js":18,"./geotiffimage.js":20,"./globals.js":21}],20:[function(require,module,exports){
  "use strict";
  
  var globals = require("./globals.js");
  var RGB = require("./rgb.js");
  var RawDecoder = require("./compression/raw.js");
  var LZWDecoder = require("./compression/lzw.js");
  var DeflateDecoder = require("./compression/deflate.js");
  var PackbitsDecoder = require("./compression/packbits.js");
  
  var sum = function sum(array, start, end) {
    var s = 0;
    for (var i = start; i < end; ++i) {
      s += array[i];
    }
    return s;
  };
  
  var arrayForType = function arrayForType(format, bitsPerSample, size) {
    switch (format) {
      case 1:
        // unsigned integer data
        switch (bitsPerSample) {
          case 8:
            return new Uint8Array(size);
          case 16:
            return new Uint16Array(size);
          case 32:
            return new Uint32Array(size);
        }
        break;
      case 2:
        // twos complement signed integer data
        switch (bitsPerSample) {
          case 8:
            return new Int8Array(size);
          case 16:
            return new Int16Array(size);
          case 32:
            return new Int32Array(size);
        }
        break;
      case 3:
        // floating point data
        switch (bitsPerSample) {
          case 32:
            return new Float32Array(size);
          case 64:
            return new Float64Array(size);
        }
        break;
    }
    throw Error("Unsupported data format/bitsPerSample");
  };
  
  /**
   * GeoTIFF sub-file image.
   * @constructor
   * @param {Object} fileDirectory The parsed file directory
   * @param {Object} geoKeys The parsed geo-keys
   * @param {DataView} dataView The DataView for the underlying file.
   * @param {Boolean} littleEndian Whether the file is encoded in little or big endian
   * @param {Boolean} cache Whether or not decoded tiles shall be cached
   */
  function GeoTIFFImage(fileDirectory, geoKeys, dataView, littleEndian, cache) {
    this.fileDirectory = fileDirectory;
    this.geoKeys = geoKeys;
    this.dataView = dataView;
    this.littleEndian = littleEndian;
    this.tiles = cache ? {} : null;
    this.isTiled = fileDirectory.StripOffsets ? false : true;
    var planarConfiguration = fileDirectory.PlanarConfiguration;
    this.planarConfiguration = typeof planarConfiguration === "undefined" ? 1 : planarConfiguration;
    if (this.planarConfiguration !== 1 && this.planarConfiguration !== 2) {
      throw new Error("Invalid planar configuration.");
    }
  
    switch (this.fileDirectory.Compression) {
      case undefined:
      case 1:
        // no compression
        this.decoder = new RawDecoder();
        break;
      case 5:
        // LZW
        this.decoder = new LZWDecoder();
        break;
      case 6:
        // JPEG
        throw new Error("JPEG compression not supported.");
      case 8:
        // Deflate
        this.decoder = new DeflateDecoder();
        break;
      //case 32946: // deflate ??
      //  throw new Error("Deflate compression not supported.");
      case 32773:
        // packbits
        this.decoder = new PackbitsDecoder();
        break;
      default:
        throw new Error("Unknown compresseion method identifier: " + this.fileDirectory.Compression);
    }
  }
  
  GeoTIFFImage.prototype = {
    /**
     * Returns the associated parsed file directory.
     * @returns {Object} the parsed file directory
     */
    getFileDirectory: function getFileDirectory() {
      return this.fileDirectory;
    },
    /**
    * Returns the associated parsed geo keys.
    * @returns {Object} the parsed geo keys
    */
    getGeoKeys: function getGeoKeys() {
      return this.geoKeys;
    },
    /**
     * Returns the width of the image.
     * @returns {Number} the width of the image
     */
    getWidth: function getWidth() {
      return this.fileDirectory.ImageWidth;
    },
    /**
     * Returns the height of the image.
     * @returns {Number} the height of the image
     */
    getHeight: function getHeight() {
      return this.fileDirectory.ImageLength;
    },
    /**
     * Returns the number of samples per pixel.
     * @returns {Number} the number of samples per pixel
     */
    getSamplesPerPixel: function getSamplesPerPixel() {
      return this.fileDirectory.SamplesPerPixel;
    },
    /**
     * Returns the width of each tile.
     * @returns {Number} the width of each tile
     */
    getTileWidth: function getTileWidth() {
      return this.isTiled ? this.fileDirectory.TileWidth : this.getWidth();
    },
    /**
     * Returns the height of each tile.
     * @returns {Number} the height of each tile
     */
    getTileHeight: function getTileHeight() {
      return this.isTiled ? this.fileDirectory.TileLength : this.fileDirectory.RowsPerStrip;
    },
  
    /**
     * Calculates the number of bytes for each pixel across all samples. Only full
     * bytes are supported, an exception is thrown when this is not the case.
     * @returns {Number} the bytes per pixel
     */
    getBytesPerPixel: function getBytesPerPixel() {
      var bitsPerSample = 0;
      for (var i = 0; i < this.fileDirectory.BitsPerSample.length; ++i) {
        var bits = this.fileDirectory.BitsPerSample[i];
        if (bits % 8 !== 0) {
          throw new Error("Sample bit-width of " + bits + " is not supported.");
        } else if (bits !== this.fileDirectory.BitsPerSample[0]) {
          throw new Error("Differing size of samples in a pixel are not supported.");
        }
        bitsPerSample += bits;
      }
      return bitsPerSample / 8;
    },
  
    getSampleByteSize: function getSampleByteSize(i) {
      if (i >= this.fileDirectory.BitsPerSample.length) {
        throw new RangeError("Sample index " + i + " is out of range.");
      }
      var bits = this.fileDirectory.BitsPerSample[i];
      if (bits % 8 !== 0) {
        throw new Error("Sample bit-width of " + bits + " is not supported.");
      }
      return bits / 8;
    },
  
    getReaderForSample: function getReaderForSample(sampleIndex) {
      var format = this.fileDirectory.SampleFormat ? this.fileDirectory.SampleFormat[sampleIndex] : 1;
      var bitsPerSample = this.fileDirectory.BitsPerSample[sampleIndex];
      switch (format) {
        case 1:
          // unsigned integer data
          switch (bitsPerSample) {
            case 8:
              return DataView.prototype.getUint8;
            case 16:
              return DataView.prototype.getUint16;
            case 32:
              return DataView.prototype.getUint32;
          }
          break;
        case 2:
          // twos complement signed integer data
          switch (bitsPerSample) {
            case 8:
              return DataView.prototype.getInt8;
            case 16:
              return DataView.prototype.getInt16;
            case 32:
              return DataView.prototype.getInt32;
          }
          break;
        case 3:
          switch (bitsPerSample) {
            case 32:
              return DataView.prototype.getFloat32;
            case 64:
              return DataView.prototype.getFloat64;
          }
          break;
      }
    },
  
    getArrayForSample: function getArrayForSample(sampleIndex, size) {
      var format = this.fileDirectory.SampleFormat ? this.fileDirectory.SampleFormat[sampleIndex] : 1;
      var bitsPerSample = this.fileDirectory.BitsPerSample[sampleIndex];
      return arrayForType(format, bitsPerSample, size);
    },
  
    getDecoder: function getDecoder() {
      return this.decoder;
    },
  
    /**
     * Returns the decoded strip or tile.
     * @param {Number} x the strip or tile x-offset
     * @param {Number} y the tile y-offset (0 for stripped images)
     * @param {Number} plane the planar configuration (1: "chunky", 2: "separate samples")
     * @returns {(Int8Array|Uint8Array|Int16Array|Uint16Array|Int32Array|Uint32Array|Float32Array|Float64Array)}
     */
    getTileOrStrip: function getTileOrStrip(x, y, sample, callback) {
      var numTilesPerRow = Math.ceil(this.getWidth() / this.getTileWidth());
      var numTilesPerCol = Math.ceil(this.getHeight() / this.getTileHeight());
      var index;
      var tiles = this.tiles;
      if (this.planarConfiguration === 1) {
        index = y * numTilesPerRow + x;
      } else if (this.planarConfiguration === 2) {
        index = sample * numTilesPerRow * numTilesPerCol + y * numTilesPerRow + x;
      }
  
      if (tiles !== null && index in tiles) {
        if (callback) {
          return callback(null, { x: x, y: y, sample: sample, data: tiles[index] });
        }
        return tiles[index];
      } else {
        var offset, byteCount;
        if (this.isTiled) {
          offset = this.fileDirectory.TileOffsets[index];
          byteCount = this.fileDirectory.TileByteCounts[index];
        } else {
          offset = this.fileDirectory.StripOffsets[index];
          byteCount = this.fileDirectory.StripByteCounts[index];
        }
        var slice = this.dataView.buffer.slice(offset, offset + byteCount);
        if (callback) {
          return this.getDecoder().decodeBlockAsync(slice, function (error, data) {
            if (!error && tiles !== null) {
              tiles[index] = data;
            }
            callback(error, { x: x, y: y, sample: sample, data: data });
          });
        }
        var block = this.getDecoder().decodeBlock(slice);
        if (tiles !== null) {
          tiles[index] = block;
        }
        return block;
      }
    },
  
    _readRasterAsync: function _readRasterAsync(imageWindow, samples, valueArrays, interleave, callback, callbackError) {
      var tileWidth = this.getTileWidth();
      var tileHeight = this.getTileHeight();
  
      var minXTile = Math.floor(imageWindow[0] / tileWidth);
      var maxXTile = Math.ceil(imageWindow[2] / tileWidth);
      var minYTile = Math.floor(imageWindow[1] / tileHeight);
      var maxYTile = Math.ceil(imageWindow[3] / tileHeight);
  
      var numTilesPerRow = Math.ceil(this.getWidth() / tileWidth);
  
      var windowWidth = imageWindow[2] - imageWindow[0];
      var windowHeight = imageWindow[3] - imageWindow[1];
  
      var bytesPerPixel = this.getBytesPerPixel();
      var imageWidth = this.getWidth();
  
      var predictor = this.fileDirectory.Predictor || 1;
  
      var srcSampleOffsets = [];
      var sampleReaders = [];
      for (var i = 0; i < samples.length; ++i) {
        if (this.planarConfiguration === 1) {
          srcSampleOffsets.push(sum(this.fileDirectory.BitsPerSample, 0, samples[i]) / 8);
        } else {
          srcSampleOffsets.push(0);
        }
        sampleReaders.push(this.getReaderForSample(samples[i]));
      }
  
      var allStacked = false;
      var unfinishedTiles = 0;
      var littleEndian = this.littleEndian;
      var globalError = null;
  
      function checkFinished() {
        if (allStacked && unfinishedTiles === 0) {
          if (globalError) {
            callbackError(globalError);
          } else {
            callback(valueArrays);
          }
        }
      }
  
      function onTileGot(error, tile) {
        if (!error) {
          var dataView = new DataView(tile.data);
  
          var firstLine = tile.y * tileHeight;
          var firstCol = tile.x * tileWidth;
          var lastLine = (tile.y + 1) * tileHeight;
          var lastCol = (tile.x + 1) * tileWidth;
          var sampleIndex = tile.sample;
  
          for (var y = Math.max(0, imageWindow[1] - firstLine); y < Math.min(tileHeight, tileHeight - (lastLine - imageWindow[3])); ++y) {
            for (var x = Math.max(0, imageWindow[0] - firstCol); x < Math.min(tileWidth, tileWidth - (lastCol - imageWindow[2])); ++x) {
              var pixelOffset = (y * tileWidth + x) * bytesPerPixel;
              var value = sampleReaders[sampleIndex].call(dataView, pixelOffset + srcSampleOffsets[sampleIndex], littleEndian);
              var windowCoordinate;
              if (interleave) {
                if (predictor !== 1 && x > 0) {
                  windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth * samples.length + (x + firstCol - imageWindow[0] - 1) * samples.length + sampleIndex;
                  value += valueArrays[windowCoordinate];
                }
  
                windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth * samples.length + (x + firstCol - imageWindow[0]) * samples.length + sampleIndex;
                valueArrays[windowCoordinate] = value;
              } else {
                if (predictor !== 1 && x > 0) {
                  windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth + x - 1 + firstCol - imageWindow[0];
                  value += valueArrays[sampleIndex][windowCoordinate];
                }
  
                windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth + x + firstCol - imageWindow[0];
                valueArrays[sampleIndex][windowCoordinate] = value;
              }
            }
          }
        } else {
          globalError = error;
        }
  
        // check end condition and call callbacks
        unfinishedTiles -= 1;
        checkFinished();
      }
  
      for (var yTile = minYTile; yTile <= maxYTile; ++yTile) {
        for (var xTile = minXTile; xTile <= maxXTile; ++xTile) {
          for (var sampleIndex = 0; sampleIndex < samples.length; ++sampleIndex) {
            var sample = samples[sampleIndex];
            if (this.planarConfiguration === 2) {
              bytesPerPixel = this.getSampleByteSize(sample);
            }
            var _sampleIndex = sampleIndex;
            unfinishedTiles += 1;
            this.getTileOrStrip(xTile, yTile, sample, onTileGot);
          }
        }
      }
      allStacked = true;
      checkFinished();
    },
  
    _readRaster: function _readRaster(imageWindow, samples, valueArrays, interleave, callback, callbackError) {
      try {
        var tileWidth = this.getTileWidth();
        var tileHeight = this.getTileHeight();
  
        var minXTile = Math.floor(imageWindow[0] / tileWidth);
        var maxXTile = Math.ceil(imageWindow[2] / tileWidth);
        var minYTile = Math.floor(imageWindow[1] / tileHeight);
        var maxYTile = Math.ceil(imageWindow[3] / tileHeight);
  
        var numTilesPerRow = Math.ceil(this.getWidth() / tileWidth);
  
        var windowWidth = imageWindow[2] - imageWindow[0];
        var windowHeight = imageWindow[3] - imageWindow[1];
  
        var bytesPerPixel = this.getBytesPerPixel();
        var imageWidth = this.getWidth();
  
        var predictor = this.fileDirectory.Predictor || 1;
  
        var srcSampleOffsets = [];
        var sampleReaders = [];
        for (var i = 0; i < samples.length; ++i) {
          if (this.planarConfiguration === 1) {
            srcSampleOffsets.push(sum(this.fileDirectory.BitsPerSample, 0, samples[i]) / 8);
          } else {
            srcSampleOffsets.push(0);
          }
          sampleReaders.push(this.getReaderForSample(samples[i]));
        }
  
        for (var yTile = minYTile; yTile < maxYTile; ++yTile) {
          for (var xTile = minXTile; xTile < maxXTile; ++xTile) {
            var firstLine = yTile * tileHeight;
            var firstCol = xTile * tileWidth;
            var lastLine = (yTile + 1) * tileHeight;
            var lastCol = (xTile + 1) * tileWidth;
  
            for (var sampleIndex = 0; sampleIndex < samples.length; ++sampleIndex) {
              var sample = samples[sampleIndex];
              if (this.planarConfiguration === 2) {
                bytesPerPixel = this.getSampleByteSize(sample);
              }
              var tile = new DataView(this.getTileOrStrip(xTile, yTile, sample));
  
              var reader = sampleReaders[sampleIndex];
              var ymax = Math.min(tileHeight, tileHeight - (lastLine - imageWindow[3]));
              var xmax = Math.min(tileWidth, tileWidth - (lastCol - imageWindow[2]));
              var totalbytes = (ymax * tileWidth + xmax) * bytesPerPixel;
              var tileLength = new Uint8Array(tile.buffer).length;
              if (2 * tileLength !== totalbytes && this._debugMessages) {
                console.warn('dimension mismatch', tileLength, totalbytes);
              }
              for (var y = Math.max(0, imageWindow[1] - firstLine); y < ymax; ++y) {
                for (var x = Math.max(0, imageWindow[0] - firstCol); x < xmax; ++x) {
                  var pixelOffset = (y * tileWidth + x) * bytesPerPixel;
                  var value = 0;
                  if (pixelOffset < tileLength - 1) {
                    value = reader.call(tile, pixelOffset + srcSampleOffsets[sampleIndex], this.littleEndian);
                  }
  
                  var windowCoordinate;
                  if (interleave) {
                    if (predictor !== 1 && x > 0) {
                      windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth * samples.length + (x + firstCol - imageWindow[0] - 1) * samples.length + sampleIndex;
                      value += valueArrays[windowCoordinate];
                    }
  
                    windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth * samples.length + (x + firstCol - imageWindow[0]) * samples.length + sampleIndex;
                    valueArrays[windowCoordinate] = value;
                  } else {
                    if (predictor !== 1 && x > 0) {
                      windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth + x - 1 + firstCol - imageWindow[0];
                      value += valueArrays[sampleIndex][windowCoordinate];
                    }
  
                    windowCoordinate = (y + firstLine - imageWindow[1]) * windowWidth + x + firstCol - imageWindow[0];
                    valueArrays[sampleIndex][windowCoordinate] = value;
                  }
                }
              }
            }
          }
        }
        callback(valueArrays);
        return valueArrays;
      } catch (error) {
        return callbackError(error);
      }
    },
  
    /**
     * This callback is called upon successful reading of a GeoTIFF image. The
     * resulting arrays are passed as a single argument.
     * @callback GeoTIFFImage~readCallback
     * @param {(TypedArray|TypedArray[])} array the requested data as a either a
     *                                          single typed array or a list of
     *                                          typed arrays, depending on the
     *                                          'interleave' option.
     */
  
    /**
     * This callback is called upon encountering an error while reading of a
     * GeoTIFF image
     * @callback GeoTIFFImage~readErrorCallback
     * @param {Error} error the encountered error
     */
  
    /**
     * Reads raster data from the image. This function reads all selected samples
     * into separate arrays of the correct type for that sample. When provided,
     * only a subset of the raster is read for each sample.
     *
     * @param {Object} [options] optional parameters
     * @param {Array} [options.window=whole image] the subset to read data from.
     * @param {Array} [options.samples=all samples] the selection of samples to read from.
     * @param {Boolean} [options.interleave=false] whether the data shall be read
     *                                             in one single array or separate
     *                                             arrays.
     * @param {GeoTIFFImage~readCallback} [callback] the success callback. this
     *                                               parameter is mandatory for
     *                                               asynchronous decoders (some
     *                                               compression mechanisms).
     * @param {GeoTIFFImage~readErrorCallback} [callbackError] the error callback
     * @returns {(TypedArray|TypedArray[]|null)} in synchonous cases, the decoded
     *                                           array(s) is/are returned. In
     *                                           asynchronous cases, nothing is
     *                                           returned.
     */
    readRasters: function readRasters() /* arguments are read via the 'arguments' object */{
      // parse the arguments
      var options, callback, callbackError;
      switch (arguments.length) {
        case 0:
          break;
        case 1:
          if (typeof arguments[0] === "function") {
            callback = arguments[0];
          } else {
            options = arguments[0];
          }
          break;
        case 2:
          if (typeof arguments[0] === "function") {
            callback = arguments[0];
            callbackError = arguments[1];
          } else {
            options = arguments[0];
            callback = arguments[1];
          }
          break;
        case 3:
          options = arguments[0];
          callback = arguments[1];
          callbackError = arguments[2];
          break;
        default:
          throw new Error("Invalid number of arguments passed.");
      }
  
      // set up default arguments
      options = options || {};
      callbackError = callbackError || function (error) {
        console.error(error);
      };
  
      var imageWindow = options.window || [0, 0, this.getWidth(), this.getHeight()],
          samples = options.samples,
          interleave = options.interleave;
  
      // check parameters
      if (imageWindow[0] < 0 || imageWindow[1] < 0 || imageWindow[2] > this.getWidth() || imageWindow[3] > this.getHeight()) {
        throw new Error("Select window is out of image bounds.");
      } else if (imageWindow[0] > imageWindow[2] || imageWindow[1] > imageWindow[3]) {
        throw new Error("Invalid subsets");
      }
  
      var imageWindowWidth = imageWindow[2] - imageWindow[0];
      var imageWindowHeight = imageWindow[3] - imageWindow[1];
      var numPixels = imageWindowWidth * imageWindowHeight;
      var i;
  
      if (!samples) {
        samples = [];
        for (i = 0; i < this.fileDirectory.SamplesPerPixel; ++i) {
          samples.push(i);
        }
      } else {
        for (i = 0; i < samples.length; ++i) {
          if (samples[i] >= this.fileDirectory.SamplesPerPixel) {
            throw new RangeError("Invalid sample index '" + samples[i] + "'.");
          }
        }
      }
      var valueArrays;
      if (interleave) {
        var format = this.fileDirectory.SampleFormat ? Math.max.apply(null, this.fileDirectory.SampleFormat) : 1,
            bitsPerSample = Math.max.apply(null, this.fileDirectory.BitsPerSample);
        valueArrays = arrayForType(format, bitsPerSample, numPixels * samples.length);
      } else {
        valueArrays = [];
        for (i = 0; i < samples.length; ++i) {
          valueArrays.push(this.getArrayForSample(samples[i], numPixels));
        }
      }
  
      // start reading data, sync or async
      var decoder = this.getDecoder();
      if (decoder.isAsync()) {
        if (!callback) {
          throw new Error("No callback specified for asynchronous raster reading.");
        }
        return this._readRasterAsync(imageWindow, samples, valueArrays, interleave, callback, callbackError);
      } else {
        callback = callback || function () {};
        return this._readRaster(imageWindow, samples, valueArrays, interleave, callback, callbackError);
      }
    },
  
    /**
     * Reads raster data from the image as RGB. The result is always an
     * interleaved typed array.
     * Colorspaces other than RGB will be transformed to RGB, color maps expanded.
     * When no other method is applicable, the first sample is used to produce a
     * greayscale image.
     * When provided, only a subset of the raster is read for each sample.
     *
     * @param {Object} [options] optional parameters
     * @param {Array} [options.window=whole image] the subset to read data from.
     * @param {GeoTIFFImage~readCallback} callback the success callback. this
     *                                             parameter is mandatory.
     * @param {GeoTIFFImage~readErrorCallback} [callbackError] the error callback
     */
    readRGB: function readRGB() {
      // parse the arguments
      var options = null,
          callback = null,
          callbackError = null;
      switch (arguments.length) {
        case 0:
          break;
        case 1:
          if (typeof arguments[0] === "function") {
            callback = arguments[0];
          } else {
            options = arguments[0];
          }
          break;
        case 2:
          if (typeof arguments[0] === "function") {
            callback = arguments[0];
            callbackError = arguments[1];
          } else {
            options = arguments[0];
            callback = arguments[1];
          }
          break;
        case 3:
          options = arguments[0];
          callback = arguments[1];
          callbackError = arguments[2];
          break;
        default:
          throw new Error("Invalid number of arguments passed.");
      }
  
      // set up default arguments
      options = options || {};
      callbackError = callbackError || function (error) {
        console.error(error);
      };
  
      var imageWindow = options.window || [0, 0, this.getWidth(), this.getHeight()];
  
      // check parameters
      if (imageWindow[0] < 0 || imageWindow[1] < 0 || imageWindow[2] > this.getWidth() || imageWindow[3] > this.getHeight()) {
        throw new Error("Select window is out of image bounds.");
      } else if (imageWindow[0] > imageWindow[2] || imageWindow[1] > imageWindow[3]) {
        throw new Error("Invalid subsets");
      }
  
      var width = imageWindow[2] - imageWindow[0];
      var height = imageWindow[3] - imageWindow[1];
  
      var pi = this.fileDirectory.PhotometricInterpretation;
  
      var bits = this.fileDirectory.BitsPerSample[0];
      var max = Math.pow(2, bits);
  
      if (pi === globals.photometricInterpretations.RGB) {
        return this.readRasters({
          window: options.window,
          interleave: true
        }, callback, callbackError);
      }
  
      var samples;
      switch (pi) {
        case globals.photometricInterpretations.WhiteIsZero:
        case globals.photometricInterpretations.BlackIsZero:
        case globals.photometricInterpretations.Palette:
          samples = [0];
          break;
        case globals.photometricInterpretations.CMYK:
          samples = [0, 1, 2, 3];
          break;
        case globals.photometricInterpretations.YCbCr:
        case globals.photometricInterpretations.CIELab:
          samples = [0, 1, 2];
          break;
        default:
          throw new Error("Invalid or unsupported photometric interpretation.");
      }
  
      var subOptions = {
        window: options.window,
        interleave: true,
        samples: samples
      };
      var fileDirectory = this.fileDirectory;
      return this.readRasters(subOptions, function (raster) {
        switch (pi) {
          case globals.photometricInterpretations.WhiteIsZero:
            return callback(RGB.fromWhiteIsZero(raster, max, width, height));
          case globals.photometricInterpretations.BlackIsZero:
            return callback(RGB.fromBlackIsZero(raster, max, width, height));
          case globals.photometricInterpretations.Palette:
            return callback(RGB.fromPalette(raster, fileDirectory.ColorMap, width, height));
          case globals.photometricInterpretations.CMYK:
            return callback(RGB.fromCMYK(raster, width, height));
          case globals.photometricInterpretations.YCbCr:
            return callback(RGB.fromYCbCr(raster, width, height));
          case globals.photometricInterpretations.CIELab:
            return callback(RGB.fromCIELab(raster, width, height));
        }
      }, callbackError);
    },
  
    /**
     * Returns an array of tiepoints.
     * @returns {Object[]}
     */
    getTiePoints: function getTiePoints() {
      if (!this.fileDirectory.ModelTiepoint) {
        return [];
      }
  
      var tiePoints = [];
      for (var i = 0; i < this.fileDirectory.ModelTiepoint.length; i += 6) {
        tiePoints.push({
          i: this.fileDirectory.ModelTiepoint[i],
          j: this.fileDirectory.ModelTiepoint[i + 1],
          k: this.fileDirectory.ModelTiepoint[i + 2],
          x: this.fileDirectory.ModelTiepoint[i + 3],
          y: this.fileDirectory.ModelTiepoint[i + 4],
          z: this.fileDirectory.ModelTiepoint[i + 5]
        });
      }
      return tiePoints;
    },
  
    /**
     * Returns the parsed GDAL metadata items.
     * @returns {Object}
     */
    getGDALMetadata: function getGDALMetadata() {
      var metadata = {};
      if (!this.fileDirectory.GDAL_METADATA) {
        return null;
      }
      var string = this.fileDirectory.GDAL_METADATA;
      var xmlDom = globals.parseXml(string.substring(0, string.length - 1));
      var result = xmlDom.evaluate("GDALMetadata/Item", xmlDom, null, XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE, null);
      for (var i = 0; i < result.snapshotLength; ++i) {
        var node = result.snapshotItem(i);
        metadata[node.getAttribute("name")] = node.textContent;
      }
      return metadata;
    },
  
    /**
     * Returns the image origin as a XYZ-vector. When the image has no affine
     * transformation, then an exception is thrown.
     * @returns {Array} The origin as a vector
     */
    getOrigin: function getOrigin() {
      var tiePoints = this.fileDirectory.ModelTiepoint;
      if (!tiePoints || tiePoints.length !== 6) {
        throw new Error("The image does not have an affine transformation.");
      }
  
      return [tiePoints[3], tiePoints[4], tiePoints[5]];
    },
  
    /**
     * Returns the image resolution as a XYZ-vector. When the image has no affine
     * transformation, then an exception is thrown.
     * @returns {Array} The resolution as a vector
     */
    getResolution: function getResolution() {
      if (!this.fileDirectory.ModelPixelScale) {
        throw new Error("The image does not have an affine transformation.");
      }
  
      return [this.fileDirectory.ModelPixelScale[0], this.fileDirectory.ModelPixelScale[1], this.fileDirectory.ModelPixelScale[2]];
    },
  
    /**
     * Returns whether or not the pixels of the image depict an area (or point).
     * @returns {Boolean} Whether the pixels are a point
     */
    pixelIsArea: function pixelIsArea() {
      return this.geoKeys.GTRasterTypeGeoKey === 1;
    },
  
    /**
     * Returns the image bounding box as an array of 4 values: min-x, min-y,
     * max-x and max-y. When the image has no affine transformation, then an
     * exception is thrown.
     * @returns {Array} The bounding box
     */
    getBoundingBox: function getBoundingBox() {
      var origin = this.getOrigin();
      var resolution = this.getResolution();
  
      var x1 = origin[0];
      var y1 = origin[1];
  
      var x2 = x1 + resolution[0] * this.getWidth();
      var y2 = y1 + resolution[1] * this.getHeight();
  
      return [Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2)];
    }
  };
  
  module.exports = GeoTIFFImage;
  
  },{"./compression/deflate.js":14,"./compression/lzw.js":15,"./compression/packbits.js":16,"./compression/raw.js":17,"./globals.js":21,"./rgb.js":23}],21:[function(require,module,exports){
  "use strict";
  
  var fieldTagNames = {
    // TIFF Baseline
    0x013B: 'Artist',
    0x0102: 'BitsPerSample',
    0x0109: 'CellLength',
    0x0108: 'CellWidth',
    0x0140: 'ColorMap',
    0x0103: 'Compression',
    0x8298: 'Copyright',
    0x0132: 'DateTime',
    0x0152: 'ExtraSamples',
    0x010A: 'FillOrder',
    0x0121: 'FreeByteCounts',
    0x0120: 'FreeOffsets',
    0x0123: 'GrayResponseCurve',
    0x0122: 'GrayResponseUnit',
    0x013C: 'HostComputer',
    0x010E: 'ImageDescription',
    0x0101: 'ImageLength',
    0x0100: 'ImageWidth',
    0x010F: 'Make',
    0x0119: 'MaxSampleValue',
    0x0118: 'MinSampleValue',
    0x0110: 'Model',
    0x00FE: 'NewSubfileType',
    0x0112: 'Orientation',
    0x0106: 'PhotometricInterpretation',
    0x011C: 'PlanarConfiguration',
    0x0128: 'ResolutionUnit',
    0x0116: 'RowsPerStrip',
    0x0115: 'SamplesPerPixel',
    0x0131: 'Software',
    0x0117: 'StripByteCounts',
    0x0111: 'StripOffsets',
    0x00FF: 'SubfileType',
    0x0107: 'Threshholding',
    0x011A: 'XResolution',
    0x011B: 'YResolution',
  
    // TIFF Extended
    0x0146: 'BadFaxLines',
    0x0147: 'CleanFaxData',
    0x0157: 'ClipPath',
    0x0148: 'ConsecutiveBadFaxLines',
    0x01B1: 'Decode',
    0x01B2: 'DefaultImageColor',
    0x010D: 'DocumentName',
    0x0150: 'DotRange',
    0x0141: 'HalftoneHints',
    0x015A: 'Indexed',
    0x015B: 'JPEGTables',
    0x011D: 'PageName',
    0x0129: 'PageNumber',
    0x013D: 'Predictor',
    0x013F: 'PrimaryChromaticities',
    0x0214: 'ReferenceBlackWhite',
    0x0153: 'SampleFormat',
    0x0154: 'SMinSampleValue',
    0x0155: 'SMaxSampleValue',
    0x022F: 'StripRowCounts',
    0x014A: 'SubIFDs',
    0x0124: 'T4Options',
    0x0125: 'T6Options',
    0x0145: 'TileByteCounts',
    0x0143: 'TileLength',
    0x0144: 'TileOffsets',
    0x0142: 'TileWidth',
    0x012D: 'TransferFunction',
    0x013E: 'WhitePoint',
    0x0158: 'XClipPathUnits',
    0x011E: 'XPosition',
    0x0211: 'YCbCrCoefficients',
    0x0213: 'YCbCrPositioning',
    0x0212: 'YCbCrSubSampling',
    0x0159: 'YClipPathUnits',
    0x011F: 'YPosition',
  
    // EXIF
    0x9202: 'ApertureValue',
    0xA001: 'ColorSpace',
    0x9004: 'DateTimeDigitized',
    0x9003: 'DateTimeOriginal',
    0x8769: 'Exif IFD',
    0x9000: 'ExifVersion',
    0x829A: 'ExposureTime',
    0xA300: 'FileSource',
    0x9209: 'Flash',
    0xA000: 'FlashpixVersion',
    0x829D: 'FNumber',
    0xA420: 'ImageUniqueID',
    0x9208: 'LightSource',
    0x927C: 'MakerNote',
    0x9201: 'ShutterSpeedValue',
    0x9286: 'UserComment',
  
    // IPTC
    0x83BB: 'IPTC',
  
    // ICC
    0x8773: 'ICC Profile',
  
    // XMP
    0x02BC: 'XMP',
  
    // GDAL
    0xA480: 'GDAL_METADATA',
    0xA481: 'GDAL_NODATA',
  
    // Photoshop
    0x8649: 'Photoshop',
  
    // GeoTiff
    0x830E: 'ModelPixelScale',
    0x8482: 'ModelTiepoint',
    0x85D8: 'ModelTransformation',
    0x87AF: 'GeoKeyDirectory',
    0x87B0: 'GeoDoubleParams',
    0x87B1: 'GeoAsciiParams'
  };
  
  var key;
  var fieldTags = {};
  for (key in fieldTagNames) {
    fieldTags[fieldTagNames[key]] = parseInt(key);
  }
  
  var arrayFields = [fieldTags.BitsPerSample, fieldTags.ExtraSamples, fieldTags.SampleFormat, fieldTags.StripByteCounts, fieldTags.StripOffsets, fieldTags.StripRowCounts, fieldTags.TileByteCounts, fieldTags.TileOffsets];
  
  var fieldTypeNames = {
    0x0001: 'BYTE',
    0x0002: 'ASCII',
    0x0003: 'SHORT',
    0x0004: 'LONG',
    0x0005: 'RATIONAL',
    0x0006: 'SBYTE',
    0x0007: 'UNDEFINED',
    0x0008: 'SSHORT',
    0x0009: 'SLONG',
    0x000A: 'SRATIONAL',
    0x000B: 'FLOAT',
    0x000C: 'DOUBLE',
    // introduced by BigTIFF
    0x0010: 'LONG8',
    0x0011: 'SLONG8',
    0x0012: 'IFD8'
  };
  
  var fieldTypes = {};
  for (key in fieldTypeNames) {
    fieldTypes[fieldTypeNames[key]] = parseInt(key);
  }
  
  var photometricInterpretations = {
    WhiteIsZero: 0,
    BlackIsZero: 1,
    RGB: 2,
    Palette: 3,
    TransparencyMask: 4,
    CMYK: 5,
    YCbCr: 6,
  
    CIELab: 8,
    ICCLab: 9
  };
  
  var geoKeyNames = {
    1024: 'GTModelTypeGeoKey',
    1025: 'GTRasterTypeGeoKey',
    1026: 'GTCitationGeoKey',
    2048: 'GeographicTypeGeoKey',
    2049: 'GeogCitationGeoKey',
    2050: 'GeogGeodeticDatumGeoKey',
    2051: 'GeogPrimeMeridianGeoKey',
    2052: 'GeogLinearUnitsGeoKey',
    2053: 'GeogLinearUnitSizeGeoKey',
    2054: 'GeogAngularUnitsGeoKey',
    2055: 'GeogAngularUnitSizeGeoKey',
    2056: 'GeogEllipsoidGeoKey',
    2057: 'GeogSemiMajorAxisGeoKey',
    2058: 'GeogSemiMinorAxisGeoKey',
    2059: 'GeogInvFlatteningGeoKey',
    2060: 'GeogAzimuthUnitsGeoKey',
    2061: 'GeogPrimeMeridianLongGeoKey',
    2062: 'GeogTOWGS84GeoKey',
    3072: 'ProjectedCSTypeGeoKey',
    3073: 'PCSCitationGeoKey',
    3074: 'ProjectionGeoKey',
    3075: 'ProjCoordTransGeoKey',
    3076: 'ProjLinearUnitsGeoKey',
    3077: 'ProjLinearUnitSizeGeoKey',
    3078: 'ProjStdParallel1GeoKey',
    3079: 'ProjStdParallel2GeoKey',
    3080: 'ProjNatOriginLongGeoKey',
    3081: 'ProjNatOriginLatGeoKey',
    3082: 'ProjFalseEastingGeoKey',
    3083: 'ProjFalseNorthingGeoKey',
    3084: 'ProjFalseOriginLongGeoKey',
    3085: 'ProjFalseOriginLatGeoKey',
    3086: 'ProjFalseOriginEastingGeoKey',
    3087: 'ProjFalseOriginNorthingGeoKey',
    3088: 'ProjCenterLongGeoKey',
    3089: 'ProjCenterLatGeoKey',
    3090: 'ProjCenterEastingGeoKey',
    3091: 'ProjCenterNorthingGeoKey',
    3092: 'ProjScaleAtNatOriginGeoKey',
    3093: 'ProjScaleAtCenterGeoKey',
    3094: 'ProjAzimuthAngleGeoKey',
    3095: 'ProjStraightVertPoleLongGeoKey',
    3096: 'ProjRectifiedGridAngleGeoKey',
    4096: 'VerticalCSTypeGeoKey',
    4097: 'VerticalCitationGeoKey',
    4098: 'VerticalDatumGeoKey',
    4099: 'VerticalUnitsGeoKey'
  };
  
  var geoKeys = {};
  for (key in geoKeyNames) {
    geoKeys[geoKeyNames[key]] = parseInt(key);
  }
  
  var parseXml;
  // node.js version
  if (typeof window === "undefined") {
    parseXml = function parseXml(xmlStr) {
      // requires xmldom module
      var DOMParser = require('xmldom').DOMParser;
      return new DOMParser().parseFromString(xmlStr, "text/xml");
    };
  } else if (typeof window.DOMParser !== "undefined") {
    parseXml = function parseXml(xmlStr) {
      return new window.DOMParser().parseFromString(xmlStr, "text/xml");
    };
  } else if (typeof window.ActiveXObject !== "undefined" && new window.ActiveXObject("Microsoft.XMLDOM")) {
    parseXml = function parseXml(xmlStr) {
      var xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
      xmlDoc.async = "false";
      xmlDoc.loadXML(xmlStr);
      return xmlDoc;
    };
  }
  
  module.exports = {
    fieldTags: fieldTags,
    fieldTagNames: fieldTagNames,
    arrayFields: arrayFields,
    fieldTypes: fieldTypes,
    fieldTypeNames: fieldTypeNames,
    photometricInterpretations: photometricInterpretations,
    geoKeys: geoKeys,
    geoKeyNames: geoKeyNames,
    parseXml: parseXml
  };
  
  },{"xmldom":"xmldom"}],22:[function(require,module,exports){
  "use strict";
  
  var GeoTIFF = require("./geotiff.js");
  
  /**
   * Main parsing function for GeoTIFF files.
   * @param {(string|ArrayBuffer)} data Raw data to parse the GeoTIFF from.
   * @param {Object} [options] further options.
   * @param {Boolean} [options.cache=false] whether or not decoded tiles shall be cached.
   * @returns {GeoTIFF} the parsed geotiff file.
   */
  var parse = function parse(data, options) {
    var rawData, i, strLen, view;
    if (typeof data === "string" || data instanceof String) {
      rawData = new ArrayBuffer(data.length * 2); // 2 bytes for each char
      view = new Uint16Array(rawData);
      for (i = 0, strLen = data.length; i < strLen; ++i) {
        view[i] = data.charCodeAt(i);
      }
    } else if (data instanceof ArrayBuffer) {
      rawData = data;
    } else {
      throw new Error("Invalid input data given.");
    }
    return new GeoTIFF(rawData, options);
  };
  
  if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
    module.exports.parse = parse;
  }
  if (typeof window !== "undefined") {
    window["GeoTIFF"] = { parse: parse };
  }
  
  },{"./geotiff.js":19}],23:[function(require,module,exports){
  "use strict";
  
  function fromWhiteIsZero(raster, max, width, height) {
    var rgbRaster = new Uint8Array(width * height * 3);
    var value;
    for (var i = 0, j = 0; i < raster.length; ++i, j += 3) {
      value = 256 - raster[i] / max * 256;
      rgbRaster[j] = value;
      rgbRaster[j + 1] = value;
      rgbRaster[j + 2] = value;
    }
    return rgbRaster;
  }
  
  function fromBlackIsZero(raster, max, width, height) {
    var rgbRaster = new Uint8Array(width * height * 3);
    var value;
    for (var i = 0, j = 0; i < raster.length; ++i, j += 3) {
      value = raster[i] / max * 256;
      rgbRaster[j] = value;
      rgbRaster[j + 1] = value;
      rgbRaster[j + 2] = value;
    }
    return rgbRaster;
  }
  
  function fromPalette(raster, colorMap, width, height) {
    var rgbRaster = new Uint8Array(width * height * 3);
    var greenOffset = colorMap.length / 3;
    var blueOffset = colorMap.length / 3 * 2;
    for (var i = 0, j = 0; i < raster.length; ++i, j += 3) {
      var mapIndex = raster[i];
      rgbRaster[j] = colorMap[mapIndex] / 65536 * 256;
      rgbRaster[j + 1] = colorMap[mapIndex + greenOffset] / 65536 * 256;
      rgbRaster[j + 2] = colorMap[mapIndex + blueOffset] / 65536 * 256;
    }
    return rgbRaster;
  }
  
  function fromCMYK(cmykRaster, width, height) {
    var rgbRaster = new Uint8Array(width * height * 3);
    var c, m, y, k;
    for (var i = 0, j = 0; i < cmykRaster.length; i += 4, j += 3) {
      c = cmykRaster[i];
      m = cmykRaster[i + 1];
      y = cmykRaster[i + 2];
      k = cmykRaster[i + 3];
  
      rgbRaster[j] = 255 * ((255 - c) / 256) * ((255 - k) / 256);
      rgbRaster[j + 1] = 255 * ((255 - m) / 256) * ((255 - k) / 256);
      rgbRaster[j + 2] = 255 * ((255 - y) / 256) * ((255 - k) / 256);
    }
    return rgbRaster;
  }
  
  function fromYCbCr(yCbCrRaster, width, height) {
    var rgbRaster = new Uint8Array(width * height * 3);
    var y, cb, cr;
    for (var i = 0, j = 0; i < yCbCrRaster.length; i += 3, j += 3) {
      y = yCbCrRaster[i];
      cb = yCbCrRaster[i + 1];
      cr = yCbCrRaster[i + 2];
  
      rgbRaster[j] = y + 1.40200 * (cr - 0x80);
      rgbRaster[j + 1] = y - 0.34414 * (cb - 0x80) - 0.71414 * (cr - 0x80);
      rgbRaster[j + 2] = y + 1.77200 * (cb - 0x80);
    }
    return rgbRaster;
  }
  
  // converted from here:
  // http://de.mathworks.com/matlabcentral/fileexchange/24010-lab2rgb/content/Lab2RGB.m
  // still buggy
  function fromCIELab(cieLabRaster, width, height) {
    var T1 = 0.008856;
    var T2 = 0.206893;
    var MAT = [3.240479, -1.537150, -0.498535, -0.969256, 1.875992, 0.041556, 0.055648, -0.204043, 1.057311];
    var rgbRaster = new Uint8Array(width * height * 3);
    var L, a, b;
    var fX, fY, fZ, XT, YT, ZT, X, Y, Z;
    for (var i = 0, j = 0; i < cieLabRaster.length; i += 3, j += 3) {
      L = cieLabRaster[i];
      a = cieLabRaster[i + 1];
      b = cieLabRaster[i + 2];
  
      // Compute Y
      fY = Math.pow((L + 16) / 116, 3);
      YT = fY > T1;
      fY = (YT !== 0) * (L / 903.3) + YT * fY;
      Y = fY;
  
      fY = YT * Math.pow(fY, 1 / 3) + (YT !== 0) * (7.787 * fY + 16 / 116);
  
      // Compute X
      fX = a / 500 + fY;
      XT = fX > T2;
      X = XT * Math.pow(fX, 3) + (XT !== 0) * ((fX - 16 / 116) / 7.787);
  
      // Compute Z
      fZ = fY - b / 200;
      ZT = fZ > T2;
      Z = ZT * Math.pow(fZ, 3) + (ZT !== 0) * ((fZ - 16 / 116) / 7.787);
  
      // Normalize for D65 white point
      X = X * 0.950456;
      Z = Z * 1.088754;
  
      rgbRaster[j] = X * MAT[0] + Y * MAT[1] + Z * MAT[2];
      rgbRaster[j + 1] = X * MAT[3] + Y * MAT[4] + Z * MAT[5];
      rgbRaster[j + 2] = X * MAT[6] + Y * MAT[7] + Z * MAT[8];
    }
    return rgbRaster;
  }
  
  module.exports = {
    fromWhiteIsZero: fromWhiteIsZero,
    fromBlackIsZero: fromBlackIsZero,
    fromPalette: fromPalette,
    fromCMYK: fromCMYK,
    fromYCbCr: fromYCbCr,
    fromCIELab: fromCIELab
  };
  
  },{}]},{},[22]);
    