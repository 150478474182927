<template lang="pug">
  v-timeline(class="meteo-timeline custom-scroll" align-top dense)
    v-timeline-item(
      class="pb-3"
      v-for="(item, index) in dates"
      color="blue lighten-1"
      small
      :key="index")
      v-row(class="pt-1")
        v-col(cols="5")
          strong {{item.slice(11)}}
        v-col
          strong {{item.slice(0,10)}}
          div(
            v-for="el in selectedVars"
            class="text-caption"
            :set="cVar= getVar(index, el)")
            div(:title="cVar.name")
              span <b>{{cVar.name}}:</b>
              span(class="ml-2") {{cVar.value}} {{cVar.unit}}

</template>

<script>

export default {
  data(instance) {
    const { dates, variables, selectedVars } = instance.$attrs;
    return {
      dates,
      variables,
      selectedVars,
    };
  },
  methods: {
    getVar(index, cVar) {
      const netVar = this.variables.filter((x) => x.variable === cVar)[0];
      return {
        name: netVar.name,
        value: netVar.data[index].toFixed(3),
        unit: netVar.unit
      };
    }
  },
};
</script>

<style scoped>
  .meteo-timeline {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* width */
  .custom-scroll::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
