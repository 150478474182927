import { MD5 } from 'crypto-js';
import SubscriptionService from '@/services/SubscriptionService.js';
import { makeId } from '../../utils.js';
import {
  frontServerUrl, payUWebcheckout, merchantId, accountId, ApiKey, confirmationUrl, paymentCurrency
} from '../../config.js';

const subscription = {
  namespaced: true,
  state: {
    rates: [],
    payUWebcheckout,
    merchantId,
    accountId,
    referenceCode: '',
    currency: paymentCurrency,
    buyerEmail: '',
    buyerName: '',
    responseUrl: `${frontServerUrl}payu/response`,
    confirmationUrl,
    signature: '',
    description: 'Compra de aplicaciones climaticas meteo-colombia',
    ApiKey,
    amount: 0,
    paymentMessage: '',
    paymentStatus: '',
    credits: [],
    userInfo: {},
    paidVersion: -1,
    filteredCities: [],
    siigoIdentifications: [],
    serviceAmount: 0,
    tax: 0,
    taxReturnBase: 0,
    serviceProducts: [],
    serviceValid: false,
  },
  mutations: {
    SET_VALUE(state, { name, value }) {
      state[name] = value;
    },
    APPEND(state, appId) {
      const app = state.rates.filter((x) => x.app.id === appId)[0];
      app.count = Number(Number.isNaN(app.count) ? 0 : app.count) + 1;
      app.count = app.count < 0 ? 0 : app.count;
      return true;
    },
    SUBTRACT(state, appId) {
      const app = state.rates.filter((x) => x.app.id === appId)[0];
      if (app.count === 0) return true;
      app.count = Number(Number.isNaN(app.count) ? 0 : app.count) - 1;
      app.count = app.count < 0 ? 0 : app.count;
      return true;
    },
    CALCULATE_SIGNATURE(state, amount) {
      state.amount = amount;
      // hacer el pedido de venta unico
      console.log('el codigo de referencia', state.referenceCode);
      console.log('entro CALCULATE_SIGNATURE', amount);
      const sigStr = `${state.ApiKey}~${state.merchantId}~${state.referenceCode}~${amount}~${state.currency}`;
      console.log(sigStr);
      const newSig = MD5(sigStr).toString();
      console.log(newSig);
      state.signature = newSig;
    },
    CREATE_REF_CODE(state) {
      state.referenceCode = makeId(8);
    },
    SET_PAID_VERSION(state, value) {
      state.paidVersion = value;
    },
    CLEAR_CITIES(state) {
      state.filteredCities = [];
    }
  },
  actions: {
    getRates({ state }) {
      return SubscriptionService.getRates()
        .then((resp) => {
          state.rates = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    savePayment({ state }) {
      const purchasedApps = state.rates.filter((x) => x.count !== 0);
      return SubscriptionService.savePayment(state.referenceCode, purchasedApps)
        .then((resp) => {
          console.log(resp);
          return 'success';
        })
        .catch((err) => `Ocurrio un error: ${err.response.status} - ${err.response.data.error}`);
    },
    validatePayment({ state }, refCode) {
      return SubscriptionService.validatePayment(refCode)
        .then((resp) => {
          console.log(resp);
          state.paymentStatus = resp.status;
          state.paymentMessage = resp.data.message;
          return 'success';
        })
        .catch((err) => {
          state.paymentMessage = err.response.data.message;
          state.paymentStatus = err.response.status;
        });
    },
    getCredits({ state }) {
      return SubscriptionService.getCredits()
        .then((resp) => {
          state.credits = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getUserInfo({ state }) {
      return SubscriptionService.getUserInfo()
        .then((resp) => {
          console.log('la información del usuario es:', resp);
          state.userInfo = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCities({ state }, query) {
      return SubscriptionService.getCities(query)
        .then((resp) => {
          state.filteredCities = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSiigoIdentifications({ state }) {
      return SubscriptionService.getSiigoIdentifications()
        .then((resp) => {
          state.siigoIdentifications = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  getters: {
    apps: (state) => state.rates.filter((x) => x.app.type !== 'webgis'),
    webGis: (state) => state.rates.filter((x) => x.app.type === 'webgis'),
    totalCredits: (state) => state
      .rates
      .map((x) => x.count)
      .reduce((a, b) => Number(a) + Number(b), 0),
    totalPayment: (state) => state.rates.map((x) => x.count * x.price).reduce((a, b) => a + b, 0),
    purchasedApps: (state) => state.rates.filter((x) => x.count !== 0),
  }
};

export default subscription;
