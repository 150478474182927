import Vue from 'vue';
// Leaflet
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';

// dependencia para el grafico de Barbas
import HighchartsVue from 'highcharts-vue';

// generar imagenes desde un elemento html
import VueHtml2Canvas from 'vue-html2canvas';
import App from './App.vue';

// Vuetify
import vuetify from './plugins/vuetify';

// Vue-router
import router from './router';

// Vuex
import store from './store';

import 'leaflet/dist/leaflet.css';

// configuracion del bus de eventos
import eventBus from './plugins/event-bus';
// plugin para cargar mapas y particulas
import canvasLayerPlugin from '@/plugins/canvas-leflet.js';
// plugin para el capcha con las formas
import jqCaptchaPlugin from '@/plugins/jq-captcha.js';

// Estilos sondeos stuve y skewt
import '../public/resources/libs/skewt.css';
// estilos meteocolombia
import './assets/css/main.css';

// resolver error en recarga automatica desarrollo
import 'regenerator-runtime';

// Registro de los plugins
Vue.use(HighchartsVue);
Vue.use(eventBus);
Vue.use(canvasLayerPlugin);
Vue.use(jqCaptchaPlugin);
Vue.use(VueHtml2Canvas);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

// Registrar componentes de Leaflet
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
