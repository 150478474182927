<template lang="pug">
  div(class="ramp-container")
    div(class="gradient mr-1 mt-1 ml-1")
      span(class="grad-step" v-for="color in colors" :style="{background: color}")
    div(v-if="showName" class="ramp-name mt-1") {{rampName}}
</template>

<script>
import * as chroma from 'chroma-js';

export default {
  name: 'ColorRamp',
  props: {
    rampName: {
      type: String,
      default: 'OrRd'
    },
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      colors: []
    };
  },
  watch: {
    rampName(newValue) {
      this.colors = chroma.scale(newValue).colors(100);
    }
  },
  created() {
    this.colors = chroma.scale(this.rampName).colors(100);
  },
};
</script>

<style scoped>
.ramp-container {
  display: flex;
}

.gradient {
  width: 97%;
}

.grad-step {
  display: inline-block;
  height: 20px;
  width: 1%;
}

.ramp-name {
  font-size: 13px;
}

</style>
