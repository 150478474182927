import { initialVariableLevel } from '../../config.js';
import UiService from '@/services/UiService.js';

const ui = {
  namespaced: true,
  state: {
    isLoading: true,
    showAlert: false,
    alertMessage: '',
    alertType: 'info',
    appsBox: [],
    showDialogForm: false,
    showFaqs: false,
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.isLoading = !state.isLoading;
    },
    TOGGLE_FORM_DIALOG(state) {
      state.showDialogForm = !state.showDialogForm;
      if (!state.showDialogForm) {
        state.showFaqs = false;
      }
    },
    SET_UI_ITEM(state, { paramName, paramValue }) {
      state[paramName] = paramValue;
    },
    SHOW_ALERT(state, { message, level }) {
      state.alertMessage = message;
      state.alertType = level;
      state.showAlert = true;
      setTimeout(() => { state.showAlert = false; }, 5000);
    },
    SHOW_FAQS(state) {
      console.log('entro mutacion SHOW_FAQS');
      state.showFaqs = true;
    },
  },
  actions: {
    toggleLoading({ commit }) {
      commit('TOGGLE_LOADING');
    },
    getAppCategories({ state, commit }) {
      return UiService.getAppCategories()
        .then((resp) => {
          state.appsBox = resp.data;
        })
        .catch((err) => {
          console.error(err);
          commit('SHOW_ALERT', {
            message: 'Error al cargar las aplicaciones de Meteocolombia',
            level: 'error'
          });
        });
    },
  },
  getters: {
    paidVersion: (state) => (appId) => {
      let app = null;
      for (let i = 0; i < state.appsBox.length; i++) {
        const { apps } = state.appsBox[i];
        for (let j = 0; j < apps.length; j++) {
          app = apps[j];
          if (app.id === appId) {
            return app;
          }
        }
      }
      return app;
    }
  },
  modules: {
    layout: {
      namespaced: true,
      state: {
        avalaibleCountries: {
          colombia: {
            name: 'Colombia',
            flag: 'Colombia.png',
            mapLat: 5.550381,
            mapLon: -73.564453,
            zoom: 5,
          },
          peru: {
            name: 'Peru',
            flag: 'Peru.png',
            mapLat: -9.752370,
            mapLon: -75.629883,
            zoom: 5,
          },
          argentina: {
            name: 'Argentina',
            flag: 'Argentina.png',
            mapLat: -37.926868,
            mapLon: -66.181641,
            zoom: 5,
          },
          bolivia: {
            name: 'Bolivia',
            flag: 'Bolivia.png',
            mapLat: -17.203770,
            mapLon: -64.929199,
            zoom: 5,
          },
          brasil: {
            name: 'Brasil',
            flag: 'Brazil.png',
            mapLat: -12.297068,
            mapLon: -51.943359,
            zoom: 5,
          },
          chile: {
            name: 'Chile',
            flag: 'Chile.png',
            mapLat: -12.297068,
            mapLon: -51.943359,
            zoom: 5,
          },
          ecuador: {
            name: 'Ecuador',
            flag: 'Ecuador.png',
            mapLat: -12.297068,
            mapLon: -51.943359,
            zoom: 5,
          },
          venezuela: {
            name: 'Venezuela',
            flag: 'Venezuela.png',
            mapLat: -12.297068,
            mapLon: -51.943359,
            zoom: 5,
          },
        }
      },
    },
    leaflet: {
      namespaced: true,
      state: {
        loadingData: false,
        alertMessage: 'leaflet cargado correctamente',
        showAlert: false,
        alertStatus: 'info',
        alertTimeout: 3000,
        netCDFVariables: [],
        netCDFPeriods: [],
        netCDFLevels: [],
        selectedVariable: {},
        selectedPeriod: null,
        selectedLevel: initialVariableLevel,
        configVariablesControl: null,
        currentAppRate: {},
        cuts: {},
      },
      mutations: {
        TOGGLE_LOADING(state) {
          state.loadingData = !state.loadingData;
        },
        SHOW_ALERT(state, { status, message }) {
          state.showAlert = true;
          state.alertStatus = status;
          state.alertMessage = message;
          setTimeout(() => {
            state.showAlert = false;
          }, state.alertTimeout);
        },
        SET_NETCDF_PARAMS(state, netCDFData) {
          state.netCDFVariables = netCDFData.variables;
          state.netCDFPeriods = netCDFData.dates;
          state.netCDFLevels = netCDFData.levels;
        },
        SET_NETCDF_STATE(state, { stateName, stateValue }) {
          state[stateName] = stateValue;
        },
        SET_INITIAL_VARIABLE(state) {
          const variables2D = state.netCDFVariables.filter((x) => x.type3D === false);
          if (variables2D.length > 0) {
            // si existen variables 2D elegir la primera de las variables filtradas
            [state.selectedVariable] = variables2D;
          } else if (state.netCDFVariables.length > 0) {
            // si no existen variables 2D elegir la primera de la lista inicial
            [state.selectedVariable] = state.netCDFVarqiables;
          } else {
            state.selectedVariable = {};
          }
        }
      },
      actions: {
        setNetCDFState({ commit, state }, { stateName, stateValue }) {
          if (stateName === 'selectedVariable') {
            const selVar = state.netCDFVariables.filter((x) => x.variable === stateValue);
            [stateValue] = selVar;
          }
          commit('SET_NETCDF_STATE', { stateName, stateValue });
          return state[stateName];
        },
        getAppParams({ state, commit, rootState }, app) {
          return UiService.getAppParams(app)
            .then((resp) => {
              if (resp.data) {
                // actualizar las capas base del mapa leaflet
                if ('base_layers' in resp.data) {
                  commit('gis/UPDATE_BASE_LAYERS', resp.data.base_layers, { root: true });
                  if (resp.data.base_layers.length === 0) {
                    commit('gis/RESTORE_BASE_LAYERS', null, { root: true });
                  }
                } else {
                  commit('gis/RESTORE_BASE_LAYERS', null, { root: true });
                }
                // actualizar las variables netcdf
                const forecastData = resp.data.forecast_data;
                state.netCDFVariables = forecastData.variables;
                state.netCDFPeriods = forecastData.dates;
                state.netCDFLevels = forecastData.levels;
                state.cuts = resp.data.cuts;
                const coords = resp.data.central_point.coordinates;
                commit('gis/CHANGE_MAP_LOCATION', {
                  center: [coords[1], coords[0]],
                  zoom: resp.data.zoom
                }, { root: true });
                rootState.gis.baselayers = resp.data.base_layer;
                rootState.gis.appModules = resp.data.modules;
                state.currentAppRate = resp.data.rate;
                // Setear el control de las variables
                [state.configVariablesControl] = forecastData.module_variables;
                const { rate } = resp.data;
                state.currentAppRate = rate;
                // Asignar la version de pago si existe
                const paidVersion = resp.data.paid_version;
                if (paidVersion.length > 0) {
                  [rootState.subscription.paidVersion] = paidVersion;
                } else {
                  rootState.subscription.paidVersion = -1;
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },
    },
  }
};

export default ui;
