<template lang="pug">
div(class="control-internal-content mt-2")
  v-select(
    v-if="configVariablesControl.use_variables"
    class="mt-2"
    v-model="selectedVariable"
    :items="netCDFVariables"
    item-text="name"
    item-value="variable"
    label="Seleccione la variable"
    outlined
    dense)
  v-select(
    v-if="configVariablesControl.use_periods"
    class="mt-2"
    v-model="selectedPeriod"
    :items="netCDFPeriods"
    label="Seleccione la hora"
    outlined
    dense)
  v-select(
    v-if="selectedVariable.type3D && configVariablesControl.use_levels"
    class="mt-2"
    v-model="selectedLevel"
    :items="netCDFLevels"
    label="Seleccione el nivel (mbar)"
    outlined
    dense)
  v-form(ref="form" v-model="valid" lazy-validation)
    v-select(
      v-for="s, index in platformSelects"
      :label="s.label"
      :items="s.items"
      outlined
      item-text="name"
      item-value="value"
      v-model="platformSelects[index].default_value"
      :rules="[s.required === true ? rules.required : undefined]"
      dense
    )
    v-text-field(
      v-for="v, index in extraVariables"
      :label="`${v.name} - ${v.unit}`"
      v-model="extraVariables[index].default_value"
      :rules="[\
        v.required === true ? rules.required : undefined,\
        ['INTEGER', 'FLOAT'].includes(v.type) ? rules.isNumber : undefined]\
        .filter((x) => x !== undefined)"
      outlined
      dense)
</template>
<script>
/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-globals */
import { mapState } from 'vuex';

export default {
  data() {
    return {
      extraVariables: [],
      platformSelects: [],
      valid: true,
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        isNumber: function (v) {
          const res = 'Debe ser un número';
          if (!isNaN(v)) {
            return true;
          }
          return res;
        },
      },
    };
  },
  computed: {
    ...mapState('ui/leaflet', [
      'netCDFVariables',
      'netCDFPeriods',
      'netCDFLevels',
      'configVariablesControl',
    ]),
    selectedVariable: {
      get() {
        return this.$store.state.ui.leaflet.selectedVariable;
      },
      set(newValue) {
        return this.$store.dispatch('ui/leaflet/setNetCDFState', {
          stateName: 'selectedVariable',
          stateValue: newValue
        });
      }
    },
    selectedPeriod: {
      get() {
        return this.$store.state.ui.leaflet.selectedPeriod;
      },
      set(newValue) {
        return this.$store.dispatch('ui/leaflet/setNetCDFState', {
          stateName: 'selectedPeriod',
          stateValue: newValue
        });
      }
    },
    selectedLevel: {
      get() {
        return this.$store.state.ui.leaflet.selectedLevel;
      },
      set(newValue) {
        console.log('newValue', newValue);
        return this.$store.dispatch('ui/leaflet/setNetCDFState', {
          stateName: 'selectedLevel',
          stateValue: newValue
        });
      }
    },
  },
  watch: {},
  mounted() {
    const copiedVars = JSON.parse(JSON.stringify(this.configVariablesControl.variables));
    this.extraVariables = copiedVars;
    const copiedSelects = JSON.parse(JSON.stringify(this.configVariablesControl.selects));
    this.platformSelects = copiedSelects;
  },
  methods: {
    validate() {
      // valisda que las variables extra cumplan los requerimientos de la API
      return this.$refs.form.validate();
    },
    urlQueryParams() {
      // genera la cadena con los valores de los parametros extra para la API
      let query = '';
      if (this.extraVariables.length === 0) {
        return query;
      }
      let queryValues = [];
      for (let index = 0; index < this.extraVariables.length; index++) {
        const element = this.extraVariables[index];
        let realValue = element.default_value;
        if (element.default_value === '' && this.configVariablesControl.variables[index].default_value !== '') {
          realValue = this.configVariablesControl.variables[index].default_value;
        }
        queryValues.push(`${element.api_name}=${realValue}`);
      }
      query = `&${queryValues.join('&')}`;

      // añadir los valores de los selects
      if (this.platformSelects.length === 0) {
        return query;
      }

      queryValues = [];
      for (let index = 0; index < this.platformSelects.length; index++) {
        const element = this.platformSelects[index];
        let realValue = element.default_value;
        if (element.default_value === '' && this.configVariablesControl.selects[index].default_value !== '') {
          realValue = this.configVariablesControl.selects[index].default_value;
        }
        queryValues.push(`${element.api_name}=${realValue}`);
      }
      query = `${query}&${queryValues.join('&')}`;

      return query;
    }
  }
};
</script>
