<template lang="pug">
  div(class="mt-8 text-center")
    div(class="text-h5") Solicite su Servicio
    div(class="text-caption mb-5 mx-auto" width="60")
      | Diligencie el formulario y a continuación lo contactará
      | uno de nuestros expertos en calidad del aire para entregarle
      | la cotización y atender sus inquietudes
    v-card(
      class="mx-auto my-auto meteo-form text-center"
      max-width="500"
      outlined)
      v-form(ref="form" v-model="valid" lazy-validation :id="formId")
        v-text-field(
          v-model="firstName"
          :rules="[rules.required]"
          label="*Nombre(s)"
          required)
        v-text-field(
          v-model="lastName"
          :rules="[rules.required]"
          label="*Apellido(s)"
          required)
        v-text-field(
          v-model="company"
          :rules="[rules.required]"
          label="*Nombre Entidad"
          required)
        v-text-field(
          v-model="phone"
          :rules="[rules.required]"
          label="*Teléfono"
          required)
        v-text-field(
          v-model="direction"
          :rules="[]"
          label="Dirección"
          required)
        v-text-field(
          v-model="email"
          :rules="[rules.required, rules.emailvalid]"
          label="*Correo Electrónico"
          required)
        v-text-field(
          v-model="city"
          :rules="[]"
          label="Ciudad"
          required)
        location-picker(
          label="Ubicación Proyecto"
          help-text="Las coordenadas deben estar lat+espacio+lon. \
          Si no conoce la ubicación, presione el ícono de mapa para ubicarse"
        )
        motion-captcha(
          :form-id="formId"
          v-model="validatedCaptcha"
          :show-error="captchaError")
        v-btn(
          :disabled="!valid"
          color="success"
          class="mr-4 mt-4"
          @click="submit") Enviar

      ul(class="contact-conditions text-caption mt-5 text-left")
        li - Los campos marcados con asterísco son obligatorios
</template>

<script>
import LocationPicker from '@/components/LocationPicker';
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';

export default {
  name: 'ContactUsForm',
  components: {
    LocationPicker,
    MotionCaptcha,
  },
  mixins: [formMixin],
  data() {
    return {
      formId: 'contact-form',
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      direction: '',
      email: '',
      city: '',
      valid: true,
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
        emailvalid: (v) => /.+@.+\..+/.test(v) || 'Debe ingresar un correo valido',
      },
    };
  },
  methods: {
    submit() {
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      if (this.validatedCaptcha === false) {
        this.captchaError = true;
        return;
      }
      this.captchaError = false;
    }
  }
};
</script>

<style scoped>
  .contact-conditions {
    list-style: none!important;
  }
</style>
