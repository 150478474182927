import { apiClient } from './config.js';

export default {
  getCountries() {
    return apiClient.get('countries');
  },
  getCities(name) {
    return apiClient.get(
      'cities_search',
      {
        params: {
          name
        }
      }
    );
  }
};
