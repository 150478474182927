<script>
import { Line } from 'vue-chartjs';
import { choosePaletteColor } from '@/utils.js';
import graphMixin from '@/mixins/graphMixin.js';

export default {
  name: 'ForecastLineChart',
  extends: Line,
  mixins: [graphMixin],
  data(instance) {
    const { variables, graphLabels } = instance.$attrs;
    for (let index = 0; index < variables.length; index++) {
      const element = variables[index];
      element.data = element.data.map((x) => parseFloat(x.toFixed(2)));
    }
    const datasets = [];
    let useMeteoFormat = false;
    let pointRadius = 3;
    let chartData = null;
    if ('pointRadius' in instance.$attrs) {
      pointRadius = instance.$attrs.pointRadius;
    }
    const xLabel = {
      display: false,
      labelString: ''
    };
    if ('use-format' in instance.$attrs) {
      useMeteoFormat = true;
    }
    if ('xLabel' in instance.$attrs) {
      xLabel.labelString = instance.$attrs.xLabel;
      xLabel.display = true;
    }
    const yLabel = {
      display: false,
      labelString: ''
    };
    if ('yLabel' in instance.$attrs) {
      yLabel.labelString = instance.$attrs.yLabel;
      yLabel.display = true;
    }
    if ('windPowerData' in instance.$attrs) {
      for (let index = 0; index < instance.$attrs.windPowerData.length; index++) {
        const element = instance.$attrs.windPowerData[index];
        datasets.push({
          label: element.title,
          data: element.data,
          fill: element.fill,
          borderColor: element.color,
          backgroundColor: element.color,
          borderWidth: 1,
          showLine: true,
        });
      }
      chartData = {
        labels: instance.$attrs.windPowerData[0].labels,
        datasets
      };
    }
    return {
      variables,
      graphLabels,
      chartData,
      datasets,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true
            },
            scaleLabel: yLabel
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 12,
              fontColor: 'black',
              beginAtZero: true,
              callback: (value) => {
                let tick = '';
                if (useMeteoFormat === true) {
                  tick = this.formatTick(value);
                } else {
                  tick = value;
                }
                return tick;
              }
            },
            gridLines: {
              display: false
            },
            scaleLabel: xLabel
          }],
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'black',
            fontSize: 15
          }
        },
        elements: {
          point: {
            radius: pointRadius
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    this.$bus.$emit('line-chart-ready');
  },
  created() {
    this.$bus.$on('update-line-chart', (variables) => {
      this.fillDatasets(variables);
    });
    this.$bus.$on('add-line-dataset', (args) => {
      let color = 'rgba(229, 57, 53, 1)';
      if ('color' in args) {
        color = args.color;
      }
      let datasets = [];
      let deleteDatasets = true;
      if ('deleteDatasets' in args) {
        deleteDatasets = args.deleteDatasets;
      }
      if (deleteDatasets === true) {
        datasets = [];
      } else {
        datasets = this.datasets;
      }

      datasets.push({
        label: args.title,
        data: args.data,
        fill: args.fill,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        showLine: true,
      });
      console.log(datasets);
      this.chartData = {
        labels: args.labels,
        datasets
      };
      this.renderChart(this.chartData, this.options);
    });
  },
  methods: {
    fillDatasets(selectVars) {
      const datasets = [];
      const yAxes = [];
      const selectedVariables = this.variables.filter((x) => selectVars.includes(x.variable));
      for (let index = 0; index < selectedVariables.length; index++) {
        const variable = selectedVariables[index];
        const color = choosePaletteColor(index);
        const chartColor = color;
        datasets.push({
          label: `${variable.name} [${variable.unit}]`,
          data: variable.data,
          fill: false,
          yAxisID: variable.variable,
          borderColor: chartColor,
          backgroundColor: chartColor,
          borderWidth: 1,
          showLine: true,
        });
        const positionAxis = index === 0 ? 'left' : 'right';
        // Calcular el valor máximo del eje y
        const maxVal = parseInt(Math.max(...variable.data) * 1.1, 10);
        const step = maxVal / 5;
        yAxes.push({
          id: variable.variable,
          type: 'linear',
          position: positionAxis,
          ticks: {
            min: 0,
            max: maxVal,
            fontSize: 15,
            stepSize: step,
            fontColor: chartColor,
            callback: (value) => `${value} ${variable.unit}`
          }
        });
      }
      this.chartData = {
        labels: this.graphLabels,
        datasets
      };
      this.options.scales.yAxes = yAxes;
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>
