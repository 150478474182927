<template lang="pug">
v-card(id="meteo-news-box" class="mx-auto mt-1 scrollable blog-containeer bgLeafletControl")
  v-list(v-if="posts.length > 0" two-line)
    div(class="m-1 px-2 bgLeafletControl" v-for="(item, index) in posts")
      v-row
        v-col(class="pb-0 text-caption font-weight-medium" cols="7") {{item.title}}
        v-spacer
        v-col(class="pb-0 text-caption text-14 font-weight-light" cols="5") {{item.created_on}}
        v-col(
          v-if="item.subtitle"
          color="gray"
          class="py-0 text-caption font-weight-light"
          cols="12") {{item.subtitle}}
      v-row(class="m-0 p-0")
        v-col(class="pt-0 text-blog-content font-weight-light" cols="12" v-html="item.content")
      //- v-divider(
        v-if="index < items.length - 1"
        //:key="index")
  div(class="d-flex flex-column text-center pt-8" v-else)
    v-icon(size="40") mdi-note-off-outline
    span(class="text-body-2") Sin articulos
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MeteoBlog',
  computed: {
    ...mapState('blog', ['posts']),
  },
  mounted() {
    this.getPosts();
  },
  data() {
    return {
      selected: [2],
      items: [
        {
          date: '2022-03-11 10:45',
          title: 'Nueva aplicación',
          content: 'Ill be in your neighborhood doing errands this weekend. Do you want to hang out?',
          level: 'info',
        },
        {
          date: '2022-02-11 10:31',
          title: 'Summer BBQ',
          content: 'Wish I could come, but Im out of town this weekend.',
          level: 'success',
        },
        {
          date: '2021-03-11 10:45',
          title: 'Oui oui',
          content: 'Do you have Paris recommendations? Have you ever been?',
          level: 'warning',
        },
        {
          date: '2022-01-11 09-32',
          title: 'Birthday gift',
          content: 'Have any ideas about what we should get Heidi for her birthday?',
          level: 'danger',
        },
        {
          date: '2021-12-11 16:11',
          title: 'Recipe to try',
          content: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
          level: '',
        },
      ],
    };
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
    bgClass(level) {
      let cls = '';
      switch (level) {
        case 'low':
          cls = 'light-blue lighten-5';
          break;
        case 'medium':
          cls = 'amber lighten-5';
          break;
        case 'high':
          cls = 'red lighten-5';
          break;
        case 'white':
          cls = 'red lighten-5';
          break;
        default:
          cls = 'white';
          break;
      }
      return cls;
    },
  },
};
</script>

<style>
.text-blog-content {
  font-size: 0.75rem !important;
  line-height: 1.25rem;
  text-align: justify;
}
.v-application .text-caption {
  font-size: 14px !important;
}
#meteo-news-box {
  border-radius: 15px;
}
</style>
