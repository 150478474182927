/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import * as d3 from '../../public/resources/libs/d3.v4.min.js';
import '../../public/resources/libs/leaflet.v120.js';
import '../../public/resources/libs/geotiff.js';
import '../../public/resources/libs/leaflet.canvaslayer.field.js';// '../../node_modules/ih-leaflet-canvaslayer-field/dist/leaflet.canvaslayer.field';

const canvasLayerPlugin = {};

canvasLayerPlugin.install = function (Vue) {
  Vue.prototype.$canvasLf = L;
};

export default canvasLayerPlugin;
