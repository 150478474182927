<script>
import { Bar } from 'vue-chartjs';
import { choosePaletteColor } from '@/utils.js';
import graphMixin from '@/mixins/graphMixin.js';

export default {
  name: 'ForecastBarChart',
  extends: Bar,
  mixins: [graphMixin],
  data(instance) {
    const { variables, graphLabels } = instance.$attrs;
    for (let index = 0; index < variables.length; index++) {
      const element = variables[index];
      element.data = element.data.map((x) => parseFloat(x.toFixed(2)));
    }
    return {
      variables,
      graphLabels,
      chartData: null,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 12,
              fontColor: 'black',
              callback: (value) => {
                const tick = this.formatTick(value);
                return tick;
              }
            },
            gridLines: {
              display: true,
            }
          }]
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'black',
            fontSize: 15
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    this.$bus.$emit('bar-chart-ready');
  },
  created() {
    this.$bus.$on('update-bar-chart', (variables) => {
      this.fillDatasets(variables);
    });
    this.$bus.$on('add-bar-dataset', (args) => {
      let color = 'rgba(229, 57, 53, 1)';
      if ('color' in args) {
        color = args.color;
      }
      let datasets = [];
      let deleteDatasets = true;
      if ('deleteDatasets' in args) {
        deleteDatasets = args.deleteDatasets;
      }
      if (deleteDatasets === true) {
        datasets = [];
      } else {
        datasets = this.datasets;
      }

      datasets.push({
        label: args.title,
        data: args.data,
        fill: args.fill,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        showLine: true,
      });
      this.chartData = {
        labels: args.labels,
        datasets
      };
      this.renderChart(this.chartData, this.options);
    });
  },
  methods: {
    fillDatasets(selectVars) {
      const datasets = [];
      const yAxes = [];
      const selectedVariables = this.variables.filter((x) => selectVars.includes(x.variable));
      for (let index = 0; index < selectedVariables.length; index++) {
        const variable = selectedVariables[index];
        const color = choosePaletteColor(index);
        const chartColor = color;
        datasets.push({
          label: `${variable.name} [${variable.unit}]`,
          borderWidth: 1,
          backgroundColor: color,
          borderColor: color,
          data: variable.data,
          yAxisID: variable.variable,
        });
        const positionAxis = index === 0 ? 'left' : 'right';
        // Calcular el valor máximo del eje y
        const maxVal = parseInt(Math.max(...variable.data) * 1.1, 10);
        const step = maxVal / 5;
        yAxes.push({
          id: variable.variable,
          type: 'linear',
          position: positionAxis,
          ticks: {
            min: 0,
            max: maxVal,
            fontSize: 15,
            stepSize: step,
            fontColor: chartColor,
            callback: (value) => `${value} ${variable.unit}`
          }
        });
      }
      this.chartData = {
        labels: this.graphLabels,
        datasets
      };
      this.options.scales.yAxes = yAxes;
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>
